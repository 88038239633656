import axios from 'axios'
// import mergeConfig from 'axios/lib/core/mergeConfig'
/**
 *
 * @param {Object} instanceConfig
 * @param {Function} func
 *
*/

export default(instanceConfig, func) => {
  const defaults = {
    // 默认配置
    timeout: 60 * 1000,
    responseType: 'application/json',
    Accept: 'application/json',
  }
  const instance = axios.create(Object.assign(defaults, instanceConfig))
  // 全局拦截（最后拦截）
  func(instance)
  // 全局拦截（先于func拦截）
  return instance
}
