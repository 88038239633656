<template>
  <div class="LoadMore">
    <mt-loadmore
      :auto-fill="false"
      :top-method="loadTop"
      :bottom-method="loadBottom"
      :bottom-all-loaded="allLoaded"
      @bottom-status-change="handleBottomChange"
      ref="loadmore"
    >
      <slot></slot>
      <div slot="bottom" class="mint-loadmore-bottom">
        <span
          v-show="bottomStatus === 'drop'"
          :class="{ rotate: bottomStatus === 'drop' }"
          >释放加载</span
        >
        <span
          v-show="bottomStatus === 'pull'"
          :class="{ rotate: bottomStatus === 'pull' }"
          >上拉加载更多</span
        >
        <span v-show="bottomStatus === 'loading'">加载中</span>
      </div>
      <p v-if="allLoaded" class="to-the-bottom">已经到底了...</p>
    </mt-loadmore>
  </div>
</template>

<script>
// import { Chart } from '@antv/g2';
export default {
  name: "LoadMore",
  props: {
    bottomStatus: {
      type: String,
      default: ''
    },
    allLoaded: {
       type: Boolean,
       default: false
    }
  },
  methods: {
    test() {
      // console.log('test')
    },
    loadTop() {
      this.$emit('loadTop')
    },
    loadBottom() {
      this.$emit('loadBottom')
    },
    handleBottomChange(status) {
      this.$emit('handleBottomChange', status)
    },
    onTopLoaded() {
      this.$refs.loadmore.onTopLoaded()
    },
    onBottomLoaded() {
      this.$refs.loadmore.onBottomLoaded()
    },
  },
};
</script>

<style scoped lang="less">
.LoadMore {
  .mint-loadmore-top {
    font-size: 16px;
  }
  .mint-loadmore-bottom {
    font-size: 16px;
  }
  .to-the-bottom {
    font-size: 16px;
  }
}
</style>
