export default {
    /**
     * 获取足球教程
     */
    getSgTeach: params => {
        return {
            url: '/admin/getSgTeach',
            method: 'post',
            params
        }
    }, 
    getTeachDetail: params => {
        return {
            url: '/admin/getTeachDetail',
            method: 'post',
            params
        }
    },
}
