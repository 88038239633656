<template>
  <div class="History">
    <mt-header title="历史记录" :fixed="true">
      <router-link
        :to="{ name: 'Mine', query: { invite_code: $route.query.invite_code } }"
        slot="left"
      >
        <i class="iconfont iconzuojiantou"></i>
      </router-link>
      <div slot="right" @click="clear()">清空</div>
    </mt-header>
    <mt-navbar v-model="selected">
      <mt-tab-item
        :id="l"
        v-for="(item, l) in tabs"
        :key="l"
        @click.native="get_lists('log_top')"
        >{{ item }}</mt-tab-item
      >
    </mt-navbar>

    <!-- tab-container -->
    <div v-if="lists && lists.length !== 0">
      <LoadMore
        :bottomStatus="bottomStatus"
        :allLoaded="allLoaded"
        @loadTop="loadTop"
        @loadBottom="loadBottom"
        @handleBottomChange="handleBottomChange"
        ref="gloadmore"
      >
        <VdListItem v-for="(n, i) in lists" :key="i + '1'" :items="n" />
      </LoadMore>
    </div>
    <NoData v-else />
  </div>
</template>

<script>
import mixin from '@/mixin/list'
export default {
  name: "History",
  mixins: [mixin],
  data() {
    return {
      tabs: ["今天", "七天", "更早"],
      selected: 0,
      lists: [],
      user_info: window.sessionStorage.getItem("user_info")
        ? JSON.parse(window.sessionStorage.getItem("user_info"))
        : "",
    };
  },
  created() {
    this.get_lists('log_top');
  },
  methods: {
    clear() {
      const _this = this
      this.$MessageBox({
        title: "提示",
        message:
          "确定清空所有历史记录吗？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then((action) => {
        if (action == "confirm") {
          _this.clear_all()
        } else {
          console.log("取消");
        }
      });
    },
    async clear_all() {
      const params = {
        uid: this.user_info ? this.user_info.id : "",
      };
      const { errNum } = await this.$http("deleteHistory", params);
      if (errNum === 1) {
        this.$Toast("清理成功");
        this.lists = [];
      } else {
        this.$Toast("清理失败");
      }
    },
    async get_lists(type) {
      if (type === 'log_top') {
        this.lists = []
      }
      const params = {
        uid: this.user_info ? this.user_info.id : "",
        type: this.selected || 0,
        page: this.page || 1,
      };
      const { errNum, data } = await this.$http("getHistory", params);
      if (errNum === 1 && data) {
        data.forEach((element) => {
          this.lists.push(element);
        });
        if (data.length === 0) {
          this.page = this.page > 1 ? this.page - 1 : 1
          this.allLoaded = true; // 若数据已全部获取完毕
        } else {
          this.allLoaded = false
        }
      } else {
        this.lists = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.History {
  width: 100%;
  padding-top: 40px;
  height: calc(100% - 20px);
 overflow-y: auto;
-webkit-overflow-scrolling: touch;
  .page-part {
    font-size: 12px;
  }
}
</style>
