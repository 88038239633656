
export default [
  {
    apiName: 'goLogin',
    data: {
      code: 200,
      desc: '操作成功',
      data: {
        username: '@cname',
        name: '',
        id: '@id'
      }
    }

  }

]
