import creatAxios from './creatAxios'
import {mocklogRequest} from '@/utiles'
const isMock = process.env.VUE_APP_MOCK === 'true'
import CryptoJS from 'crypto-js';
// import enc from 'crypto-js/enc';

// 发送前的拦截
const requestHandle = config => {
  // console.log(config)
  const send_data = config.data || '{}'
  // console.log('传出参数：', send_data)
  // Encrypt
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(send_data), 'vSqN8pVX6H9ZFC5x').toString();
  // console.log('加密：', ciphertext)
  config.data = { params: ciphertext }
  return config
}
// 发送失败拦截
const requesrErrHandle = err => {
  return Promise.reject(err)
}
// 响应成功拦截
const responsetHandle = resp => {
  isMock && mocklogRequest(resp)
  const ciphertext = resp.data.params
  const bytes  = CryptoJS.AES.decrypt(ciphertext, 'vSqN8pVX6H9ZFC5x');
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // console.log('返回参数：', decryptedData)
  resp.data = decryptedData
  return resp.data
}

// 响应失败拦截
const responseErrHandle = err => {
  return Promise.reject(err)
}

export const http = creatAxios(
  // {baseURL: '/'},
  { baseURL: window.config.api_baseurl },
  instance => {
    instance.interceptors.request.use(requestHandle, requesrErrHandle)
    instance.interceptors.response.use(responsetHandle, responseErrHandle)
  }
)
