<template>
  <div class="vdList">
    <div class="box" v-if="lists && lists.length !== 0">
      <div class="items" v-for="(item, i) in lists" @click="goto_play(item)" :key="i + 'lists'">
        <div class="top_bg">
          <img v-show="item.img" style="max-width: 100%;" :src="item.img" alt="newsPic">
        </div>
        <p class="time" v-if="item.addtime">{{ cp_date2(item.addtime) }}</p>
        <div class="center">
          <p>
            {{
            item.title
            }}
          </p>
        </div>
        <div class="bottom">
          <p class="content" v-text="item.textContent"></p>
          <p class="dd" v-if="item.textContent.length > 50">...</p>
        </div>
      </div>
    </div>
    <NoData v-else />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "NewsList",
  props: {
    lists: {
      type: Array,
    },
    routeName: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      user_info: 'get_user_info'
    }),
  },
  methods: {
    goto_play(m) {
      // console.log(m)
      // return
      this.$router.push({
        name: this.routeName,
        query: {
          infoId: m.id,
          pageNum: m.page,
          keyword: this.$route.query.keyword,
          type: m.type,
          title: m.title
        },
      })
      document.title = 'starsport.tv-' + this.routeName + '-' + m.title
    },
    cp_date(dates) {
      var date = new Date(dates);
      var now = new Date();
      var diff = now.getTime() - date.getTime();
      var hours = Math.floor(diff / 3600000);
      var days = Math.floor(diff / 86400000);
      var weeks = Math.floor(diff / 604800000);
      if (hours >= 24 && days < 7) {
        return days + this.$t('message.天前')
      }
      if (hours >= 24 && days >= 7) {
        return weeks + this.$t('message.周前')
      }
      return hours + this.$t('message.小时前')
    },
    cp_date2(dates) {
      const date = new Date(dates);
      // 获取年份、月份、日期
      // const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份从 0 开始，需要加 1
      const day = date.getDate();
      return month + this.$t('message.月') + day + this.$t('message.日')
    }
  }
}
</script>
<style lang="less" scoped>
.vdList {
  width: 100%;

  // padding: 5px 10px;
  .box {
    width: 100%;
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // justify-content: center;

    // height: 112px;
    // display: flex;
    /* 手机 */
    @media all and (max-width: 768px) {
      .items {
        width: 49% !important;
      }
    }

    .items {
      width: 350px;
      border-radius: 0 0 5px 5px;
      margin-bottom: 10px;
      cursor: pointer;
      // margin-right: 5px;

      /* 手机 */
      @media all and (max-width: 768px) {
        .top_bg {
          height: auto !important;

          img {
            width: 100% !important;
            height: 200px !important;
          }
        }

        .time {
          padding: 10px 5px !important;
        }

        .center {
          font-size: 12px !important;
          padding-top: 0px !important;
          padding-bottom: 0px !important;

          p {
            font-size: 18px !important;
          }
        }

        .bottom {
          height: 60px !important;

          .content {
            font-size: 14px !important
          }

          .time {
            right: 14px !important;
            bottom: 5px !important;
            font-size: 11px !important;
          }

          .code {
            font-size: 12px !important;
          }

          .ii {
            font-size: 11px !important;

            .vs {
              font-size: 10px !important;
            }
          }
        }
      }

      .top_bg {
        background-color: #000;
        height: 315px;
        position: relative;
        border-left: 1px solid;
        border-right: 1px solid;
        border-top: 1px solid;
        border-width: 5px;
        border-color: rgba(64, 54, 0, 1);
        border-style: solid;
        transition: opacity .2s ease;
        opacity: 1;
        border-radius: 5px 5px 0 0;

        // padding: 5px;
        .live {
          background-color: #dd0404;
          color: #fff;
          position: absolute;
          left: 10px;
          top: 10px;
          border-radius: 5px;
          padding: 3px;
          font-size: 10px;
        }

        img {
          width: 100%;
          height: 315px;
          border-radius: 5px 5px 0 0;
        }
      }

      .time {
        border-left: 1px solid;
        border-right: 1px solid;
        border-width: 5px;
        border-color: rgba(64, 54, 0, 1);
        transition: opacity .2s ease;
        opacity: 1;
        color: #FFC107;
        text-align: left;
        padding: 25px 10px;
        font-size: 16px;
        padding-bottom: 0;
      }

      .center {
        padding-top: 10px;
        border-left: 1px solid;
        border-right: 1px solid;
        border-width: 5px;
        border-color: rgba(64, 54, 0, 1);
        transition: opacity .2s ease;
        opacity: 1;
        color: #FFC107;
        display: flex;
        font-size: 22px;
        padding-left: 10px;
        padding-right: 10px;

        p {
          width: 100%;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          // white-space: nowrap; //溢出不换
          height: 55px;
          font-size: 20px;
          font-weight: bold;
          text-align: left;
        }
      }

      .bottom {
        display: flex;
        padding: 5px 10px;
        padding-top: 10px;
        height: 80px;
        border-radius: 0 0 5px 5px;
        position: relative;
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-width: 5px;
        border-color: rgba(64, 54, 0, 1);
        transition: opacity .2s ease;
        opacity: 1;
        color: #FFC107;
        justify-content: space-between;

        .content {
          // padding-left: 10px;
          font-size: 14px;
          overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          height: 40px;
          // line-height: 40px;
          width: 100%;
          text-align: left;
        }

        .dd {
          line-height: 60px;
        }

        .time {
          padding: 2px 5px;
          border-radius: 15px;
          color: #fff;
          background-color: #58595a;
          text-align: center !important;
          position: absolute;
          right: 22px;
          bottom: 10px;
          font-size: 14px;
        }

        .code {
          font-size: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .ii {
          font-size: 15px;

          p {
            text-align: left;
          }

          .vs {
            color: red;
            font-size: 14px;
            font-weight: bold;
            text-align: center !important;
          }
        }
      }
    }

    .item {
      // flex-shrink: 0;
      width: calc(50% - 10px);
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
        height: 100px;
        border-radius: 10px;
      }

      span {
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>