<template>
  <div class="types">
    <VideoList v-if="video_lists && video_lists.length !== 0" :lists="video_lists" />
    <NoData v-else />
  </div>
</template>

<script>
// import Poster from './components/Poster'
import { mapGetters } from "vuex";

export default {
  name: "Types",
  components: {
    // Poster,
  },
  computed: {
    ...mapGetters({
      video_lists: "video_lists",
    }),
  },
  data() {
    return {
      lists: [],
      types_bg: [],
    };
  },
  created() {
  },
  methods: {
    
  },
};
</script>
<style lang="less" scoped>
.types {
  padding: 10px;
  .title {
    margin-bottom: 10px;
  }
  .boxs {
    .box {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 20px;
      background-color: #ffc107;
      border-radius: 10px;
      padding-top: 155px;
      color: #fff;
      padding-bottom: 10px;
      margin-bottom: 20px;
      padding-left: 20px;
      .img {
        border-radius: 5px 5px;
        border: 1px solid black;
      }
      p {
        display: flex;
        font-size: 16px;
        // padding-left: 0.55rem;
        justify-content: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tip {
        display: flex;
        font-size: 20px;
        font-weight: bold;
        // padding-left: 55px;
        margin-bottom: 10px;
      }
      .dec {
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-align: left;
      }
      .tag {
        align-items: center;
        flex-wrap: wrap;
        display: flex;
        padding-top: 10px;
        color: white;
        font-size: 14px;
        justify-content: flex-start;
        // padding: 35px;
        // margin-left: 33px;
        .tag-content {
          display: inline-block;
          // height: 30px;
          // line-height: 30px;
          // padding: 0 12px;
          padding: 5px 10px;
          background: rgb(194, 189, 189);
          border-radius: 100px;
          margin-left: 20px;
          font-size: 13px;
          // opacity: 0.8;
          background-color: #ffbc00;
          color: #000;
        }
        .tag-content:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
