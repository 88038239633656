<template>
    <div class="tips-detail">
        <mt-header v-if="isPcOrMobile === 1" :title="$t('message.心水推荐')" :fixed="true">
            <div slot="left">
                <i @click="$router.go(-1)" class="iconfont iconzuojiantou"></i>
            </div>
        </mt-header>
        <!-- <h2 class="top">{{ $t('message.本日StatSport心水推荐') }}</h2> -->
        <div class="lists">
            <div v-for="(item, i) in datas" :key="i" class="item">
                <!-- <img src="@/assets/others/color.png"> -->
                <div class="top" v-if="i === 0">
                    <span v-if="item.date">{{ item.date.split(' ')[0] }} </span>
                    <span>{{ $t('message.心水推荐') }}</span>
                </div>
                <div class="center">
                    <img v-if="item.dialogImageUrlArr" :src="item.dialogImageUrlArr.split(',')[0]">
                    <span>VS</span>
                    <img v-if="item.dialogImageUrlArr" :src="item.dialogImageUrlArr.split(',')[1]">
                </div>
                <div class="bottom">
                    <span v-if="item.date">{{ item.date.split(' ')[1] }}</span>
                    <span>{{ item.league }}</span>
                    <span class="bet" @click="bet()">{{ $t('message.投注') }} ></span>
                </div>
                <!--  <div class="itemTop">
                    <div class="team">
                        <img v-if="item.dialogImageUrlArr" :src="item.dialogImageUrlArr.split(',')[0]">
                        <span v-if="item.sgmatch" v-text="item.sgmatch.split('VS')[0]"></span>
                    </div>
                    <span v-text="$t('message.对')"></span>
                    <div class="team">
                        <img v-if="item.dialogImageUrlArr" :src="item.dialogImageUrlArr.split(',')[1]">
                        <span v-if="item.sgmatch" v-text="item.sgmatch.split('VS')[1]"></span>
                    </div>
                </div>
                <div class="results">
                    <span v-text="item.results"></span> 
                    <span v-text="item.sgstatus"></span>
                </div>
               
                <p v-text="item.date" class="time"></p>
                <p v-text="item.league" class="league"></p> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            datas: [],
            page: 1,
            limit: 20,
        };
    },
    created() {
        this.getData();
        this.initScroll()
    },

    computed: {
        ...mapGetters({
            getCont: 'getCont',
            isPcOrMobile: 'isPcOrMobile',
            typeMenus: 'typeMenus'
        }),
    },
    watch: {
        getCont() {
            this.getData();
        }
    },
    methods: {
        bet() {
            window.open(this.typeMenus.links[0], 'target')
        },
        initScroll() {
            window.onscroll = function() {
                //scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                //滚动条到底部的条件
                if (scrollTop + windowHeight == scrollHeight) {
                    //到了这个就可以进行业务逻辑加载后台数据了
                    console.log("到了底部");
                    // window.onscroll = '' 用于解除绑定
                }
            }
        },
        async getData() {
            const locale = this.$i18n.locale
            if (locale === 'hk') {
                this.$store.commit('SETCONT', 'TraditionalChinese')
            } else if (locale === 'tai') {
                this.$store.commit('SETCONT', 'Thailand')
            } else if (locale === 'en') {
                this.$store.commit('SETCONT', 'English')
            }
            // getSgBattleInfo
            const param = {
                siteName: "afb",
                page: this.page,
                limit: this.limit,
                cont: this.getCont
            }
            // console.log(this.getCont)
            const { code, data } = await this.$http('getSgBattleInfo', param);
            // console.log(data)
            if (code === 20000 && data) {
                // console.log(data)
                this.datas = data
            }
        },
    },
};
</script>
<style lang="less" scoped>
/* 手机 */
@media all and (max-width: 768px) {
    .tips-detail {
        .lists {
            .item {
                .top {
                    flex-direction: column !important;
                }

                .bottom {
                    flex-direction: column !important;
                }
            }
        }
    }
}

.tips-detail {
    /deep/ .mint-header.is-fixed {
        top: 68px !important;
    }

    .lists {
        // background-color: #181818;
        padding: 10px 10px 10px 10px;
        color: #fff;

        .item {
            margin: 20px auto;
            border-radius: 20px;
            color: #ffd602;
            // background-color: #4a4b4c;
            text-align: center;
            padding: 10px;
            width: 80%;

            .top {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 30px;
                font-weight: bold;
            }

            .center {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                font-size: 35px;
                font-weight: bold;
                margin: 20px 0;
                border-top: 1px solid #ffd602;
                padding-top: 10px;

                img {
                    width: 100px;
                }
            }

            .bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 35px;
                font-weight: bold;

                .bet {
                    font-size: 23px;
                    background-color: #ffd602;
                    color: #000;
                    padding: 5px 10px;
                    width: 100px;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }

            .itemTop {
                display: flex;
                justify-content: space-around;
                align-items: center;

                img {
                    width: 50px;
                    border-radius: 10px;
                    margin-right: 5px;
                }

                .team {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .results {}

            .time {
                font-size: 15px;
                margin: 10px 0;
            }
        }
    }
}
</style>