export default {
  total_looks: params => {
      return {
        url: '/play/total_looks',
        method: 'post',
        params
      }
    },
    play: params => {
      return {
        url: '/play',
        method: 'post',
        params
      }
    },
    getVideo: params => {
      return {
        url: '/play/getVideo',
        method: 'post',
        params
      }
    },
  }
  