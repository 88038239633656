<template>
  <div class="Poster">
    <img
      @click="goin('game_ad')"
      v-if="this.ads && this.ads.length !== 0"
      :src="this.ads.filter((item) => item.position === 'game_ad')[0].img_url"
      alt="banner"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      ads: "ads",
    }),
  },
  methods: {
    goin(k) {
      const item = this.ads.filter((item) => item.position === k)[0];
      if (item.open_type == "1") {
        window.open(item.link);
      } else {
        window.location.href = item.link;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.Poster {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  // padding: 20px;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    // border-radius: 10px;
  }
}
</style>
