<template>
  <div class="search">
    <!-- <div class="left_img">
      <img @click="go_link('tg')" :src="require('@/assets/others/tg.png')" alt="tg">
      <img @click="go_link('line')" :src="require('@/assets/others/whatsApp.png')" alt="whatsApp">
    </div> -->
    <div class="logo" :class="[isPcOrMobile === 1 ? 'mLogo' : '']">
      <img @click="go_link('home')" :src="require('@/assets/logo.png')" alt="logo">
    </div>
    <div class="right_content">
      <el-menu v-if="isPcOrMobile === 0" :default-active="this.$route.path" class="el-menu-demo" mode="horizontal" background-color="#000" text-color="#FFC107" active-text-color="#ffd602" style="display: flex">
        <div v-for="item  in navList" :key="item.path" style="display: flex;">
          <el-menu-item v-if="!item.children" :index="item.path" :key="item.path" @click="clickMen(item)">
            <i v-if="item.icon" :class="'el-icon-'+item.icon"></i>
            <span slot="title">{{item.label}}</span>
          </el-menu-item>
          <el-submenu v-else :index="item.path" :key="item.path" :popper-append-to-body="true">
            <template slot="title">
              <i v-if="item.icon" :class="'el-icon-'+item.icon"></i>
              <span>{{item.label}}</span>
            </template>
            <el-menu-item :index="sub.path" v-for="sub in item.children" :key="sub.path" @click="clickMen(sub)">
              <i v-if="sub.icon" :class="'el-icon-'+sub.icon"></i>
              <span>{{sub.label}}</span>
            </el-menu-item>
          </el-submenu>
        </div>
        <!--  <el-menu-item :index="item.path" v-for="item  in noChildren" :key="item.path" @click="clickMen(item)">
          <i v-if="item.icon" :class="'el-icon-'+item.icon"></i>
          <span slot="title">{{item.label}}</span>
        </el-menu-item>
        <el-submenu :index="v.path" v-for="v in hasChildren" :key="v.path" :popper-append-to-body="true">
          <template slot="title">
            <i v-if="v.icon" :class="'el-icon-'+v.icon"></i>
            <span>{{v.label}}</span>
          </template>
          <el-menu-item-group>
          <el-menu-item :index="sub.path" v-for="sub in v.children" :key="sub.path" @click="clickMen(sub)">
            <i v-if="sub.icon" :class="'el-icon-'+sub.icon"></i>
            <span>{{sub.label}}</span>
          </el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
      </el-menu>
      <div style="display: flex">
        <div v-if="isPcOrMobile === 0" class="login" @click="go_link('mine')">
          {{ my_token ? $t('message.i61') : $t('message.i43') + '/' + $t('message.i2') }}
        </div>
        <div v-if="isPcOrMobile === 1" class="right_lang" @click="show_">
          <!-- <span class="ii">{{ $t('message.i68') }}</span> -->
          <i class="iconfont iconfenlei1 ooo"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import { debounce } from "@/utiles"
export default {
  name: 'Search',
  components: {},
  computed: {
    ...mapGetters({
      telegram: 'get_telegram',
      line: 'get_line',
      my_token: 'my_token',
      isPcOrMobile: 'isPcOrMobile',
      typeMenus: 'typeMenus'
    }),
    noChildren() {
      if (!this.navList) return []
      return this.navList.filter((item) => !item.children)
    },
    hasChildren() {
      if (!this.navList) return []
      return this.navList.filter((item) => item.children)
    },
    navList() {
      const { news, scores, teach, links } = this.typeMenus
      if (!news || !scores || !teach || !links) return false
      const newsChildren = []
      const scoresChildren = []
      const teachChildren = []
      news.forEach((item) => {
        newsChildren.push({
          path: `/Content/News?type=${item}`,
          label: item,
          icon: ""
        })
      })
      scores.forEach((item) => {
        scoresChildren.push({
          path: `/Content/Score?type=${item}`,
          label: item,
          icon: ""
        })
      })
      teach.forEach((item) => {
        teachChildren.push({
          path: `/Content/Teach?type=${item}`,
          label: item,
          icon: ""
        })
      })
      return [
        // '主頁'
        { path: '/Content/Home', label: this.$t('message.i40'), icon: "", },
        // 賽事直播
        { path: '/Content/Types', label: this.$t('message.i41'), icon: "", },
        // 賽程表
        { path: '/Content/Extend', label: this.$t('message.i42'), icon: "", },
        {
          path: '/Content/Score',
          label: this.$t('message.积分榜'),
          icon: "",
          children: scoresChildren
        },
        // 体育新闻
        {
          path: '/Content/News',
          label: this.$t('message.i67'),
          icon: "",
          children: newsChildren
        },
        { path: '/Content/Tips', label: this.$t('message.心水推荐'), icon: "", },
        {
          path: '/Content/Teach',
          label: this.$t('message.资讯文章'),
          icon: "",
          children: teachChildren
        },
        { path: links[0], label: this.$t('message.星汇娱乐'), icon: "", link: true },
        { path: links[1], label: this.$t('message.优惠'), icon: "", link: true },
      ]
    },
  },
  data() {
    return {}
  },
  methods: {
    clickMen(item) {
      // console.log(item)
      if (item.link) {
        window.open(item.path, 'target')
        return
      }
      this.$router.push({ path: item.path })
    },
    async getDatas() {
      let lang = 'hk'
      if (localStorage.getItem('i18lang')) {
        lang = localStorage.getItem('i18lang')
      } else {
        localStorage.setItem('i18lang', 'hk')
      }
      const params = {
        site: location.host,
        cont: lang,
        type: ''
      }
      const { code, data } = await this.$http('get_cont_data', params);
      if (code === 20000 && data) {
        // console.log(data)
        this.$store.commit('all_datas', data)
      }
    },
    async getVideos() {
      let lang = 'hk'
      if (localStorage.getItem('i18lang')) {
        lang = localStorage.getItem('i18lang')
      } else {
        localStorage.setItem('i18lang', 'hk')
      }
      const params = {
        lang: lang
      }
      const { errNum, data } = await this.$http('videoLists', params);
      // console.log(errNum)
      // console.log(data)
      if (JSON.stringify(data) === '{}') {
        return
      }
      const datas = data && JSON.parse(data)
      if (errNum === 1 && datas) {
        this.$store.commit('video_lists', datas && datas.data)
      }
    },
    show_() {
      this.$store.commit('set_sheetVisible', true)
    },
    go_link(e) {
      if (e === 'tg') {
        if (!this.telegram) return
        if (this.telegram[0].open_type === '新开窗口打开') {
          window.open(this.telegram[0].link)
        } else {
          window.location.href = this.telegram[0].link
        }
      } else if (e === 'line') {
        if (!this.line) return
        if (this.line[0].open_type === '新开窗口打开') {
          window.open(this.line[0].link)
        } else {
          window.location.href = this.line[0].link
        }
      } else if (e === 'home') {
        this.$router.push({ path: '/' })
      } else if (e === 'mine') {
        this.$router.push({ name: 'Mine', query: { invite_code: this.$route.query.invite_code } });
      }
    }
  },
}
</script>
<style lang="less" scoped>
/* 手机 */
@media all and (max-width: 768px) {
  .search {
    width: 100% !important;

  }
}

.search {
  width: 81%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  background-color: #000;
  // padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding-right: 10px;

  // border-bottom: 1px solid #fff;
  // margin-bottom: 10px;
  .logo {
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .mLogo {
    margin-left: 43% !important;
  }

  .login {
    padding: 6px 5px;
    border-radius: 5px;
    font-size: 14px;
    // border: 1px solid;
    border-color: #ffd602;
    color: #FFC107;
    margin-right: 20px;
    cursor: pointer;
  }

  .right_content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right_lang {
    padding: 6px 5px;
    border-radius: 5px;
    font-size: 14px;
    // border: 1px solid;
    border-color: rgba(187, 130, 43, 1);
    // background-color: #000;
    color: #FFC107;
    .ooo {
      font-size: 20px;
    }

    .ii {
      color: rgba(187, 130, 43, 1) !important;
    }
  }

  .left_img {
    display: flex;

    img {
      // width: 48px;
      height: 48px;
      margin-right: 10px;
    }
  }

  .right {
    width: 40px;
    height: 30px;
  }

  .icon {
    padding: 0 10px;
  }

  .iconLOGO {
    margin-right: 10px;
    font-size: 18px;
    width: 40px;
    height: 35px;
  }

  .center_box {
    position: relative;
    width: calc(100% - 20px - 40px - 40px);

    .center_input {
      border: 1px solid #fff;
      border-radius: 10px;
      height: 30px;
      line-height: 30px;
      // width: 275px;
      width: 100%;
      padding-left: 30px;
      background-color: #eee;
    }

    .iconsousuo {
      position: absolute;
      left: 8px;
      top: 6px;
    }
  }

  .search_list {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #fff;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    top: 0;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 10px 0;

      .iconfont {
        font-size: 18px;
      }

      .center_box {
        position: relative;
        width: calc(100% - 40px - 28px - 40px);

        .center_input {
          border: 1px solid #fff;
          border-radius: 10px;
          height: 30px;
          line-height: 30px;
          // width: 275px;
          width: 100%;
          padding-left: 30px;
          background-color: #eee;
        }

        .iconsousuo {
          position: absolute;
          left: 8px;
          top: 6px;
        }
      }

      .btn {
        font-size: 17px;
      }

      button {
        margin-bottom: 0;
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 10px;

        h5 {
          font-weight: bold;
        }

        i {
          font-size: 19px;
        }
      }

      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        padding-left: 10px;

        p {
          background-color: #eee;
          color: #000;
          margin-right: 20px;
          font-size: 16px;
          padding: 0px 10px;
          border-radius: 15px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }

    .videos {
      // position: absolute;
      // top: 220px;
      // bottom: 0;
      // height: calc(100% - 179px);
      //overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .iconliwu1 {
    margin-left: 10px;
  }
}
</style>