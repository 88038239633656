const hk = {
  message: {
    i1: '登錄',
    i2: '註冊',
    i3: '重置',
    i4: '歡迎來到{0}',
    i5: '歡迎加入{0}',
    i6: '歡迎回歸{0}',
    i7: '姓',
    i8: '請輸入姓',
    i9: '名',
    i10: '請輸入名',
    i11: '手機號碼',
    i12: '請輸入手機號碼',
    i13: '用戶名',
    i14: '請輸入用戶名',
    i15: '驗證碼',
    i16: '獲取驗證碼',
    i17: '密碼',
    i18: '請輸入密碼',
    i19: '確認密碼',
    i20: '請輸入確認密碼',
    i21: '登 錄',
    i22: '沒有帳戶？創建一個新賬戶',
    i23: '註冊',
    i24: '返回登錄',
    i25: '修改密碼並登錄',
    i26: '返回登錄',
    i27: '驗證碼發送成功',
    i28: '驗證碼發送失敗',
    i29: '密碼未達到6位',
    i30: '姓或名不能為空',
    i31: '用戶名不能為空',
    i32: '兩次輸入的密碼不正確',
    i33: '登錄成功',
    i34: '查詢不到此手機號',
    i35: '用戶名或者密碼錯誤',
    i36: '已有重複的用戶名',
    i37: '熱門直播',
    i38: '現時直播',
    i39: '更多賽事',
    i40: '主頁',
    i41: '賽事直播',
    i42: '賽程表',
    i43: '登入',
    i44: '未開始',
    i45: '註冊 & 登錄',
    i46: '歡迎您！ {0}',
    i47: '意見反饋',
    i48: '退出登錄',
    i49: '遊客',
    i50: '反饋類型',
    i51: '反饋內容',
    i52: '提交',
    i53: '無法播放',
    i54: '播放卡頓',
    i55: '推薦不准',
    i56: '其他',
    i57: '請先註冊或登錄',
    i58: '提交成功',
    i59: '提交失敗',
    i60: '描述問題',
    i61: '我的',
    i62: '驗證碼錯誤',
    i63: '暫無數據',
    i64: '直播全屏',
    i65: '關閉',
    i66: '數字直播',
    i67: '體育新聞',
    i68: '目錄',
    心水推荐: '心水推薦',
    足球教学: '足球教學',
    积分榜: '聯賽積分榜',
    优惠: '優惠活動',
    关于我们: '關於我們',
    搜索: '搜索',
    取消: '取消',
    热门新闻: '熱門新聞',
    更多新闻: '更多新聞',
    联赛名: '聯賽名',
    队名: '隊名',
    查询联赛名: '查詢聯賽名',
    查询队名: '查詢隊名',
    本日StatSport心水推荐: '本日StatSport心水推薦',
    对: '對',
    小时前: ' 小時前',
    天前: ' 天前',
    周前: ' 周前',
    体育新闻: '體育新聞',
    更多: '更多',
    投注: '投注',
    排名: '排名',
    球队: '球隊',
    场次: '完場',
    积分: '積分',
    胜: '勝',
    平: '平',
    负: '負',
    进球: '进球',
    失球: '失球',
    浮胜球: '浮胜球',
    星汇娱乐: '星匯娛樂',
    资讯文章: '資訊文章',
    相关文章: '相關文章',
    复制成功: '複製成功',
    查看全部: '查看全部',
    月: '月',
    日: '日',
    年: '年'
  },
};


export default hk;
