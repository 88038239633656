export default {
    created() {
        // console.log('混入对象的钩子被调用')
    },
    mounted() {
        // console.log('来了！！！！')
    },
    data() {
        return {
            allLoaded: false,
            page: 1,
            bottomStatus: '',
        }
    },
    methods: {
        loadTop() {
            // 加载更多数据
            // console.log("log_top");
            this.allLoaded = false;
            this.page = 1
            this.get_lists('log_top', this.selected)
            this.$refs.gloadmore.onTopLoaded()
        },
        loadBottom() {
            // console.log("log_bottom");
            this.page += 1
            this.get_lists('log_bottom', this.selected);
            this.$refs.gloadmore.onBottomLoaded();
        },
        handleBottomChange(p_status) {
            // console.log(p_status)
            this.bottomStatus = p_status;
        },
    },
}