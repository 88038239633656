import Content from '@/views/Content'
import Login from '@/views/Login'
import Home from '@/views/Home'
import Types from '@/views/Types'
import News from '@/views/News'
import NewsDetail from '@/views/News/components/NewsDetail'
import Teach from '@/views/Teach'
import TeachDetail from '@/views/Teach/components/TeachDetail'
import Tips from '@/views/Tips'
import Score from '@/views/Score'
import Aboutme from '@/views/Aboutme'
import Game from '@/views/Game'
import Extend from '@/views/Extend'
import Mine from '@/views/Mine'
import Collect from '@/views/Collect'
import History from '@/views/History'
import Reback from '@/views/Reback'
import ExtendCenter from '@/views/ExtendCenter'
import Play from '@/views/Play'
import Fullscreen from '@/views/Play/components/Fullscreen'
import TypeList from '@/views/TypeList'
import Gift from '@/views/Gift'
import Test from '@/views/Test'
import Explore from '@/views/Explore'
import Others from '@/views/Others'
import FirstPage from '@/views/Others/FirstPage'
import SecondPage from '@/views/Others/SecondPage'
import ThreePage from '@/views/Others/ThreePage'
const routes = [
  {
    path: '/',
    redirect: 'Content/Home'
  },
  {
    path: '/Others',
    name: 'Others',
    component: Others,
    children: [
      {
        path: 'FirstPage',
        name: 'FirstPage',
        component: FirstPage
      },
      {
        path: 'SecondPage',
        name: 'SecondPage',
        component: SecondPage
      },
      {
        path: 'ThreePage',
        name: 'ThreePage',
        component: ThreePage
      },
    ]
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Play',
    name: 'Play',
    component: Play
  },
  {
    path: '/Fullscreen',
    name: 'Fullscreen',
    component: Fullscreen
  },
  {
    path: '/TypeList',
    name: 'TypeList',
    component: TypeList
  },
  {
    path: '/Gift',
    name: 'Gift',
    component: Gift
  },
  {
    path: '/Explore',
    name: 'Explore',
    component: Explore
  },
  {
    path: '/ExtendCenter',
    name: 'ExtendCenter',
    component: ExtendCenter
  },
  {
    path: '/Collect',
    name: 'Collect',
    component: Collect
  },
  {
    path: '/History',
    name: 'History',
    component: History
  },
  {
    path: '/Reback',
    name: 'Reback',
    component: Reback
  },
  {
    path: '/Content',
    name: 'Content',
    component: Content,
    children: [
      {
        path: 'Home',
        name: 'Home',
        component: Home
      },
      {
        path: 'Types',
        name: 'Types',
        component: Types
      },
      {
        path: 'News',
        name: 'News',
        component: News
      },
       {
        path: 'NewsDetail',
        name: 'NewsDetail',
        component: NewsDetail
      },
      {
        path: 'Teach',
        name: 'Teach',
        component: Teach
      },
       {
        path: 'TeachDetail',
        name: 'TeachDetail',
        component: TeachDetail
      },
      {
        path: 'Tips',
        name: 'Tips',
        component: Tips
      },
      {
        path: 'Score',
        name: 'Score',
        component: Score
      },
      {
        path: 'Aboutme',
        name: 'Aboutme',
        component: Aboutme
      },
      {
        path: 'Game',
        name: 'Game',
        component: Game
      },
      {
        path: 'Extend',
        name: 'Extend',
        component: Extend
      },
      {
        path: 'Mine',
        name: 'Mine',
        component: Mine
      },
      {
        path: 'Test',
        name: 'Test',
        component: Test
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About')
  }
]

export default routes