const tai = {
  message: {
    i1: 'เข้าสู่ระบบ',
    i2: 'ลงทะเบียน',
    i3: 'รีเซ็ต',
    i4: 'ยินดีต้อนรับสู่ {0}',
    i5: 'ยินดีต้อนรับสู่ {0}',
    i6: 'ยินดีต้อนรับกลับสู่ {0}',
    i7: 'นามสกุล',
    i8: 'โปรดป้อนนามสกุล',
    i9: 'ชื่อ',
    i10: 'โปรดป้อนชื่อ',
    i11: 'หมายเลขโทรศัพท์มือถือ',
    i12: 'โปรดป้อนหมายเลขโทรศัพท์มือถือ',
    i13: 'ชื่อผู้ใช้',
    i14: 'โปรดป้อนชื่อผู้ใช้',
    i15: 'รหัสยืนยัน',
    i16: 'รับรหัสยืนยัน',
    i17: 'รหัสผ่าน',
    i18: 'โปรดป้อนรหัสผ่าน',
    i19: 'ยืนยันรหัสผ่าน',
    i20: 'โปรดป้อนรหัสผ่านการยืนยัน',
    i21: 'เข้าสู่ระบบ',
    i22: 'ไม่มีบัญชี? สร้างบัญชีใหม่',
    i23: 'ลงทะเบียน',
    i24: 'กลับสู่การเข้าสู่ระบบ',
    i25: 'เปลี่ยนรหัสผ่านและเข้าสู่ระบบ',
    i26: 'กลับสู่การเข้าสู่ระบบ',
    i27: 'ส่งรหัสยืนยันเรียบร้อยแล้ว',
    i28: 'ไม่สามารถส่งรหัสยืนยัน',
    i29: 'รหัสผ่านไม่ถึง 6 หลัก',
    i30: 'ต้องระบุชื่อหรือนามสกุล',
    i31: 'ชื่อผู้ใช้ต้องไม่ว่างเปล่า',
    i32: 'รหัสผ่านที่ป้อนสองครั้งไม่ถูกต้อง',
    i33: 'การเข้าสู่ระบบสำเร็จ',
    i34: 'ไม่พบหมายเลขโทรศัพท์นี้',
    i35: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
    i36: 'มีชื่อผู้ใช้ซ้ำกัน',
    i37: 'ยอดนิยมสด',
    i38: 'มีชีวิตอยู่ในขณะนี้',
    i39: 'กิจกรรมเพิ่มเติม',
    i40: 'หน้าแรก',
    i41: 'สด',
    i42: 'กำหนดการ',
    i43: 'เข้าสู่ระบบ',
    i44: 'ยังไม่เริ่ม',
    i45: 'ลงทะเบียนและเข้าสู่ระบบ',
    i46: 'ยินดีต้อนรับ! {0}',
    i47: 'คำติชม',
    i48: 'ออกจากระบบ',
    i49: 'นักท่องเที่ยว',
    i50: 'ประเภทคำติชม',
    i51: 'เนื้อหาคำติชม',
    i52: 'ส่ง',
    i53: 'ไม่สามารถเล่นได้',
    i54: 'การเล่นค้าง',
    i55: 'ไม่อนุญาตให้ใช้คำแนะนำ',
    i56: 'อื่นๆ',
    i57: 'กรุณาลงทะเบียนหรือเข้าสู่ระบบก่อน',
    i58: 'ส่งเรียบร้อยแล้ว',
    i59: 'การคอมมิตล้มเหลว',
    i60: 'อธิบายปัญหา',
    i61: 'ของฉัน',
    i62: 'รหัสยืนยันผิดพลาด',
    i63: 'ไม่มีข้อมูล',
    i64: 'ถ่ายทอดสดแบบเต็มหน้าจอ',
    i65: 'ปิด',
    i66: 'ดิจิตอลไลฟ์',
    i67: 'ข่าว',
    i68: 'สารบัญ',
    心水推荐: 'คำแนะนำที่ชื่นชอบ',
    足球教学: 'สอนฟุตบอล',
    积分榜: 'การจัดอันดับ',
    优惠: 'การลดราคา',
    关于我们: 'เกี่ยวกับเรา',
    搜索: 'ค้นหา',
    取消: 'ยกเลิก',
    热门新闻: 'ข่าวด่วน',
    更多新闻: 'ข่าวเพิ่มเติม',
    联赛名: 'ชื่อลีก',
    队名: 'ชื่อทีม',
    查询联赛名: 'ชื่อลีกข้อความค้นหาเลือน',
    查询队名: 'ชื่อทีมแบบสอบถาม Fuzzy',
    本日StatSport心水推荐: 'คำแนะนำโปรดของ StatSport วันนี้',
    对: 'VS',
    小时前: ' An hour ago',
    天前: ' An day ago',
    周前: ' An week ago',
    体育新闻: 'ข่าวกีฬา',
    更多: 'More',
    投注: 'การเดิมพัน',
    排名: 'rank',
    球队: 'team',
    场次: 'Sessions',
    积分: 'integral',
    胜: 'win',
    平: 'flat',
    负: 'burden',
    进球: 'goal',
    失球: 'conceded goal',
    浮胜球: 'floating ball',
    星汇娱乐: '星匯娛樂',
    资讯文章: 'บทความข่าว',
    相关文章: 'บทความที่เกี่ยวข้อง',
    复制成功: 'คัดลอกสำเร็จ',
    查看全部: 'ดูทั้งหมด',
    月: 'Month',
    日: 'Day',
    年: 'Year'
  },
};


export default tai;
