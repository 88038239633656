import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import './rewrite/index.less'


import {
	Menu,
	Submenu,
	MenuItem,
	MenuItemGroup,
	Table,
	TableColumn,
	RadioGroup,
	RadioButton
} from 'element-ui';

Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(MenuItemGroup.name, MenuItemGroup);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(RadioButton.name, RadioButton);