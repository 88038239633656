const en = {
    message: {
        i1: 'Login',
        i2: 'register',
        i3: 'reset',
        i4: 'Welcome to {0}',
        i5: 'Welcome to {0}',
        i6: 'Welcome back to {0}',
        i7: 'surname',
        i8: 'Please enter last name',
        i9: 'name',
        i10: 'Please enter a name',
        i11: 'Mobile number',
        i12: 'Please enter the mobile number',
        i13: 'username',
        i14: 'Please enter a username',
        i15: 'Verification code',
        i16: 'Get verification code',
        i17: 'password',
        i18: 'Please enter the password',
        i19: 'Confirm password',
        i20: 'Please enter the confirmation password',
        i21: 'Login',
        i22: 'No account? Create a new account',
        i23: 'register',
        i24: 'return to login',
        i25: 'Change password and log in',
        i26: 'return to login',
        i27: 'Verification code sent successfully',
        i28: 'Failed to send verification code',
        i29: 'Password does not reach 6 digits',
        i30: 'First or last name cannot be empty',
        i31: 'Username cannot be empty',
        i32: 'The password entered twice is incorrect',
        i33: 'Login successful',
        i34: `Can't find this phone number`,
        i35: 'Username or password is incorrect',
        i36: 'There is a duplicate username',
        i37: 'Live now',
        i38: 'Featured Live',
        i39: 'More events',
        i40: 'Homepage',
        i41: 'Live',
        i42: 'schedule',
        i43: 'Login',
        i44: 'Not started',
        i45: 'Register & Login',
        i46: 'Welcome! {0}',
        i47: 'Feedback',
        i48: 'Log out',
        i49: 'tourist',
        i50: 'Feedback Type',
        i51: 'Feedback content',
        i52: 'Submit',
        i53: 'unable to play',
        i54: 'Playback stuck',
        i55: 'Recommendation not allowed',
        i56: 'other',
        i57: 'Please register or log in first',
        i58: 'Submitted successfully',
        i59: 'Commit failed',
        i60: 'Describe the problem',
        i61: 'mine',
        i62: 'Verification code error',
        i63: 'No data',
        i64: 'Live full screen',
        i65: 'closure',
        i66: 'digital live',
        i67: 'news',
        i68: 'lists',
        心水推荐: 'Favorite recommendation',
        足球教学: 'football teaching',
        积分榜: 'ranking',
        优惠: 'discount',
        关于我们: 'about Us',
        搜索: 'search',
        取消: 'cancel',
        热门新闻: 'hot news',
        更多新闻: 'more news',
        联赛名: 'League Name',
        队名: 'team name',
        查询联赛名: 'query league name',
        查询队名: 'query team name',
        本日StatSport心水推荐: `Today's StatSport favorite recommendation`,
        对: 'VS',
        小时前: ' An hour ago',
        天前: ' An day ago',
        周前: ' An week ago',
        体育新闻: 'Sports News',
        更多: 'More',
        投注: 'betting',
        排名: 'rank',
        球队: 'team',
        场次: 'Sessions',
        积分: 'integral',
        胜: 'win',
        平: 'flat',
        负: 'burden',
        进球: 'goal',
        失球: 'conceded goal',
        浮胜球: 'floating ball',
        星汇娱乐: '星匯娛樂',
        资讯文章: 'News article',
        相关文章: 'related articles',
        复制成功: 'copy successfully',
        查看全部: 'view all',
        月: 'Month',
        日: 'Day',
        年: 'Year'
    },
};


export default en;
