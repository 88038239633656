export default {
    /**
     * 获取tips
     */
    getSgBattleInfo: params => {
        return {
            url: '/admin/getSgBattleInfo',
            method: 'post',
            params
        }
    },
}
