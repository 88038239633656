<template>
  <div class="mine">
    <!-- <Poster /> -->
    <!-- 我的 -->
    <div class="content">
      <div class="panel-login panel">
        <div class="avatar" @click="gologin">
          <!-- <img src="" alt="头像" class="avatar"> -->
          <i class="iconfont iconicon30"></i>
        </div>
        <p class="font" v-if="!my_token" @click="gologin">{{ $t('message.i45') }}</p>
        <p class="font" v-if="my_token">{{ $t('message.i46', [user_info ? user_info.account : '-']) }}</p>
        <p class="font ood" v-show="!my_token">{{ $t('message.i46', [$t('message.i49')]) }}</p>
      </div>

      <div class="list">
        <div class="item" @click="$router.push({ name: 'Reback', query: { invite_code: $route.query.invite_code } })">
          <i class="iconfont iconziyuan1"></i>
          <span>{{ $t('message.i47') }}</span>
        </div>
        <div class="item" v-if="my_token" @click="logout">
          <i class="iconfont icontuichudenglu"></i>
          <span>{{ $t('message.i48') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Poster from './components/Poster'
import { mapGetters } from 'vuex'
// import { phoneModel } from '@/utiles'

export default {
  name: "Mine",
  components: {
    // Poster,
  },
  data() {
    return {
      press_vals: 0,
      now_level_obj: '',
      level: [],
      user_detail: {
        all_invite_count: 0
      }
    }
  },
  created() {
    // this.get_info()
  },
  activated() {
    // this.get_info()
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
      user_info: 'get_user_info',
      my_token: 'my_token',
    }),
  },
  methods: {
    async logout() {
      this.$store.commit('my_token', '')
      this.$store.commit('user_info', '')
      this.user_detail = ''
      this.now_level_obj = ''
      window.sessionStorage.clear()
    },
    gologin() {
      if (this.my_token) return
      this.$router.push({ name: 'Login', query: { type: 'login', invite_code: this.$route.query.invite_code } })
    }
  },
};
</script>

<style lang="less" scoped>
.mine::before {
  content: "";
  display: block;
  width: 93%;
  margin: 0 auto;
  height: 600px;
  background: #ffd602;
  position: fixed;
  left: 50%;
  top: 0;
  border-radius: 50%;
  transform: translate(-50%, -75%);
}
/* 手机 */
@media all and (max-width: 768px) {
    .mine::before {
        width: 175% !important;
    }
}
.mine {
  width: 100%;
  height: 100%;
  .content {
    position: relative;
    z-index: 1;
    padding-top: 75px;
    width: 90%;
    margin: 0 auto;
    .panel-login {
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .avatar {
        width: 76px;
        height: 76px;
        // margin-top: -60px;
        border-radius: 10px;
        // top: 35px;
        // position: absolute;
        .iconicon30 {
          font-size: 76px;
        }
      }
      .font {
        // position: absolute;
        // top: 110px;
        font-size: 16px;
      }
      .ood {
        // top: 130px;
        color: orange;
        margin-top: 5px;
      }
      .panel-bottom {
        display: flex;
        justify-content: space-around;
        position: absolute;
        width: 100%;
        top: 180px;
        .a1 {
          display: flex;
          flex-direction: column;
          p:first-child {
            font-weight: bold;
            font-size: 20px;
          }
          p:last-child {
            font-size: 15px;
            color: #000;
            opacity: 0.7;
          }
        }
      }
    }
    .panel {
      box-shadow: 0 13px 26px 0 #ffd602;
      border-radius: 10px;
      margin-bottom: 25px;
      background: #fff;
      font-size: 16px;
    }
    .level_box {
      background-color: #ffd602;
      border-radius: 10px;
      padding: 5px 10px;
      .top_box {
        display: flex;
        flex-direction: row;
        font-size: 15px;
        align-items: center;
        .left {
          width: calc(100% - 100px);
          .top {
            font-size: 13px;
            display: flex;
            justify-content: space-between;
            span:first-child {
              color: orange;
            }
          }
        }
        .right {
          width: 80px;
          margin-left: 15px;
          margin-top: 15px;
          button {
            width: 85px;
            height: 30px;
            background-color: #fff;
            border: none;
            border-radius: 20px;
          }
        }
      }
      p {
        font-size: 13px;
        text-align: left;
        span {
          i {
            color: orange;
            margin: 0 5px;
          }
        }
      }
    }
    .list {
      margin-top: 25px;
      border-radius: 10px;
      box-shadow: -2px 3px 3px 3px #f2f2f2;
      background-color: #fff;
      .item {
        display: flex;
        padding: 15px;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
        .iconfont {
          width: 30px;
          font-size: 20px;
          margin-right: 10px;
        }
        span {
          font-size: 16px;
        }
      }
      .item:last-child {
        border: none;
      }
    }
  }
}
</style>
