const state = {
    menus: {},
    ads: '',
    home_ads: '',
    others: '',
    types_bg: [],
    visitorId: '',
    video_lists: [],
    my_token: '',
    user_info: '',
    banner: [],
    line: '',
    notice: '',
    telegram: '',
    sheetVisible: false,
    isPcOrMobile: 1
}
const getters = {
    ads: state => state.ads,
    others: state => state.others,
    home_ads: state => state.home_ads,
    types_bg: state => state.types_bg,
    visitorId: state => state.visitorId,
    video_lists: state => state.video_lists,
    my_token: state => state.my_token,
    get_user_info: state => state.user_info,
    get_banner: state => state.banner,
    get_line: state => state.line,
    get_notice: state => state.notice,
    get_telegram: state => state.telegram,
    sheetVisible: state => state.sheetVisible,
    menus: state => state.menus,
    isPcOrMobile: state => state.isPcOrMobile,
}
const actions = {}
const mutations = {
    SETPCORMOBILE: (state, param) => {
        state.isPcOrMobile = param
    },
    set_ads: (state, param) => {
        state.ads = param
        state.home_ads = param.filter((item) => item.position === 'home_banner')
    },
    set_sheetVisible: (state, param) => {
        state.sheetVisible = param
    },
    set_others: (state, param) => {
        state.others = param
    },
    set_types_bg: (state, param) => {
        state.types_bg = param
    },
    SETVISITORID: (state, param) => {
        state.visitorId = param
    },
    video_lists: (state, param) => {
        state.video_lists = param
    },
    my_token: (state, param) => {
        window.localStorage.setItem('hkhk_my_token', param || '')
        state.my_token = param || ''
    },
    user_info: (state, param) => {
        window.localStorage.setItem('hkhk_user_info', JSON.stringify(param || {}))
        state.user_info = param || ''
    },
    all_datas: (state, param) => {
        state.banner = param.banner ? JSON.parse(param.banner) : []
        state.line = param.line ? JSON.parse(param.line) : ''
        state.notice = param.notice ? JSON.parse(param.notice) : ''
        state.telegram = param.telegram ? JSON.parse(param.telegram) : ''
        state.menus = {
            menu1: param.menu1 ? JSON.parse(param.menu1)[0] : '',
            menu2: param.menu2 ? JSON.parse(param.menu2)[0] : '',
            menu3: param.menu3 ? JSON.parse(param.menu3)[0] : '',
            menu4: param.menu4 ? JSON.parse(param.menu4)[0] : '',
            menu5: param.menu5 ? JSON.parse(param.menu5)[0] : '',
        }
        // console.log(state.menus)
    },
}


export default {
    namespaced:false,
    state,
    getters,
    actions,
    mutations
}