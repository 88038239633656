<template>
  <div class="vdList">
    <div class="box" v-if="lists && lists.length !== 0">
      <div
        class="items"
        v-for="(item, i) in lists"
        @click="goto_play(item)"
        :key="i + 'lists'"
      >
        <div class="top_bg">
          <img :src="require('@/assets/loading.gif')" alt="loading" />
          <div class="live" v-if="item.IsRun === 'True'">
            {{ item.TVUrl ? $t('message.i41') : $t('message.i66') }}
          </div>
        </div>
        <div class="center">
          <p>
            {{
              item.League
            }}
          </p>
        </div>
        <div class="bottom">
          <div class="ii">
            <p>
              {{
                (item.Home.length > 20
                  ? item.Home.substr(0, 20) + "..."
                  : item.Home) || "-"
              }}
            </p>
            <p class="vs">VS</p>
            <p>
              {{
                (item.Away.length > 20
                  ? item.Away.substr(0, 20) + "..."
                  : item.Away) || "-"
              }}
            </p>
          </div>
          <div class="code">
            <p>{{ item.HomeScore && item.IsRun === 'True' ? item.HomeScore : "-" }}</p>
            <p>{{ item.AwayScore && item.IsRun === 'True' ? item.AwayScore : "-" }}</p>
          </div>
          <p class="time" v-if="item.GameTime">{{ item.GameTime }}</p>
        </div>
      </div>
    </div>
    <NoData v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "VideoList",
  props: {
    lists: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      user_info: 'get_user_info'
    }),
  },
  methods: {
    async goto_play(item) {
      if (item.IsRun !== 'True') return
      const data = JSON.stringify(item);
      // console.log(this.user_info)
      const hadPhone = (this.user_info && this.user_info.account) || ''
      const params = {
        videourl: item.TVUrl || item.RTSUrl,
        home: item.Home,
        away: item.Away,
        league: item.League,
        socOddsId: item.SocOddsId,
        phone: hadPhone || 'somebody',
      }
      const { errNum } = await this.$http("total_looks", params);
      if (errNum === 1) {
        console.log('统计成功')
      }
      if (this.$route.name === "Play") {
        this.$router.replace({
          name: "Play",
          query: { params: data },
        });
      } else {
        this.$router.push({
          name: "Play",
          query: { params: data },
        });
      }
    },
  }
};
</script>

<style lang="less" scoped>
.vdList {
  width: 100%;
  // padding: 5px 10px;
  .box {
    width: 100%;
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // justify-content: center;
    // height: 112px;
    // display: flex;
    /* 手机 */
    @media all and (max-width: 768px) {
        .items {
          width: 49% !important;
        }
    }
    .items {
      width: 260px;
      border-radius: 0 0 5px 5px;
      margin-bottom: 10px;
      // margin-right: 4px;
      /* 手机 */
      @media all and (max-width: 768px) {
        .top_bg {
          height: auto !important;
          img {
            width: 100% !important;
          }
        }
        .center {
          font-size: 12px !important;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }
        .bottom {
          .time {
            right: 14px !important;
            bottom: 20px !important;
            font-size: 11px !important;
          }
          .code {
            font-size: 12px !important;
          }
          .ii {
            font-size: 11px !important;
            .vs {
              font-size: 10px !important;
            }
          }
        }
      }
      .top_bg {
        background-color: #000;
        height: 215px;
        position: relative;
        border-left: 1px solid;
        border-right: 1px solid;
        border-top: 1px solid;
        border-color: #ffd602;
        border-radius: 5px 5px 0 0;
        // padding: 5px;
        .live {
          background-color: #dd0404;
          color: #fff;
          position: absolute;
          left: 10px;
          top: 10px;
          border-radius: 5px;
          padding: 3px;
          font-size: 10px;
        }
        img {
          width: 66%;
          border-radius: 5px 5px 0 0;
        }
      }
      .center {
        padding-top: 10px;
        padding-bottom: 10px;
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: #ffd602;
        background-color: #593c22;
        color: #fff;
        display: flex;
        font-size: 17px;
        padding-left: 15px;
        p {
          width: 100%;
          overflow:hidden; //超出的文本隐藏
          text-overflow:ellipsis; //溢出用省略号显示
          white-space:nowrap; //溢出不换
        }
      }
      .bottom {
        background-color: #bcbcbc;
        display: flex;
        padding: 10px 5px;
        border-radius: 0 0 5px 5px;
        position: relative;
        justify-content: space-between;
        .time {
          padding: 2px 5px;
          border-radius: 15px;
          color: #fff;
          background-color: #58595a;
          text-align: center !important;
          position: absolute;
          right: 22px;
          bottom: 27px;
          font-size: 14px;
        }
        .code {
          font-size: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .ii {
          font-size: 15px;
          p {
            text-align: left;
          }
          .vs {
            color: red;
            font-size: 14px;
            font-weight: bold;
            text-align: center !important;
          }
        }
      }
    }
    .item {
      // flex-shrink: 0;
      width: calc(50% - 10px);
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 100px;
        border-radius: 10px;
      }
      span {
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
