<template>
    <div class="score-detail">
        <mt-header v-if="isPcOrMobile === 1" :title="$t('message.积分榜')" :fixed="true">
            <div slot="left">
                <i @click="$router.go(-1)" class="iconfont iconzuojiantou"></i>
            </div>
        </mt-header>
        <div class="info">
            <div class="tops">
                <el-radio-group v-if="typeMenus" v-model="score" @input="inputs" size="small">
                    <el-radio-button v-for="(item, i) in typeMenus.scores" :key="i" :label="item"></el-radio-button>
                </el-radio-group>
            </div>
            <el-table :data="cpTableData" border :style="[isPcOrMobile === 1 ? { width: '100%' } : { width: '50%', margin: '0 auto' }]">
                <el-table-column prop="rank" :label="$t('message.排名')" min-width="50">
                </el-table-column>
                <el-table-column prop="team" :label="$t('message.球队')" min-width="70">
                </el-table-column>
                <el-table-column prop="sessions" :label="$t('message.场次')" min-width="50">
                </el-table-column>
                <el-table-column prop="win" :label="$t('message.胜')" min-width="35">
                </el-table-column>
                <el-table-column prop="flat" :label="$t('message.平')" min-width="35">
                </el-table-column>
                <el-table-column prop="burden" :label="$t('message.负')" min-width="35">
                </el-table-column>
                <el-table-column prop="coverball" label="+/-" min-width="50">
                </el-table-column>
                <el-table-column prop="score" :label="$t('message.积分')" min-width="50">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            score: '',
            scores: [],
            tableData: []
        };
    },
    // watch: {
    //     '$route.query.type': 'getPath'
    // },
    activated() {
        console.log(1)
        // 当组件被激活时启用 $route 的监听
        this.$watch('$route.query.type', this.getPath);
    },
    deactivated() {
        console.log(2)
        // 当组件失活时停止 $route 的监听
        this.$watch('$route.query.type', null);
    },
    created() {
        this.getPath()
    },

    computed: {
        ...mapGetters({
            getCont: "getCont",
            isPcOrMobile: 'isPcOrMobile',
            typeMenus: 'typeMenus'
        }),
        cpTableData() {
            const data = this.tableData || [];
            return data.filter(function(item, m) {
                return m < 20;
            });
        },
    },
    methods: {
        getPath() {
            this.score = this.$route.query.type
            this.getData();
        },
        inputs() {
            this.getData()
        },
        async getData() {
            const param = {
                siteName: "afb",
                page: 1,
                limit: 20,
                cont: this.getCont,
                type: this.score
            }
            // console.log(param)
            const { code, data } = await this.$http('getSgScores', param);
            // console.log(data)
            if (code === 20000 && data) {
                if (data && data.length !== 0) {
                    this.tableData = data
                } else {
                    this.tableData = []
                }
            } else {
                this.tableData = []
            }
        }
    },
};
</script>
<style lang="less" scoped>
.score-detail {
    /deep/ .mint-header.is-fixed {
        top: 68px !important;
    }

    .top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
    }

    .info {
        // background-color: #181818;
        padding: 68px 10px 10px 10px;
        color: #fff;

        .tops {
            margin-bottom: 20px;
        }

        .item {
            word-break: break-word;

            h3 {
                font-size: 20px;
            }

            p {
                margin-bottom: 5px;
            }
        }
    }
}
</style>