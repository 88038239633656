<template>
  <div id="app">
    <mt-actionsheet :actions="actions" cancelText="" :closeOnClickModal="false" v-model="sheetVisible2">
    </mt-actionsheet>
    <mt-actionsheet :actions="actions2" cancelText="" :closeOnClickModal="false" v-model="sheetVisible3">
    </mt-actionsheet>
    <mt-actionsheet :actions="actions3" cancelText="" :closeOnClickModal="false" v-model="sheetVisible4">
    </mt-actionsheet>
    <mt-actionsheet :actions="actions4" cancelText="" :closeOnClickModal="false" v-model="sheetVisible5">
    </mt-actionsheet>
    <mt-actionsheet :actions="actions5" cancelText="" :closeOnClickModal="false" v-model="sheetVisible6">
    </mt-actionsheet>
    <Search />
    <router-view />
    <div class="bg" v-if="show_">
      <!-- <img  :src="" alt="pic" /> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import { mapGetters } from 'vuex'
import Search from "./views/Home/components/Search";
export default {
  name: "vue",
  components: {
    Search
  },
  data() {
    return {
      timer: '',
      time: 6,
      show_: true,
      sheetVisible3: false,
      sheetVisible4: false,
      sheetVisible5: false,
      sheetVisible6: false,
      actions5: [],
      actions4: [],
      actions3: [],
      actions2: [{
          name: '中文',
          method: this.select,
          key: 'hk'
        },
        {
          name: 'ไทย',
          method: this.select,
          key: 'tai'
        },
        {
          name: 'English',
          method: this.select,
          key: 'en'
        },
        {
          name: this.$t('message.i65'),
          method: this.select,
          key: 'close'
        },
      ]
    };
  },
  created() {
    const my_token = window.localStorage.getItem('hkhk_my_token')
    this.$store.commit('my_token', my_token)
    const user_info = window.localStorage.getItem('hkhk_user_info')
    this.$store.commit('user_info', JSON.parse(user_info))
    this.timer_get()
    this.getDatas()
    this.getMeanus()
    window.setTimeout(() => {
      this.show_ = false;
    }, 1500)
  },
  computed: {
    ...mapGetters({
      sheetVisible: 'sheetVisible',
      menus: 'menus',
      typeMenus: 'typeMenus'
    }),
    actions: {
      //getter 方法
      get() {
        return [
          // 首页
          {
            name: this.$t('message.i40'),
            method: this.select2,
            key: '1'
          },
          // 直播
          {
            name: this.$t('message.i41'),
            method: this.select2,
            key: '2'
          },
          // 赛程
          {
            name: this.$t('message.i42'),
            method: this.select2,
            key: '3'
          },
          // 积分榜
          {
            name: '+ ' + this.$t('message.积分榜'),
            method: this.select2,
            key: '4'
          },
          // 新闻
          {
            name: '+ ' + this.$t('message.i67'),
            method: this.select2,
            key: '5'
          },
          {
            name: this.$t('message.心水推荐'),
            method: this.select2,
            key: '6'
          },
          {
            name: '+ ' + this.$t('message.资讯文章'),
            method: this.select2,
            key: '7'
          },
          {
            name: this.$t('message.星汇娱乐'),
            method: this.select2,
            key: '8'
          },
          {
            name: this.$t('message.优惠'),
            method: this.select2,
            key: '9'
          },
          {
            name: '中文/En',
            method: this.select2,
            key: '10'
          },
          {
            name: this.$t('message.i65'),
            method: this.select2,
            key: 'close'
          },
        ]
      },
      //setter 方法
      set(newValue) {
        // console.log(newValue)
        return newValue
      }
    },
    sheetVisible2: {
      //getter 方法
      get() {
        return this.sheetVisible
      },
      //setter 方法
      set(newValue) {
        return newValue
      }
    }
  },
  mounted() {
    const _this = this
    _this.matchMedia(_this)
    window.addEventListener('resize', function() {
      _this.matchMedia(_this)
    });
  },
  destroyed() {
    window.clearTimeout(this.timer)
    this.timer = ''
  },
  methods: {
    async getMeanus() {
      const { code, data } = await this.$http('getMeanus', {});
      if (code === 20000 && data) {
        // console.log(data)
        this.actions3 = []
        data.scores.forEach((item, i) => {
          this.actions3.push({
            name: item,
            method: this.select3,
            key: i
          })
        })
        this.actions3.push({
          name: this.$t('message.i65'),
          method: this.select3,
          key: 'close'
        })

        this.actions4 = []
        data.news.forEach((item, i) => {
          this.actions4.push({
            name: item,
            method: this.select4,
            key: i
          })
        })
        this.actions4.push({
          name: this.$t('message.i65'),
          method: this.select4,
          key: 'close'
        })

        this.actions5 = []
        data.teach.forEach((item, i) => {
          this.actions5.push({
            name: item,
            method: this.select5,
            key: i
          })
        })
        this.actions5.push({
          name: this.$t('message.i65'),
          method: this.select5,
          key: 'close'
        })
        this.$store.commit('SET_MENUS', data)
      }
    },
    matchMedia(_this) {
      // 在这里添加您想要执行的操作
      if (window.matchMedia("(max-width: 1380px)").matches) {
        // 窗口宽度小于等于1380像素
        _this.$store.commit('SETPCORMOBILE', 1)
      } else {
        // 窗口宽度大于1380像素
        _this.$store.commit('SETPCORMOBILE', 0)
      }
    },
    select5(e) {
      this.sheetVisible6 = false
      if (e.key === 0) {
        this.$router.push({ name: 'Teach', query: { type: e.name } })
      } else if (e.key === 1) {
        this.$router.push({ name: 'Teach', query: { type: e.name } })
      } else if (e.key === 2) {
        this.$router.push({ name: 'Teach', query: { type: e.name } })
      } else if (e.key === 'close') {
        return
      }
    },
    select4(e) {
      this.sheetVisible5 = false
      if (e.key === 0) {
        this.$router.push({ name: 'News', query: { type: e.name } })
      } else if (e.key === 1) {
        this.$router.push({ name: 'News', query: { type: e.name } })
      } else if (e.key === 2) {
        this.$router.push({ name: 'News', query: { type: e.name } })
      } else if (e.key === 'close') {
        return
      }
    },
    select3(e) {
      this.sheetVisible4 = false
      if (e.key === 0) {
        this.$router.push({ name: 'Score', query: { type: e.name } })
      } else if (e.key === 1) {
        this.$router.push({ name: 'Score', query: { type: e.name } })
      } else if (e.key === 2) {
        this.$router.push({ name: 'Score', query: { type: e.name } })
      } else if (e.key === 'close') {
        return
      }
    },
    select2(e) {
      // console.log(e)
      let configs = ''
      if (e.key === '10') {
        this.$store.commit('set_sheetVisible', false)
        this.sheetVisible3 = true
        return
      } else if (e.key === '1') {
        this.$router.push({ name: 'Home' })
        this.$store.commit('set_sheetVisible', false)
        return
      } else if (e.key === '2') {
        this.$router.push({ name: 'Types' })
        this.$store.commit('set_sheetVisible', false)
        return
      } else if (e.key === '3') {
        this.$router.push({ name: 'Extend' })
        this.$store.commit('set_sheetVisible', false)
        return
      } else if (e.key === '4') {
        this.$store.commit('set_sheetVisible', false)
        this.sheetVisible4 = true
        return
      } else if (e.key === '5') {
        this.$store.commit('set_sheetVisible', false)
        this.sheetVisible5 = true
        return
      } else if (e.key === '6') {
        this.$router.push({ name: 'Tips' })
        this.$store.commit('set_sheetVisible', false)
        return
      } else if (e.key === '7') {
        this.$store.commit('set_sheetVisible', false)
        this.sheetVisible6 = true
        return
      } else if (e.key === '8') {
        configs = this.typeMenus.links[0]
        this.$store.commit('set_sheetVisible', false)
      } else if (e.key === '9') {
        configs = this.typeMenus.links[1]
        this.$store.commit('set_sheetVisible', false)
      } else {
        this.$store.commit('set_sheetVisible', false)
        return
      }
      window.open(configs)
      // eslint-disable-next-line
      // if (configs) {
      //   if (configs.open_type === '新开窗口打开') {
      //     window.open(configs.link)
      //   } else {
      //     window.location.href = configs.link
      //   }
      // }
    },
    select(e) {
      // console.log(e)
      this.sheetVisible3 = false
      if (e.key === 'hk') {
        this.$store.commit('SETCONT', 'TraditionalChinese')
      } else if (e.key === 'tai') {
        this.$store.commit('SETCONT', 'Thailand')
      } else if (e.key === 'en') {
        this.$store.commit('SETCONT', 'English')
      } else if (e.key === 'close') {
        return
      }
      this.$i18n.locale = e.key
      localStorage.setItem('i18lang', e.key)
      this.getVideos()
      this.getDatas()
    },
    async getDatas() {
      let lang = 'hk'
      if (localStorage.getItem('i18lang')) {
        lang = localStorage.getItem('i18lang')
      } else {
        localStorage.setItem('i18lang', 'hk')
      }
      const params = {
        // site: location.host,
        site: 'starsport.tv',
        cont: lang,
        type: ''
      }
      const { code, data } = await this.$http('get_cont_data', params);
      if (code === 20000 && data) {
        // console.log(data)
        this.$store.commit('all_datas', data)
      }
    },
    timer_get() {
      this.getVideos()
      this.timer = window.setTimeout(() => {
        this.timer_get()
      }, 60000)
    },
    async getVideos() {
      let lang = 'hk'
      if (localStorage.getItem('i18lang')) {
        lang = localStorage.getItem('i18lang')
      } else {
        localStorage.setItem('i18lang', 'hk')
      }
      const params = {
        lang: lang
      }
      const { errNum, data } = await this.$http('videoLists', params);
      // console.log(errNum)
      // console.log(data)
      if (JSON.stringify(data) === '{}') {
        return
      }
      const datas = data && JSON.parse(data)
      if (errNum === 1 && datas) {
        // console.log(datas.data)
        // datas.data.forEach(item => {
        //   item.looknum = 11
        // });
        this.$store.commit('video_lists', datas && datas.data)
      } else {
        this.close()
      }
    },
    close() {
      this.show_ = false;
    },
  },
};
</script>
<style lang="less">
#cnzz_stat_icon_1279854591 {
  display: none;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  font-family: "Fahkwang";
  background-image: url('./assets/comBg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

/* 手机 */
@media all and (max-width: 768px) {
  #app {
    width: 100% !important;
  }
}

button {
  outline: none;
}

ul,
li {
  list-style: none;
  -webkit-padding-start: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  position: relative;
  padding-top: 68px;
}

#app {
  text-align: center;
  color: #2c3e50;
  font-size: 20px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.bg {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 3;
  top: 0;

  .download_box {
    position: relative;

    img {
      position: absolute;
      width: 312px;
      height: 86px;
      bottom: 79px;
    }

    .left {
      left: 20px;
    }

    .right {
      right: 20px;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  #time {
    width: 50px;
    height: 50px;
    background-color: #9e9e9e;
    opacity: 0.9;
    color: white;
    z-index: 2;
    border-radius: 50%;
    // margin-left: 350px;
    position: absolute;
    top: 23px;
    right: 15px;
    padding-top: 12px;
  }
}

.bon {
  font-size: 20px;
  background-color: #9e9e9e;
  opacity: 0.9;
  color: white;
  position: absolute;
  top: 23px;
  right: 15px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>