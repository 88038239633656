export default {
    /**
     * 获取关于我们页面
     */
    getSgAboutme: params => {
        return {
            url: '/admin/getSgAboutme',
            method: 'post',
            params
        }
    },
    /**
     * 获取积分榜页面
     */
    getSgScores: params => {
        return {
            url: '/admin/getSgScores',
            method: 'post',
            params
        }
    },
}
