const state = {
    teachLoadState: true,
    teachIdList: [],
    teachData: [],
    teachPage: 1
}
const getters = {
    getTeachData: state => state.teachData,
    getTeachPage: state => state.teachPage,
    getTeachLoadState: state => state.teachLoadState,
    getTeachIdList: state => state.teachIdList
}
const actions = {
    getTeachData: (({
        commit
    }, param) => {
        const _this = param._this
        _this.$http("getSgTeach", param)
            .then((res) => {
                if (res.errNum == 1) {
                    const teachData = []
                    if (res.data && res.data.length !== 0) {
                        res.data.forEach(item => {
                            if (window.myConfigs.isNotApp) {
                                const be = window.location.protocol ? window.location.protocol : 'http:'
                                item.content = item.content.replace(/http:/g, be)
                            }
                            teachData.push(item)
                        })
                    }
                    const backdata = {
                        allPage: res.allPage,
                        allCount: res.allCount,
                        data: teachData
                    }
                    if (typeof param.c !== 'undefined') {
                        if (res.data.length === 0) {
                            param.c(true);
                            return
                        }
                        param.c()
                    }
                    commit('TEACH', {
                        a: param.page,
                        b: backdata,
                        name: param.pagename
                    })
                }
            })
    }),
}
const mutations = {
    UPDATETEACHLOADSTATE: (state, param) => {
        state.set_user_info = param
    },
    UPDATETEACHPAGE: (state, param) => {
        state.set_user_info = param
    },
    RESETTEACH: (state) => {
        state.teachData.data = []
    },
    TEACH: (state, param) => {
        let ddd = [];
        if (param.a > 1 && typeof param.name === 'undefined') {
            const aaa = state.teachData.data
            ddd = param.b.data
            ddd.forEach((item) => {
                item.page = param.a
            });
            param.b.data = ddd

            state.teachData.data = aaa.concat(param.b.data)
        } else {
            ddd = param.b.data
            ddd.forEach((item) => {
                item.page = param.a
            })
            param.b.data = ddd
            state.teachData = param.b
        }
        const imgReg = /<img.*?(?:>|\/>)/gi
        for (let i = 0; i < param.b.data.length; i++) {
            const item = param.b.data[i]
            const imgStr = item.content
            let imgHtml = ''
            if ((imgStr.match(imgReg)) === null) {
                imgHtml = ''
            } else {
                imgHtml = (imgStr.match(imgReg))[0]
            }
            // eslint-disable-next-line
            const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
            item.img = imgHtml ? imgHtml.match(srcReg)[1] : ''
            item.contents = imgStr.replace(imgHtml, '')
            const tempElement = document.createElement('div');
            tempElement.innerHTML = item.contents;
            const extractedText = tempElement.textContent;
            item.textContent = extractedText
            state.teachIdList.push(item.id)
        }
    },
}


export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}