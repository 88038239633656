<template>
  <div class="GuessYouLike">
    <div class="top">
      <p class="title">
        <span>{{ $t('message.i38') }}</span>
      </p>
      <!-- <div @click="change">
            <span>换一换</span>
            <i class="iconfont iconshuaxin"></i>
        </div> -->
      <div class="more" @click="more()">{{ $t('message.i39') }}</div>
    </div>
    <VideoList :lists="guess_like" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  // props: {
  //   lists: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  computed: {
    ...mapGetters({
      video_lists: "video_lists",
    }),
    guess_like() {
      if (this.video_lists && this.video_lists.length !== 0) {
        const datas = this.video_lists.filter((item, key) => key < 4)
        return datas
      } else {
        return []
      }
    },
  },
  methods: {
    more() {
      this.$router.push({ name: 'Types' });
    },
    change() {
      this.$emit("change_guess");
    },
    goto_play(params) {
      const data = JSON.stringify(params);
      this.$router.push({
        name: "Play",
        query: { params: data, invite_code: this.$route.query.invite_code },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.GuessYouLike {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-around;
  padding: 15px 10px;
  .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      // border-bottom: 3px solid #fbe802;
      color: #ffd602;
    }
    .more {
      border: 1px solid;
      border-color: #ffd602;
      color: #ffd602;
      border-radius: 5px;
      padding: 5px 6px;
      font-size: 15px;
    }
    // div {
    //     font-size: 15px;
    //     color: #607d8b;
    //     span {
    //         margin-right: 5px;
    //     }
    // }
  }
  .box {
    width: 100%;
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // height: 112px;
    // display: flex;
    .items {
      width: 49%;
      border-radius: 0 0 5px 5px;
      margin-bottom: 10px;
      .top_bg {
        border-left: 1px solid;
        border-right: 1px solid;
        border-top: 1px solid;
        border-color: #ffd602;
        border-radius: 5px 5px 0 0;
        padding: 5px;
        img {
          width: 100%;
        }
      }
      .center {
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: #ffd602;
        background-color: #593c22;
        color: #fff;
        display: flex;
        font-size: 12px;
        /* justify-content: start; */
        padding-left: 15px;
        text-align: left;
      }
      .bottom {
        background-color: #bcbcbc;
        display: flex;
        padding: 10px 5px;
        border-radius: 0 0 5px 5px;
        position: relative;
        justify-content: space-between;
        .time {
          padding: 2px 5px;
          border-radius: 15px;
          color: #fff;
          background-color: #58595a;
          text-align: center !important;
          position: absolute;
          right: 14px;
          bottom: 20px;
          font-size: 11px;
        }
        .code {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .ii {
          font-size: 11px;
          p {
            text-align: left;
          }
          .vs {
            color: red;
            font-size: 10px;
            font-weight: bold;
            text-align: center !important;
          }
        }
      }
    }
    .item {
      // flex-shrink: 0;
      width: calc(50% - 10px);
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 100px;
        border-radius: 10px;
      }
      span {
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
