const state = {
    set_user_info: {}
}
const getters = {
    set_user_info: state => state.set_user_info,
}
const actions = {

}
const mutations = {
    set_user_info: (state, param) => {
        state.set_user_info = param
    },
}


export default {
    namespaced:false,
    state,
    getters,
    actions,
    mutations
}