<template>
    <div class="aboutme-detail">
        <mt-header v-if="isPcOrMobile === 1" :title="$t('message.关于我们')" :fixed="true">
            <div slot="left">
                <i @click="$router.go(-1)" class="iconfont iconzuojiantou"></i>
            </div>
        </mt-header>
        <div class="info">
            <div class="item">
                <!-- <h3>{{datas.title}}</h3> -->
                <!-- <p>{{datas.addtime}}</p> -->
                <!-- <p v-if="datas.img">
                    <img style="max-width: 100%;" :src="datas.img" alt="newsPic">
                </p> -->
                <div v-html="datas.contents"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            datas: {},
        };
    },
    created() {
        this.getData();
    },

    computed: {
        ...mapGetters({
            getCont: "getCont",
            isPcOrMobile: "isPcOrMobile",
        }),
    },
    methods: {
        async getData() {
            const param = {
                siteName: "afb",
                page: 1,
                limit: 10,
                cont: this.getCont
            }
            // console.log(param)
            const { code, data } = await this.$http('getSgAboutme', param);
            // console.log(data)
            if (code === 20000 && data) {
                const newsData = []
                if (data && data.length !== 0) {
                    data.forEach(item => {
                        const be = window.location.protocol ? window.location.protocol : 'http:'
                        item.content = item.content.replace(/http:/g, be)
                        newsData.push(item)
                    })
                    if (newsData && newsData.length !== 0) {
                        const imgReg = /<img.*?(?:>|\/>)/gi
                        for (let i = 0; i < newsData.length; i++) {
                            const item = newsData[i]
                            const imgStr = item.content
                            let imgHtml = ''
                            if ((imgStr.match(imgReg)) === null) { imgHtml = '' } else { imgHtml = (imgStr.match(imgReg))[0] }
                            // eslint-disable-next-line
                            const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
                            item.img = imgHtml ? imgHtml.match(srcReg)[1] : ''
                            item.contents = imgStr
                        }
                        this.datas = newsData[0]
                    }
                }
            }
        }
    },
};
</script>
<style lang="less" scoped>
.aboutme-detail {
    /deep/ .mint-header.is-fixed {
        top: 68px !important;
    }

    .top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
    }

    .info {
        // background-color: #181818;
        padding: 68px 10px 10px 10px;
        color: #fff;

        .item {
            word-break: break-word;

            h3 {
                font-size: 20px;
            }

            p {
                margin-bottom: 5px;
            }
        }
    }
}
</style>