<template>
  <div class="Play">
    <mt-header :title="title" :fixed="true">
      <div slot="left">
        <i @click="back_on()" class="iconfont iconzuojiantou"></i>
      </div>
      <div slot="right">
        <p v-if="!longScreen && isMobile()" @click="go_look">{{ $t('message.i64') }}</p>
      </div>
    </mt-header>
    <div class="bodys">
      <div class="video" :class="[longScreen ? 'only' : '', fullscreen ? 'fullscreen' : '']">
        <iframe
          :src="videoUrlNormal"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <div class="tabs">
        <!-- <p @click="show(1)">聊天室</p> -->
        <!-- <p @click="show(2)">賽程</p> -->
        <!-- <p @click="show(3)">实时战况</p> -->
        <p :class="[selected === item.key ? 'sel' : '']" v-for="(item, i) in tabs" :key="i + item" @click="show(item.key)">{{ item.name }}</p>
      </div>
      <div class="showBody">
        <VideoList v-if="selected === 2" :lists="runlist" />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utiles";
import { mapGetters } from 'vuex';

export default {
  name: "Play",
  components: {},
  data() {
    return {
      videoUrlNormal: "",
      longScreen: false,
      fullscreen: false,
      title: '',
      selected: 2
    };
  },
  watch: {
    $route: {
      handler: "init",
      deep: true,
    },
  },
  created() {
    this.init();
  },
  destroyed() {
    document.title = 'starsport.tv'
  },
  computed: {
    ...mapGetters({
      video_lists: "video_lists",
    }),
    tabs() {
      return [
        {
          key: 2,
          name: this.$t('message.i42')
        }
      ]
    },
    runlist() {
      if (this.video_lists && this.video_lists.length !== 0) {
        const datas = this.video_lists.filter(item => item.IsRun === 'True')
        return datas
      } else {
        return []
      }
    },
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    go_look() {
      // fullscreen = true
      this.$router.push({ name: 'Fullscreen', query: { url: this.videoUrlNormal } })
    },
    back_on() {
      this.$router.push({ name: 'Types' })
    },
    // show(e) {
    //   this.selected = e
    // },
    async delete_select() {
      const params = {
        uid: this.user_info ? this.user_info.id : "",
        ids: [this.collect_id],
      };
      const { errNum, data } = await this.$http("deleteCollect", params);
      if (errNum === 1 && data) {
        this.$Toast("取消成功");
        this.get_collect();
      } else {
        this.$Toast("发生错误");
      }
    },
    change_status: debounce(function () {
      const uid = this.user_info ? this.user_info.id : "";
      if (!uid) {
        this.$Toast("请先注册或登录，登录观影次数+5喔");
        return;
      }
      this.addto_collect(uid);
    }, 1000),
    async addto_collect(uid) {
      if (this.is_collect) {
        this.delete_select();
        return;
      }
      const params = {
        uid: uid,
        vid: this.vid || "",
        title: this.title || "",
        img_url: this.posterImg || "",
        video_url: this.videoUrlNormal || "",
        tag: this.tag || "",
        first_classify: this.first_classify || "",
        second_classify: this.second_classify || "",
      };
      const { errNum } = await this.$http("setCollect", params);
      if (errNum === 1) {
        this.get_collect();
        this.$Toast("收藏成功");
      } else {
        this.$Toast("收藏失败");
      }
    },
    init() {
      const params = this.$route.query.params
        ? JSON.parse(this.$route.query.params)
        : "";
      if (params) {
        // console.log(params)
        if (!params.TVUrl) {
          this.longScreen = true
        } else {
          this.longScreen = false
        }
        this.videoUrlNormal = params.TVUrl || params.RTSUrl;
        this.title = params.League
        document.title = params.Home + ' VS ' + params.Away
      }
    },
    async playi() {
      const params = {
        uid: this.user_info ? this.user_info.id : "",
        vid: this.vid,
        title: this.title,
        img_url: this.posterImg,
        video_url: this.videoUrlNormal,
        tag: this.tag,
        first_classify: this.first_classify || "",
        second_classify: this.second_classify || "",
      };
      const { errNum, data } = await this.$http("play", params);
      if (errNum === 1) {
        // console.log(data)
        if (data && data.length !== 0) {
          this.user_detail = data[0] || "";
        }
      }
    },
    async get_info() {
      const params = {
        uid: this.user_info ? this.user_info.id : "",
      };
      const { errNum, data } = await this.$http("getUsers", params);
      if (errNum === 1) {
        this.user_detail = data[0] || "";
      } else {
        this.user_detail = "";
        this.$Toast("获取用户信息出现错误");
      }
    },
    async updateGuess() {
      const params = {
        uid: this.user_info ? this.user_info.id : "",
      };
      const { errNum, data } = await this.$http("updateGuess", params);
      if (errNum === 1) {
        this.lists = data || [];
      } else {
        this.lists = [];
        this.$Toast("获取列表出现错误");
      }
    },
    async get_collect() {
      const params = {
        uid: this.user_info ? this.user_info.id : "",
        vid: this.vid || "",
      };
      const { errNum, data } = await this.$http("getCollect", params);
      if (errNum === 1) {
        const datas = data || [];
        if (datas && datas.length !== 0) {
          this.is_collect = true;
          this.collect_id = datas[0].id;
        } else {
          this.is_collect = false;
        }
      } else {
        this.is_collect = false;
      }
    },
    goto_extend() {
      this.$router.push({
        name: "Extend",
        query: { invite_code: this.$route.query.invite_code },
      });
    },
    loadTop() {
      // 加载更多数据
      this.$refs.loadmore.onTopLoaded();
      // console.log("log_top");
      this.updateGuess();
    },
    loadBottom() {
      this.allLoaded = true; // 若数据已全部获取完毕
      // console.log("log_bottom");
      this.$refs.loadmore.onBottomLoaded();
    },
  },
};
</script>

<style lang="less" scoped>
.Play {
  padding-top: 40px;
  height: calc(100% - 0.2rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  /deep/ .mint-header.is-fixed {
    top: 68px !important;
  }
  .bodys {
    .fullscreen {
      display: flex;
      position: fixed;
      // 没有关闭按钮
      // top: 27%;
      // left: -58%;
      // width: 100vh !important;
      // 安卓处理样式
      top: 25%;
      left: -169px;
      width: calc(100vh - 60px) !important;
      height: 100vw !important;
      // 苹果处理样式
      // top: 179px;
      // left: -180px;
      // width: calc(100vh - 60px) !important;
      // height: 89vw !important;

      transform: rotate(90deg);
      z-index: 99;
      .close_ {
        font-size: 15px;
        color: #fff;
        // transform: rotate(90deg);
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #000;
        width: 30px;
        justify-content: center;
        .ii {
          transform: rotate(90deg);
        }
      }
    }
    .showBody {
      padding: 5px 10px;
    }
    .tabs {
      display: flex;
      color: #fff;
      font-size: 14px;
      background-color: #171717;
      .sel {
        color: #ffd602;
        border-bottom: 2px solid #ffd602;
      }
      .noSel {
        color: #fff;
        border: none !important;
      }
      p {
        padding: 10px 12px;
      }
    }
    .only {
      height: 645px !important;
    }
    .video {
      width: 100%;
      height: 400px;
    }
     /* 手机 */
    @media all and (max-width: 768px) {
      .video {
        height: 200px !important;
      }
      .only {
        height: 445px !important;
      }
    }
  }
  .center {
    padding: 20px;
    padding-bottom: 0;
    .ad_box {
      background-color: #fbe802;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      padding: 10px;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 14px;
      }
      .iconplume {
        color: #fff;
        font-size: 30px;
      }
    }
    .center_ss {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      p {
        font-size: 20px;
        padding: 5px 0;
        text-align: left;
      }
      .bottom_ss {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left {
          color: #607d8b;
          font-size: 17px;
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          .iconjiarushoucang {
            font-size: 22px;
          }
        }
      }
    }
    .tag {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        padding: 5px 10px;
        background-color: #f7efe4;
        color: #997e55;
        font-size: 14px;
        border-radius: 15px;
        margin-right: 10px;
      }
    }
  }
  .bottom_title {
    width: 80px;
    text-align: left;
    font-size: 18px;
    border-bottom: 3px solid #fbe802;
    margin-left: 15px;
  }
}
</style>
