<template>
  <div class="home">
    <!-- <Search /> -->
    <!-- @click="goto(item.type, item.params)" -->
    <div class="swipe_box" v-if="banner && banner.length !== 0">
      <mt-swipe :auto="4000" continuous :stopPropagation="true" ref="swipeItem">
        <mt-swipe-item v-for="(item, index) in banner" :key="index">
          <img @click="goin(item)" :src="item.img_link" alt="pic" />
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <!-- <HomeTab :items="home_tab" /> -->
    <!-- <Poster /> -->
    <Recommend />
    <GuessYouLike />
    <HotNews :list="backdata1" />
    <TeachBall :list="backdata2" />
    <!-- <Types :Types="types" /> -->
    <div class="footer">
      <h4>KEPP in Touch</h4>
      <div class="logos">
        <img @click="linkTo(2)" src="@/assets/others/facebook.jpg">
        <img @click="linkTo(3)" src="@/assets/others/ig.jpg">
        <img @click="linkTo(4)" src="@/assets/others/tg.png">
      </div>
      <h4>All Rights Reserved CopyRight@ayiteam2023 </h4>
    </div>
  </div>
</template>
<style lang="less" scoped>
.home {

  // padding-top: 68px;
  .swipe_box {
    width: 100%;
    height: 400px;

    img {
      width: 100%;
      height: 400px;
    }
  }

  /* 手机 */
  @media all and (max-width: 768px) {
    .swipe_box {
      width: 100% !important;
      height: 120px !important;

      img {
        height: 120px !important;
      }
    }

    .footer {
      width: 100% !important;
    }
  }

  .footer {
    margin-top: 50px;
    display: flex;
    color: #FFC107;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-left: 20px;

    h4 {
      text-align: left;
    }

    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;

      img {
        width: 40px;
        margin-right: 10px;
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
// import Search from "./components/Search";
// import HomeTab from "./components/HomeTab";
// import Poster from "./components/Poster";
import Recommend from "./components/Recommend";
import HotNews from "./components/HotNews";
import TeachBall from "./components/TeachBall";
// import GuessYouLike from "./components/GuessYouLike";
// import Types from "./components/Types";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Recommend,
    HotNews,
    TeachBall,
  },
  computed: {
    ...mapGetters({
      // home_ads: "home_ads",
      others: 'others',
      banner: 'get_banner',
      getCont: "getCont",
      typeMenus: 'typeMenus'
    }),
  },
  data() {
    return {
      backdata1: [],
      backdata2: [],
      home_ads: [{
          img_url: require('@/assets/banner/banner1.jpg'),
        },
        {
          img_url: require('@/assets/banner/banner2.jpg'),
        },
        {
          img_url: require('@/assets/banner/banner3.jpg'),
        }
      ],
      swipe_list: [
        // {
        //   type: "play",
        //   params: {
        //     id: 1,
        //     uid: 58,
        //     video_url:
        //       "http://qqkuz6cdg.hn-bkt.clouddn.com/%27Stay%20High%27%20-%20Tove%20Lo%20-%20Against%20The%20Current%20Cover-ARO82lUakMw.mp4",
        //     img_url: "http://qqkuz6cdg.hn-bkt.clouddn.com/one.png",
        //     title: "Stay High - Tove Lo - Against The Current Cover",
        //     first_classify: "中国",
        //     second_classify: "MV",
        //     tag: "MV,国外,歌唱,音乐,自创",
        //   },
        //   img: require("@/assets/vd_ad2.jpg"),
        // },
        // {
        //   type: "typeList",
        //   params: {
        //     first_classify: "中国",
        //     second_classifys: "MV,直播,自创",
        //     dec: "国内知名主播，唱跳rap，自创音乐合集",
        //     tag: "MV,国内,歌唱,音乐,自创",
        //   },
        //   img: require("@/assets/vd_ad3.jpg"),
        // },
        {
          type: "link",
          params: {},
          img: '',
        },
      ],
      home_tab: [],
      types: [],
      recommends: [],
      guessYouLikes: []
    };
  },
  created() {
    this.getData();
    this.getTachData();
    // this.updateGuess();
  },
  methods: {
    sss(data) {
      const imgReg = /<img.*?(?:>|\/>)/gi
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const imgStr = item.content
        let imgHtml = ''
        if ((imgStr.match(imgReg)) === null) {
          imgHtml = ''
        } else {
          imgHtml = (imgStr.match(imgReg))[0]
        }
        // eslint-disable-next-line
        const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
        item.img = imgHtml ? imgHtml.match(srcReg)[1] : ''
        item.contents = imgStr.replace(imgHtml, '')
        const tempElement = document.createElement('div');
        tempElement.innerHTML = item.contents;
        const extractedText = tempElement.textContent;
        item.textContent = extractedText
      }
    },
    linkTo(index) {
      const { links } = this.typeMenus
      window.open(links[index])
    },
    async getTachData() {
      const locale = this.$i18n.locale
      if (locale === 'hk') {
        this.$store.commit('SETCONT', 'TraditionalChinese')
      } else if (locale === 'tai') {
        this.$store.commit('SETCONT', 'Thailand')
      } else if (locale === 'en') {
        this.$store.commit('SETCONT', 'English')
      }

      const param = {
        siteName: "afb",
        page: 1,
        limit: 10,
        cont: this.getCont
      }
      const { code, data } = await this.$http('getSgTeach', param);
      // console.log(data)
      if (code === 20000 && data) {
        const newsData = []
        if (data && data.length !== 0) {
          data.forEach(item => {
            // if (window.myConfigs.isNotApp) {
            const be = window.location.protocol ? window.location.protocol : 'http:'
            item.content = item.content.replace(/http:/g, be)
            // }
            newsData.push(item)
          })
        }
        const backdata = {
          allPage: data.allPage,
          allCount: data.allCount,
          data: newsData
        }
        if (typeof param.c !== 'undefined') {
          if (data.length === 0) {
            param.c(true);
            return
          }
          param.c()
        }
        // this.$store.commit('TEACH', {
        //   a: param.page,
        //   b: backdata,
        //   name: param.pagename
        // })
        this.sss(backdata.data)
        this.backdata2 = backdata.data
        // console.log(this.backdata2)
      }
    },
    async getData() {
      const locale = this.$i18n.locale
      if (locale === 'hk') {
        this.$store.commit('SETCONT', 'TraditionalChinese')
      } else if (locale === 'tai') {
        this.$store.commit('SETCONT', 'Thailand')
      } else if (locale === 'en') {
        this.$store.commit('SETCONT', 'English')
      }

      const param = {
        siteName: "afb",
        page: 1,
        limit: 10,
        cont: this.getCont
      }
      const { code, data } = await this.$http('getSgNews', param);
      // console.log(data)
      if (code === 20000 && data) {
        const newsData = []
        if (data && data.length !== 0) {
          data.forEach(item => {
            // if (window.myConfigs.isNotApp) {
            const be = window.location.protocol ? window.location.protocol : 'http:'
            item.content = item.content.replace(/http:/g, be)
            // }
            newsData.push(item)
          })
        }
        const backdata = {
          allPage: data.allPage,
          allCount: data.allCount,
          data: newsData
        }
        if (typeof param.c !== 'undefined') {
          if (data.length === 0) {
            param.c(true);
            return
          }
          param.c()
        }
        // this.$store.commit('NEWS', {
        //   a: param.page,
        //   b: backdata,
        //   name: param.pagename
        // })
        this.sss(backdata.data)
        this.backdata1 = backdata.data
        // console.log(this.backdata1)
      }
    },
    goin(item) {
      if (!this.banner) return
      if (item.open_type === '新开窗口打开') {
        window.open(item.link)
      } else {
        window.location.href = item.link
      }
    },
    goto(type, params, k) {
      const data = JSON.stringify(params);
      if (type === "play") {
        this.$router.push({
          name: "Play",
          query: { params: data, invite_code: this.$route.query.invite_code },
        });
      } else if (type === "typeList") {
        this.$router.push({
          name: "TypeList",
          query: { params: data, invite_code: this.$route.query.invite_code },
        });
      } else if (type === "link") {
        window.open(k);
      }
    },
  },
};
</script>