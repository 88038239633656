<template>
  <div class="game">
    <Poster />
    <!-- <div class="top_img">
      <img class="hongbao" src="@/assets/hongbao.jpg" alt="" />
      <img class="ad_1" src="@/assets/adv.jpg" alt="" />
    </div>
    <div class="tab">
      <button>立即下载</button>
      <button>激活码兑换</button>
    </div>
    <div class="des">
      活动说明
      <p>1.XXXXXXXXXXXXXXX</p>
      <p>2.XXXXXXXXXXXXXX</p>
      <p>3.XXXXXXXXXXXXXXXXXXX</p>
      <p>4.XXXXXXXXXXXXXXXXXXXXX</p>
    </div> -->
  </div>
</template>

<script>
import Poster from './components/Poster'

export default {
  name: "Game",
  components: {
    Poster,
  },
};
</script>
<style lang="less" scoped>
.game {
  // padding-top: 200px;
  width: 100%;
  height: 100%;
  // padding-bottom: 20px;
  // padding-top: 50%;
  .top_img {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    .hongbao {
      // width: 120px;
      width: 30%;
      height: 100px;
    }
    .ad_1 {
      width: 70%;
      // padding-bottom: 15px;
    }
  }
  .tab {
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    button {
      border-radius: 30px;
      background: gold;
      color: sandybrown;
      width: 40%;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: bold;
      border: none;
    }
  }
  .des {
    color:gold;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    background: rgb(77, 32, 77);
    // margin-top: 20px;
    width: 90%;
    // height: 200px;
    margin: 20px  auto;
    border-radius: 10px;
    p {
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
    padding-left: 30px;
    font-size: 16px;
    color:#eee;
  }
  }
}
</style>
