<template>
  <div class="extend">
    <mt-header v-if="isPcOrMobile === 1" :title="$t('message.i42')" :fixed="true">
      <div slot="left">
        <i @click="$router.go(-1)" class="iconfont iconzuojiantou"></i>
      </div>
    </mt-header>
    <!--  <Notice>
      <div v-for="(item, l) in notice" :key="l">
        {{ item.link }}
      </div>
    </Notice> -->
    <div class="search">
      <mt-search v-model="searchItem" :cancel-text="$t('message.取消')" :placeholder="$t('message.搜索')"></mt-search>
      <div @click="openSearch" class="disableBox"></div>
    </div>
    <div v-if="searchDialog" class="searchDialogModal"></div>
    <div v-if="searchDialog" class="searchDialog">
      <mt-field :label="$t('message.联赛名')" :placeholder="$t('message.查询联赛名')" v-model="leagueName"></mt-field>
      <mt-field :label="$t('message.队名')" :placeholder="$t('message.查询队名')" v-model="teamName"></mt-field>
      <div class="btnBox">
        <mt-button @click.native="handleSearch" type="primary"> {{ $t('message.搜索') }} </mt-button>
        <mt-button @click.native="handleReset" type="primary"> {{ $t('message.i3') }} </mt-button>
        <mt-button @click.native="handleCancel" type="default"> {{ $t('message.取消') }} </mt-button>
      </div>
    </div>
    <div v-if="words && words.length !== 0" class="bodys">
      <p class="time">{{ dates }}</p>
      <div class="codes" v-for="(items, k) in words" :key="k + items">
        <p class="top">{{ items.League }}</p>
        <div class="code_list" v-for="(item, l) in items.lists" :key="l + item">
          <div class="left">
            <div class="ii">
              <p>{{ item.Home }}</p>
              <p>{{ item.HomeScore && item.IsRun === 'True' ? item.HomeScore : "-" }}</p>
            </div>
            <div class="ii">
              <p>{{ item.Away }}</p>
              <p>{{ item.AwayScore && item.IsRun === 'True' ? item.AwayScore : "-" }}</p>
            </div>
          </div>
          <div class="right">
            <p class="time2">{{ item.GameTime }}</p>
            <div @click="goto_play(item)" class="bb" v-if="item.IsRun === 'True'">
              <p class="red_point"></p>
              <p>{{ item.TVUrl ? $t('message.i41') : $t('message.i66') }}</p>
            </div>
            <div class="bb bb-2" v-else>
              <span>{{ $t('message.i44') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoData v-else></NoData>
  </div>
</template>
<script>
// import QRCode from "qrcodejs2";
import { mapGetters } from "vuex";
// import Notice from './components/Notice'

export default {
  name: "Extend",
  components: {
    // Notice
  },
  computed: {
    ...mapGetters({
      notice: "get_notice",
      video_lists: "video_lists",
      isPcOrMobile: "isPcOrMobile",
    }),
    lists() {
      let arr = [];
      if (this.video_lists && this.video_lists.length !== 0) {
        arr = this.dataDeal(this.video_lists)
      }
      return arr;
    },
    dates() {
      const date = new Date()
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    },
  },
  data() {
    return {
      teamName: '',
      leagueName: '',
      searchDialog: false,
      items: ['ronaldo show up', 'ronaldo show up empty handed to messi birthday.'],
      searchItem: '',
      words: []
    };
  },
  created() {},
  watch: {
    uniqueFunc(arr, uniId) {
      const res = new Map();
      return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
    },
    video_lists(val) {
      if (val && val.length !== 0) {
        this.words = this.dataDeal(val)
      } else {
        this.words = []
      }
    },
    searchItem(val) {
      if (val) {
        const newarr = this.words.filter(word => word.League.includes(val));
        this.words = newarr
      } else {
        this.words = this.lists
      }
    }
  },
  mounted() {
    this.words = this.lists
  },
  methods: {
    handleReset() {
      this.words = this.lists
      this.handleCancel()
    },
    handleCancel() {
      this.searchDialog = false
      this.leagueName = ''
      this.teamName = ''
    },
    handleSearch() {
      let newarr = []
      if (this.leagueName && !this.teamName) {
        newarr = this.words.filter(word => word.League.includes(this.leagueName));
      } else if (!this.leagueName && this.teamName) {
        this.words.forEach(item => {
          let newLists = []
          let hadLeagueName = ''
          item.lists.forEach(items => {
            if (items.Home.includes(this.teamName) || items.Away.includes(this.teamName)) {
              newLists.push(items)
              hadLeagueName = item.League
            }
          })
          if (hadLeagueName === item.League) {
            newarr.push({
              ...item,
              lists: newLists
            })
          }
        })
      } else if (this.leagueName && this.teamName) {
        const filterLeague = this.words.filter(word => word.League.includes(this.leagueName));
        filterLeague.forEach(item => {
          let newLists = []
          let hadLeagueName = ''
          item.lists.forEach(items => {
            if (items.Home.includes(this.teamName) || items.Away.includes(this.teamName)) {
              newLists.push(items)
              hadLeagueName = item.League
            }
          })
          if (hadLeagueName === item.League) {
            newarr.push({
              ...item,
              lists: newLists
            })
          }
        })
      } else {
        newarr = this.lists
      }
      this.words = newarr
      this.handleCancel()
    },
    openSearch() {
      this.searchDialog = true
    },
    async goto_play(item) {
      if (item.IsRun !== 'True') return
      const data = JSON.stringify(item);
      if (this.$route.name === "Play") {
        this.$router.replace({
          name: "Play",
          query: { params: data },
        });
      } else {
        this.$router.push({
          name: "Play",
          query: { params: data },
        });
      }
      const hadPhone = window.localStorage.getItem("hkhk22Phone")
      // if (!this.is_had_login) {
      //   this.$store.commit('CHANGElOGINSTATUS', true)
      //   this.$router.push({ name: 'register', query: { keyword: this.$route.query.keyword } })
      //   return
      // }
      const params = {
        league: item.title,
        socOddsId: item.SocOddsId,
        phone: hadPhone || 'somebody',
      }
      const { errNum } = await this.$http("total_looks", params);
      if (errNum === 1) {
        console.log('统计成功')
      }
    },
    dataDeal(data) {
      var listArr = [];
      data.forEach((el) => {
        for (var i = 0; i < listArr.length; i++) {
          if (listArr[i].League == el.League) {
            listArr[i].League = el.League;
            listArr[i].lists.push(el);
            return;
          }
        }
        // 第一次对比没有参照，放入参照
        listArr.push({
          League: el.League,
          lists: [el]
        })
      })
      return listArr
    }
  },
};
</script>
<style lang="less" scoped>
.extend {
  /deep/ .mint-header.is-fixed {
    top: 68px !important;
  }

  .searchDialogModal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 8;
    background-color: #000;
    opacity: 0.6;
  }

  .searchDialog {
    position: fixed;
    width: 300px;
    left: 0;
    right: 0;
    margin: 0 auto;
    /* top: 100px; */
    background-color: transparent;
    z-index: 9;

    .btnBox {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 20px;
    }
  }

  .search {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 40px;
    font-size: 14px;
    color: #BB822B;
    background-color: rgb(58, 59, 60, 1);
    width: 100%;
    height: 50px;
    position: relative;

    .disableBox {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
    }
  }

  .bodys {
    padding: 0 10px;

    .time {
      background-color: rgb(74, 75, 76);
      color: #fff;
      text-align: left;
      padding-left: 10px;
      font-size: 15px;
      padding: 10px 5px;
    }

    .codes {
      background-color: rgb(58, 59, 60);

      .top {
        background-color: rgb(183, 126, 59);
        color: #fff;
        text-align: left;
        padding-left: 10px;
        font-size: 14px;
        padding: 10px 5px;
      }

      .code_list {
        display: flex;
        justify-content: space-between;
        padding: 10px 5px;
        border-bottom: 1px solid rgb(36, 37, 38);

        .left {
          display: flex;
          flex-direction: column;
          color: #fff;
          width: 60%;
          font-size: 14px;
          justify-content: space-around;
          border-right: 1px solid rgb(36, 37, 38);

          .ii {
            display: flex;
            justify-content: space-between;

            p:last-child {
              margin-right: 10px;
            }
          }
        }

        .right {
          display: flex;
          width: 38%;
          flex-direction: column;
          color: #fff;

          .time2 {
            font-size: 12px;
            text-align: left;
            padding-bottom: 10px;
          }

          .bb {
            background-color: rgb(128, 90, 67);
            font-size: 14px;
            padding: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .red_point {
              width: 10px;
              height: 10px;
              border-radius: 100%;
              background-color: rgb(239, 68, 68);
              margin-right: 5px;
              -webkit-animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
              animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
            }

            @keyframes pulse {
              50% {
                opacity: .5;
              }
            }
          }

          .bb-2 {
            background-color: rgb(74, 75, 76) !important;
          }
        }
      }
    }
  }
}
</style>