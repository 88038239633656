<template>
    <div class="teach-detail">
        <mt-header :title="$route.query.type" :fixed="true">
            <div slot="left">
                <i @click="$router.go(-1)" class="iconfont iconzuojiantou"></i>
            </div>
        </mt-header>
        <div class="info">
            <div class="item" v-if="ngetTeachData">
                <h2>{{ngetTeachData.title}}</h2>
                <!-- <p>{{ngetTeachData.addtime}}</p> -->
                <!-- <p v-if="ngetTeachData.img">
                    <img style="max-width: 100%;" :src="ngetTeachData.img" alt="newsPic">
                </p> -->
                <div v-html="ngetTeachData.content"></div>
            </div>
        </div>
        <div class="link_box">
            <img @click="link_fb" src="@/assets/others/facebook_link.png">
            <img src="@/assets/others/copy.png" v-clipboard:copy="href" v-clipboard:success="clipboardSuccess">
        </div>
        <OtherPage :type="ngetTeachData ? ngetTeachData.type : ''" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OtherPage from '../OtherPage'

export default {
    data() {
        return {
            href: window.location.href,
            // newObj: {},
            ngetTeachData: '',
        };
    },
    components: {
        OtherPage
    },
    created() {
        // this.getData();
        this.init()
    },
    watch: {
        // getTeachData(newval) {
        //     if (newval.data.length !== 0) {
        //         this.ngetTeachData = this.agetTeachData();
        //     }
        // },
        '$route': 'init'
    },

    computed: {
        ...mapGetters({
            getTeachData: 'getTeachData',
            getCont: 'getCont',
        }),
    },
    methods: {
        setOg(m) {
            // 获取所有的 meta 标签
            var metaTags = document.getElementsByTagName('meta');

            // 遍历 meta 标签，查找 property 属性为 "og:title" 的 meta 元素
            for (var i = 0; i < metaTags.length; i++) {
                var metaTag = metaTags[i];
                var property = metaTag.getAttribute('property');

                // 判断 property 属性是否为 "og:title"
                if (property === 'og:title') {
                    // 修改 content 属性的内容
                    metaTag.setAttribute('content', m.title);
                }
                if (property === 'og:description') {
                    // 修改 content 属性的内容
                    metaTag.setAttribute('content', m.textContent);
                }
                if (property === 'og:image') {
                    // 修改 content 属性的内容
                    metaTag.setAttribute('content', m.img);
                }
                if (property === 'og:url') {
                    // 修改 content 属性的内容
                    // metaTag.setAttribute('content', `https://starsport.tv/#/Content/${this.routeName}?infoId=${m.id}&type=${m.type}&title=${m.title}`);
                    metaTag.setAttribute('content', location.href);
                    break; // 找到并修改后可以直接退出循环，不再继续查找
                }
            }

        },
        link_fb() {
            window.open(`https://www.facebook.com/sharer.php?u=${this.href}&t=${document.title}`)
        },
        clipboardSuccess() {
            this.$Toast(this.$t('复制成功'))
        },
        init() {
            this.getData()
            this.getAllData()
        },
        async getAllData(ee) {
            const locale = this.$i18n.locale
            if (locale === 'hk') {
                this.$store.commit('SETCONT', 'TraditionalChinese')
            } else if (locale === 'tai') {
                this.$store.commit('SETCONT', 'Thailand')
            } else if (locale === 'en') {
                this.$store.commit('SETCONT', 'English')
            }

            const param = {
                siteName: "afb",
                page: 1,
                limit: 20,
                cont: this.getCont,
                type: this.$route.query.type || ''
            }
            const { code, data } = await this.$http('getSgTeach', param);
            // console.log(data)
            if (code === 20000 && data) {
                const newsData = []
                if (data && data.length !== 0) {
                    data.forEach(item => {
                        // if (window.myConfigs.isNotApp) {
                        const be = window.location.protocol ? window.location.protocol : 'http:'
                        item.content = item.content.replace(/http:/g, be)
                        // }
                        newsData.push(item)
                    })
                } else {
                    // console.log(123123)
                    if (!ee) {
                        this.$store.commit('RESETTEACH')
                    }
                    return
                }
                const backdata = {
                    allPage: data.allPage,
                    allCount: data.allCount,
                    data: newsData
                }
                this.$store.commit('TEACH', {
                    a: param.page,
                    b: backdata,
                    name: param.pagename
                })
            } else {
                if (!ee) {
                    this.$store.commit('RESETTEACH')
                }
            }
        },
        async getData() {
            const param = {
                id: this.$route.query.infoId || ''
            }
            const { code, data } = await this.$http('getTeachDetail', param);
            // console.log(code)
            // console.log(data)
            let teachData = []
            if (code === 20000 && data) {
                if (data && data.length !== 0) {
                    data.forEach(item => {
                        const be = window.location.protocol ? window.location.protocol : 'http:'
                        item.content = item.content.replace(/http:/g, be)
                        teachData.push(item)
                    })
                }
            }
            if (teachData.length !== 0) {
                this.ngetTeachData = teachData[0]
                
                const imgReg = /<img.*?(?:>|\/>)/gi
                const item = this.ngetNewsData
                const imgStr = item.content
                let imgHtml = ''
                if ((imgStr.match(imgReg)) === null) {
                    imgHtml = ''
                } else {
                    imgHtml = (imgStr.match(imgReg))[0]
                }
                // eslint-disable-next-line
                const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
                item.img = imgHtml ? imgHtml.match(srcReg)[1] : ''
                item.contents = imgStr.replace(imgHtml, '')
                const tempElement = document.createElement('div');
                tempElement.innerHTML = item.contents;
                const extractedText = tempElement.textContent;
                item.textContent = extractedText
                this.setOg(this.ngetNewsData)
            } else {
                this.ngetTeachData = ''
            }
        },
        click_back() {
            this.$store.commit('SET_TAB_INDEX', null)
            this.$router.push({ name: 'Teach' })
        },
        goBack() {
            console.log('goback')
            this.$router.go(-1)
        },
        // agetTeachData() {
        //     const that = this;
        //     const list = that.getTeachData.data;
        //     list.forEach((ele) => {
        //         if (ele.id == that.$route.query.infoId) {
        //             that.newObj = ele;
        //         }
        //     });
        //     return that.newObj;
        // }
    },
};
</script>
<style lang="less" scoped>
.teach-detail {
    /deep/ .mint-header.is-fixed {
        top: 68px !important;
    }

    .top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
    }

    .link_box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 70%;
        margin: 0 auto;
        margin-top: 30px;

        img {
            width: 30px;
            margin-right: 50px;
            cursor: pointer;
        }
    }

    /* 手机 */
    @media all and (max-width: 768px) {
        .link_box {
            width: 90% !important;
        }

        .info {
            width: 90% !important;
        }
    }

    .info {
        // background-color: #181818;
        padding: 68px 20px 20px 20px;
        color: #FFC107;
        width: 70%;
        margin: 0 auto;
        border: 1px solid;
        border-radius: 10px;
        border-color: rgba(64, 54, 0, 0.75);

        .item {
            word-break: break-word;
            padding: 10px 0;
            // text-align: left;

            h2 {
                font-size: 25px;
                margin-bottom: 5px;
            }

            p {
                margin-bottom: 5px;
            }
        }
    }
}
</style>