<template>
  <div class="TypeList">
    <mt-header
      :title="first_classify || second_classifys.split(',')[0]"
      :fixed="true"
    >
      <div slot="left">
        <i @click="$router.go(-1)" class="iconfont iconzuojiantou"></i>
      </div>
      <!-- <div slot="right">清空</div> -->
    </mt-header>
    <!-- <mt-navbar v-model="selected" v-if="first_classify && second_classify">
      <mt-tab-item
        :id="l"
        v-for="(item, l) in second_classifys.split(',')"
        :key="l"
        @click.native="get_lists('log_top')"
        >{{ item }}</mt-tab-item
      >
    </mt-navbar> -->
    <div class="navbar" v-if="first_classify && second_classify">
      <p
        :id="l"
        :class="[selected === l ? 'active' : '', 'item']"
        v-for="(item, l) in second_classifys.split(',')"
        :key="l"
        @click="get_lists('log_top', l)"
      >
        {{ item }}
      </p>
    </div>
    <div class="lists" v-if="lists && lists.length !== 0">
      <LoadMore
        :bottomStatus="bottomStatus"
        :allLoaded="allLoaded"
        @loadTop="loadTop"
        @loadBottom="loadBottom"
        @handleBottomChange="handleBottomChange"
        ref="gloadmore"
      >
        <VdListItem v-for="(n, i) in lists" :key="i + '1'" :items="n" />
      </LoadMore>
    </div>
    <NoData v-else />
  </div>
</template>

<script>
import mixin from "@/mixin/list";
export default {
  name: "TypeList",
  mixins: [mixin],
  data() {
    return {
      lists: [],
      first_classify: "",
      second_classifys: "",
      selected: 0,
    };
  },
  computed: {
    second_classify() {
      const data = this.second_classifys.split(",");
      return data[this.selected];
    },
  },
  created() {
    const params = this.$route.query.params
      ? JSON.parse(this.$route.query.params)
      : "";
    if (params) {
      this.first_classify = params.first_classify;
      this.second_classifys = params.second_classifys;
    }
    this.get_lists("log_top", 0);
  },
  methods: {
    async get_lists(type, index) {
      this.selected = index
      if (type === "log_top") {
        this.lists = [];
      }
      const params = {
        first_classify: this.first_classify || "",
        second_classify: this.second_classify || "",
        page: this.page || 1,
      };
      const { errNum, data } = await this.$http("getLists", params);
      if (errNum === 1 && data) {
        data.forEach((element) => {
          this.lists.push(element);
        });
        if (data.length === 0) {
          this.page = this.page > 1 ? this.page - 1 : 1;
          this.allLoaded = true; // 若数据已全部获取完毕
        } else {
          this.allLoaded = false;
        }
      } else {
        this.lists = [];
        this.$Toast("获取列表失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.TypeList {
  width: 100%;
  padding-top: 40px;
  height: calc(100% - 20px);
 overflow-y: auto;
-webkit-overflow-scrolling: touch;
  .navbar {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    .item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      padding: 5px 10px;
      width: 80px;
      flex: none;
    }
    .active {
      border-bottom: 2px solid #fbe802 !important;
      color: #fbe802 !important;
    }
  }
  .lists {
    // position: absolute;
    // width: 100%;
    // /* height: 100%; */
    // top: 40px;
    // bottom: 0;
  }
  .page-part {
    font-size: 12px;
  }
}
</style>
