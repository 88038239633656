<template>
  <div class="Gift">
  <NoData/>
  <button @click="goto_home">返回主页</button>
  </div>
</template>

<script>
export default {
  name: "Gift",
  components: {},
  methods: {
    goto_home() {
      this.$router.go(-1)
    },
  },
};

</script>

<style lang="less" scoped>
 button {
   width: 200px;
   height: 50px;
   border-radius: 30px;
   margin-top:100px ;
   background:#FFC107;
   border:none;
   font-size:14px;
   color:black;
 }
</style>
