import {http} from './http'
import API from './api'
import qs from 'qs'
import {isIEBrowser} from '@/utiles'

/**
 * 参数说明：
 *  @params {String} apiName:apiName

*/

const $http = (apiName, data = {}, config = {}) => {
  let requestConfig = API[apiName](data)
  let {method, url, params} = requestConfig
  let requestFunction = null

  switch (method) {
    case 'post':
      requestFunction = http.post(url, params, config)
      break
    case 'get':
      /**
           * 处理get请求参数为数组的情况
          */
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
      if (Object.keys(params).indexOf('timestamp') < 0) {
        if (isIEBrowser()) {
          params = {...params, timestamp: Date.now()}
        }
      }
      requestFunction = http.get(url, {params, ...config})
      break
    case 'upload':
      requestFunction = http.post(url, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      break
  }

  return requestFunction
}

export {http, $http}
