<template>
  <div class="Recommend" v-if="lists && lists.length !== 0">
    <div class="title">
      <p>{{ $t('message.i37') }}</p>
    </div>
    <div class="box">
      <div class="items" @click="goto_play(item)" v-for="(item, k) in lists" :key="k + item">
        <div class="live" v-if="item.IsRun === 'True'">
          {{ item.TVUrl ? $t('message.i41') : $t('message.i66') }}
        </div>
        <div class="top">
          <p class="left">
            <img :src="require('@/assets/fire-icon.png')" alt="fire-icon" />
            <span>{{ item.looknum }}</span>
          </p>
          <p class="right" v-if="item.GameTime">{{ item.GameTime }}</p>
        </div>
        <div class="team">
          <p>
            {{
              (item.Home.length > (isMobile() ? 15 : 25)
                ? item.Home.substr(0, isMobile() ? 15 : 25) + "..."
                : item.Home) || "-"
            }}
          </p>
          <p>
            {{
              (item.Away.length > (isMobile() ? 15 : 25)
                ? item.Away.substr(0, isMobile() ? 15 : 25) + "..."
                : item.Away) || "-"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  props: {
    // lists: {
    //   type: Array,
    //   default: () => []
    // }
  },
  computed: {
    ...mapGetters({
      video_lists: "video_lists",
    }),
    lists() {
      let arr = [];
      if (this.video_lists && this.video_lists.length !== 0) {
        arr = this.video_lists.filter((item) => Number(item.looknum) >= 10);
      }
      return arr;
    },
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    async goto_play(item) {
      if (item.IsRun !== 'True') return
      const data = JSON.stringify(item);
      if (this.$route.name === "Play") {
        this.$router.replace({
          name: "Play",
          query: { params: data },
        });
      } else {
        this.$router.push({
          name: "Play",
          query: { params: data },
        });
      }
      const hadPhone = window.localStorage.getItem("hkhk22Phone")
      // if (!this.is_had_login) {
      //   this.$store.commit('CHANGElOGINSTATUS', true)
      //   this.$router.push({ name: 'register', query: { keyword: this.$route.query.keyword } })
      //   return
      // }
      const params = {
        league: item.title,
        socOddsId: item.SocOddsId,
        phone: hadPhone || 'somebody',
      }
      const { errNum } = await this.$http("total_looks", params);
      if (errNum === 1) {
        console.log('统计成功')
      }
    },
  },
};
</script>
<style lang="less" scoped>
.Recommend {
  display: flex;
  flex-direction: column;
  // align-items: center;
  align-items: flex-start;
  // justify-content: space-around;
  padding: 15px 10px;
  .title {
    margin-bottom: 10px;
    // width: 100%;
    height: 28px;
    text-align: left;
    p {
      color: #ffd602;
      // border-bottom: 3px solid #fbe802;
    }
  }
  .box {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    width: 100%;
    /* 手机 */
    @media all and (max-width: 768px) {
      .items {
        width: 130px !important;
        .top {
          .left {
            span {
              font-size: 10px !important;
            }
          }
          .right {
            font-size: 10px !important;
          }
        }
        .team {
          font-size: 13px !important;
        }
      }
    }
    .items {
      display: flex;
      flex-direction: column;
      width: 200px;
      height: 120px;
      padding: 10px;
      border-radius: 10px;
      background-color: rgba(36, 37, 38, 1);
      margin-right: 10px;
      position: relative;
      .live {
        background-color: #dd0404;
        color: #fff;
        position: absolute;
        left: 10px;
        top: 30px;
        border-radius: 5px;
        padding: 3px;
        font-size: 10px;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(167, 170, 174, 1);
        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 20px;
          }
          span {
            font-size: 13px;
          }
        }
        .right {
          font-size: 13px;
        }
      }
      .team {
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #fff;
        font-size: 15px;
        p {
          margin-bottom: 10px;
        }
      }
    }
    .item {
      flex-shrink: 0;
      width: 150px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 100px;
        border-radius: 10px;
      }
      span {
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
