<template>
  <div class="Explore">
    <mt-header title="探索发现" :fixed="true">
      <i
        @click="$router.go(-1)"
        slot="left"
        class="iconfont iconzuojiantou"
      ></i>
    </mt-header>
    <mt-navbar v-model="selected">
      <mt-tab-item id="1" @click.native="change(1)">热点</mt-tab-item>
      <mt-tab-item id="2" @click.native="change(2)">推荐</mt-tab-item>
      <mt-tab-item id="3" @click.native="change(3)">最新</mt-tab-item>
    </mt-navbar>
    <div v-if="lists && lists.length !== 0">
      <!-- <mt-loadmore
        :auto-fill="false"
        :top-method="loadTop1"
        :bottom-method="loadBottom1"
        :bottom-all-loaded="allLoaded1"
        ref="loadmore1"
      > -->
      <LoadMore
        :bottomStatus="bottomStatus"
        :allLoaded="allLoaded"
        @loadTop="loadTop"
        @loadBottom="loadBottom"
        @handleBottomChange="handleBottomChange"
        ref="gloadmore"
      >
        <VdListItem v-for="(n, i) in lists" :key="i + '1'" :items="n" />
      <!-- </mt-loadmore> -->
      </LoadMore>
    </div>
    <NoData v-else />
  </div>
</template>

<script>
import mixin from '@/mixin/list'
export default {
  name: "Explore",
  mixins: [mixin],
  components: {},
  data() {
    return {
      selected: "1",
      lists: [],
      now_id: 1,
      user_info: window.sessionStorage.getItem('user_info') ? JSON.parse(window.sessionStorage.getItem('user_info')) : '',
    };
  },
  created() {
    this.get_lists('log_top');
  },
  methods: {
    change(id) {
      this.now_id = id
      this.lists = []
      this.page = 1
      this.get_lists('log_top')
    },
    async get_lists(type) {
      if (type === 'log_top') {
        this.lists = []
      }
      const params = {
        type: this.now_id,
        page: this.page || 1,
        uid: this.user_info ? this.user_info.id : ''
      };
      const { errNum, data } = await this.$http("getExport", params);
      if (errNum === 1 && data) {
        data.forEach((element) => {
          this.lists.push(element);
        });
        if (data.length === 0) {
          this.page = this.page > 1 ? this.page - 1 : 1
          this.allLoaded = true; // 若数据已全部获取完毕
        } else {
          this.allLoaded = false
        }
      } else {
        this.lists = [];
        this.$Toast("获取列表失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Explore {
  width: 100%;
  padding-top: 40px;
  height: calc(100% - 20px);
 overflow-y: auto;
-webkit-overflow-scrolling: touch;
}
</style>
