const state = {
    newsLoadState: true,
    newsIdList: [],
    newsData: [],
    newsPage: 1,
    cont: 'TraditionalChinese'
}
const getters = {
    getNewsData: state => state.newsData,
    getNewsPage: state => state.newsPage,
    getNewsLoadState: state => state.newsLoadState,
    getNewsIdList: state => state.newsIdList,
    getCont: state => state.cont,
}
const actions = {
    getNewsData: (({
        commit
    }, param) => {
        const _this = param._this
        _this.$http("getSgNews", param)
            .then((res) => {
                if (res.errNum == 1) {
                    const newsData = []
                    if (res.data && res.data.length !== 0) {
                        res.data.forEach(item => {
                            if (window.myConfigs.isNotApp) {
                                const be = window.location.protocol ? window.location.protocol : 'http:'
                                item.content = item.content.replace(/http:/g, be)
                            }
                            newsData.push(item)
                        })
                    }
                    const backdata = {
                        allPage: res.allPage,
                        allCount: res.allCount,
                        data: newsData
                    }
                    if (typeof param.c !== 'undefined') {
                        if (res.data.length === 0) {
                            param.c(true);
                            return
                        }
                        param.c()
                    }
                    commit('NEWS', {
                        a: param.page,
                        b: backdata,
                        name: param.pagename
                    })
                }
            })
    }),
}
const mutations = {
    SETCONT: (state, param) => {
        state.cont = param
    },
    UPDATENEWSLOADSTATE: (state, param) => {
        state.set_user_info = param
    },
    UPDATENEWSPAGE: (state, param) => {
        state.set_user_info = param
    },
    RESETNEWS: (state) => {
        state.newsData.data = []
    },
    NEWS: (state, param) => {
        let ddd = [];
        if (param.a > 1 && typeof param.name === 'undefined') {
            const aaa = state.newsData.data
            ddd = param.b.data
            ddd.forEach((item) => {
                item.page = param.a
            });
            param.b.data = ddd

            state.newsData.data = aaa.concat(param.b.data)
        } else {
            ddd = param.b.data
            ddd.forEach((item) => {
                item.page = param.a
            })
            param.b.data = ddd
            state.newsData = param.b
        }
        const imgReg = /<img.*?(?:>|\/>)/gi
        for (let i = 0; i < param.b.data.length; i++) {
            const item = param.b.data[i]
            const imgStr = item.content
            let imgHtml = ''
            if ((imgStr.match(imgReg)) === null) {
                imgHtml = ''
            } else {
                imgHtml = (imgStr.match(imgReg))[0]
            }
            // eslint-disable-next-line
            const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
            item.img = imgHtml ? imgHtml.match(srcReg)[1] : ''
            item.contents = imgStr.replace(imgHtml, '')
            const tempElement = document.createElement('div');
            tempElement.innerHTML = item.contents;
            const extractedText = tempElement.textContent;
            item.textContent = extractedText
            state.newsIdList.push(item.id)
        }
    },
}


export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}