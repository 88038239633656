/* eslint-disable no-unreachable */
<template>
  <div class="test-box">
    <!-- <SvgIcon iconClass="add-circle"></SvgIcon> -->
    <!-- <SvgIcon iconClass="爱心"></SvgIcon> -->
    <!-- <el-button type="primary" @click="clickhandle">lick me</el-button> -->
    <!-- <ServicePlan></ServicePlan>  -->
    <el-button type="primary" @click="clickhandle(0)">随机加2个</el-button>
    <el-button type="primary" @click="clickhandle(1)">加一个大</el-button>
    <el-button type="primary" @click="clickhandle(2)">加一个小</el-button>
    <!-- <DewdroMap ref="dewdrop_map" :back_data="auto_list" :nav_top="nav_top" /> -->
  </div>
</template>

<script>
// import DewdroMap from "./DewdroMap.vue";
export default {
  name: "Test",
  components: {
    // DewdroMap,
  },
  data() {
    return {
      nav_top: [
        { name: "第一名" },
        // {name:'第二名'},
        // {name:'第三名'},
        // {name:'第四名'},
        // {name:'第五名'},
        // {name:'第六名'},
        // {name:'第七名'},
        // {name:'第八名'},
        // {name:'第九名'},
        // {name:'第十名'},
      ],
      auto_list: [
        // { period: '202001' ,ball: '2,4,5,6,9,1,0,11,23,21' },
        { period: "202001", ball: "2" },
        { period: "202001", ball: "6" },
        { period: "202001", ball: "6" },
        { period: "202001", ball: "6" },
        { period: "202001", ball: "1" },
        { period: "202001", ball: "7" },
        { period: "202001", ball: "8" },
        { period: "202001", ball: "8" },
        { period: "202001", ball: "8" },
        { period: "202001", ball: "8" },
        { period: "202001", ball: "8" },
        { period: "202001", ball: "8" },
        { period: "202001", ball: "8" },
      ],
    };
  },
  methods: {
    //  async getData(){
    //    const {code,data} = await this.$http('goLogin')
    //    if(code === 200){
    //      console.log('data:',data)
    //    }
    //   },
    clickhandle(param) {
      if (param === 0) {
        this.auto_list.push({
          period: "202001",
          ball: String(this.randomNum(0, 10)),
        });
        this.auto_list.push({
          period: "202001",
          ball: String(this.randomNum(0, 10)),
        });
      } else if (param === 1) {
        this.auto_list.push({
          period: "202001",
          ball: String(this.randomNum(6, 10)),
        });
      } else if (param === 2) {
        this.auto_list.push({
          period: "202001",
          ball: String(this.randomNum(0, 5)),
        });
      }
    },
    //生成从minNum到maxNum的随机数
    randomNum(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
            return parseInt(Math.random() * minNum + 1, 10);
          // eslint-disable-next-line no-unreachable
          break;
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          // eslint-disable-next-line no-unreachable
          break;
        default:
          return 0;
          // eslint-disable-next-line no-unreachable
          break;
      }
    },
  },
  mounted() {
    this.$refs.dewdrop_map.top_go(0); //点击触发露珠图组件头部选中事件
    this.$refs.dewdrop_map.left_go(0); //点击触发露珠图组件左侧选中事件
  },
};
</script>
<style lang="less" scoped>
.test-box {
  // width: 100vw;
  // height: 100vh;
  // border: 1px solid red;
  .text {
    // width: 500px;
    // height: 100px;
  }
}
</style>
