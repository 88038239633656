export default {
    /**
     * 登陆
     */
    goLogin: params => {
        return {
            url: '/users/login',
            method: 'post',
            params
        }
    },
    // 注册
    setUser: params => {
        return {
            url: '/users/setUser',
            method: 'post',
            params
        }
    },
    // 修改
    updateUser: params => {
        return {
            url: '/users/updateUser',
            method: 'post',
            params
        }
    },
    // 获取用户信息
    getUsers: params => {
        return {
            url: '/users',
            method: 'post',
            params
        }
    },
}
