<template>
  <div class="page-box">
    <div class="box-list">
      <ul style="width: 10%;">
        <li ref="dateLi"
            v-for="(item, key) in periodList"
            :key="key"
            :timeId="item.heightId">
          <div class="u-txt-span">{{item.time}}</div>
        </li>
      </ul>
      <ul class="border-box-ul">
        <li ref="dateLi"
            v-for="(item, key) in periodList.length - 1"
            :key="key"
            :class="{'u-border-top': key === 0, 'u-border': true}"
            :timeId="item.heightId">
        </li>
      </ul>
      <div class="bg-content"
           ref="container">
      </div>
    </div>
    <!-- 日历 -->
    <div class="box-calendar">
      <calendar-Component :isMonth="isMonth"
                          :dateType="dateType"
                          :planList="planList"
                          :dateObj="dateObj"></calendar-Component>
    </div>
  </div>
</template>

<script>
import calendar from './calendar'
export default {
  name: 'dayCalendar',
  components: {
    'calendar-Component': calendar,
  },
  props: {
    isMonth: {
      type: Boolean,
      default: false
    },
    dateObj: {
      type: Object
    },
    dateType: {
      type: String
    },
    planList: {
      type: Array
    }
  },
  data () {
    return {
      periodList: [{
        time: '00:00',
        heightId: '0',
      }, {
        time: '01:00',
        heightId: '01'
      }, {
        time: '02:00',
        heightId: '02'
      }, {
        time: '03:00',
        heightId: '03'
      }, {
        time: '04:00',
        heightId: '04'
      }, {
        time: '05:00',
        heightId: '05'
      }, {
        time: '06:00',
        heightId: '06'
      }, {
        time: '07:00',
        heightId: '07'
      }, {
        time: '08:00',
        heightId: '08'
      }, {
        time: '09:00',
        heightId: '09'
      }, {
        time: '10:00',
        heightId: '10'
      }, {
        time: '11:00',
        heightId: '11'
      }, {
        time: '12:00',
        heightId: '12'
      }, {
        time: '13:00',
        heightId: '13'
      }, {
        time: '14:00',
        heightId: '14'
      }, {
        time: '15:00',
        heightId: '15'
      }, {
        time: '16:00',
        heightId: '16'
      }, {
        time: '17:00',
        heightId: '17'
      }, {
        time: '18:00',
        heightId: '18'
      }, {
        time: '19:00',
        heightId: '19'
      }, {
        time: '20:00',
        heightId: '20'
      }, {
        time: '21:00',
        heightId: '21'
      }, {
        time: '22:00',
        heightId: '22'
      }, {
        time: '23:00',
        heightId: '23'
      }, {
        time: '00:00',
        heightId: '24'
      }
      ]
    }
  },
  watch: {
    'dateObj.days': {
      handler () {
        this.getData()
      }
    }
  },
  mounted () {
    this.initPage()
  },
  methods: {
    initPage () {
      this.getData()
    },
    filterDate (val) {
      return val < 10 ? `0${val}` : val
    },
    getData () {
    //   const param = {
    //     uuid: this.$route.query.serveId,
    //     time: `${this.dateObj.year}-${this.filterDate(this.dateObj.month)}-${this.filterDate(this.dateObj.days)}`
    //   }
    //   this.$https('planOneDay', param).then(({ code, data }) => {
    //     if (code === '0') {
    //       const newList = this.removeRepeat(data.rows)
    //       const arry = this.unique(newList)
    //       this.initData(arry)
    //     }
    //   }).catch(msg => {
    //     console.log(msg)
    //   })
const data = {"total":1,"rows":[{"now":"2021-01-25","busyStartTime":"00:00","busyStartTime1":"00:00","busyEndTime":"24:00","busyEndTime1":"24:00","startTime":"00:00","endTime":"24:00"}]} ;
       const newList = this.removeRepeat(data.rows);
      const arry = this.unique(newList);
      this.initData(arry);
    },
    initData (arry = []) {
      let nextHeight = 0
      let html = ''
      let countHeight = 0 // 总高度
      let end = null
      let start = null
      // 清空容器，重新绘制
      this.$refs.container.innerHTML = ''
      arry.map((item) => {
        html = ''
        start = item.startTime.split(':')
        end = item.endTime.split(':')
        const startTime = start[0] // 起始时间(时)
        const endTime = end[0] // 结束时间(时)
        const startMinute = start[1] // 起始时间(分)
        const endMinute = end[1] // 结束时间(分)
        let startHeight = 0
        let endHeight = 0
        // 如果起始分钟大于0
        if (startMinute > 0) {
          startHeight = (startTime * 4) + (startMinute / 60 * 4)
        } else {
          startHeight = startTime * 4
        }
        // 如果结束分钟大于0 结束时间计算有问题
        if (endMinute > 0) {
          // TODO 为什么带分钟的结束时间一定要 + 4
          endHeight = ((endTime * 4 + 4) - startHeight) - ((60 - endMinute) / 60 * 4)
        } else {
          endHeight = (endTime * 4) - startHeight
        }
        // 如果当前起始时间 - 上一次的结束时间 大于1 默认白底占位
        if (startTime - nextHeight >= 0) {
          html += `<div style="height: ${startHeight - countHeight}%; opacity: 0.5; background-color: ${this.listColor()};"></div>`
        }
        // 判断上一次的结束时间如果大于当前的开始时间，就不做添加节点处理
        html += `<div style="height: ${endHeight}%; opacity: 0.8; background-color: #E02020;"></div>`
        // 总高度      当前起始高度    当前结束高度
        countHeight = startHeight + endHeight
        nextHeight = endTime
        const txt = document.createRange().createContextualFragment(html)
        this.$refs.container.appendChild(txt)
      })
      if (countHeight < 95) {
        html = `<div style="height: ${96 - countHeight}%; opacity: 0.5; background-color: ${this.listColor()};"></div>`
        const txt = document.createRange().createContextualFragment(html)
        this.$refs.container.appendChild(txt)
      }
    },
    // 数据区重复
    unique (arr) {
      const arry = []
      for (const item of arr) {
        if (arry.find(c => c.startTime === item.startTime && c.endTime === item.endTime)) {
          continue
        }
        arry.push(item)
      }
      return arry
    },
    // 过滤重复时间，时间数据重组
    removeRepeat (list = []) {
      // 数据长度大于1条才做重新组装处理
      if (list.length > 1) {
        // 排序
        list.sort((a, b) => parseInt(a.startTime, 10) - parseInt(b.startTime, 10))
        let nextEndTime = 0
        const newArry = []
        let index = 0
        for (const item of list) {
          const startTime = item.startTime.replace(/:/, '')
          const endTime = item.endTime.replace(/:/, '')
          if (parseInt(startTime, 10) === 0 && parseInt(endTime, 10) === 2400) {
            newArry.push(item)
            return newArry
          } else if (nextEndTime > startTime && nextEndTime < endTime) {
            newArry[index - 1].endTime = item.endTime
          } else {
            index++
            newArry.push(item)
          }
          nextEndTime = endTime
        }
        return newArry
      } else {
        return list
      }
    },
    // 列表数据颜色渲染
    listColor () {
      const weekday = new Date(this.dateObj.year, this.dateObj.month - 1, this.dateObj.days).getDay()
      return weekday === 6 || weekday === 0 ? '#8c8c8c' : '#00B532'
    }
  }
}
</script>

<style>
.page-box {
  display: flex;
  width: 100%;
}
.box-list {
  width: 70%;
  display: flex;
  position: relative;
}
.box-calendar {
  width: 30%;
  margin-left: 1.9%;
}
.bg-content {
  width: 90%;
  height: 100%;
  position: absolute;
  top: 2%;
  right: 0;
  background-color: #ffffff;
  opacity: 0.4;
}
.u-txt-span {
  width: 100%;
  padding: 4px 0;
  text-align: center;
  font-family: 'PingFangSC-Regular';
  font-size: 14px;
  color: #171717;
  /* background-color: #f3f3f3; */
}
.u-border-top {
  border-top: 1px solid #dcdcdc;
}
.u-border {
  width: 100%;
  height: 4%;
  background-color: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.border-box-ul {
  width: 90%;
  position: relative;
  top: 2%;
}
/* 已排计划 */
.time-bg-yp {
  background: rgba(224, 32, 32, 0.2);
}
/* 空闲 */
.time-bg-kx {
  background: rgba(0, 181, 50, 0.2);
}
</style>
