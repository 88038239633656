import Vue from 'vue'
import VueI18n from 'vue-i18n'
import hk from './langs/hk'
import tai from './langs/tai'
import en from './langs/en'
Vue.use(VueI18n)

const messages = {
  hk: hk,
  tai: tai,
  en: en,
}

const i18n = new VueI18n({
  locale: localStorage.getItem('i18lang') || 'hk',
  messages
})

export default i18n
