<template>
  <div class="login">
    <p v-show="type === 'login'" class="close">
      <i @click="back" class="iconfont iconguanbi"></i>
    </p>
    <h2 class="a1" v-show="type === 'login'">{{ $t('message.i1') }}</h2>
    <h2 class="a1" v-show="type === 'register'">{{ $t('message.i2') }}</h2>
    <h2 class="a1" v-show="type === 'forgove'">{{ $t('message.i3') }}</h2>
    <p class="a1 a2" v-show="type === 'login'">{{ $t('message.i4', [title]) }}</p>
    <p class="a1 a2" v-show="type === 'register'">{{ $t('message.i5', [title]) }}</p>
    <p class="a1 a2" v-show="type === 'forgove'">{{ $t('message.i6', [title]) }}</p>
    <div class="form">
      <mt-actionsheet
        :actions="actions"
        v-model="sheetVisible">
      </mt-actionsheet>
      <mt-field
        :state="phone_state ? 'success' : 'warning'"
        :attr="{ maxlength: 15 }"
        :label="$t('message.i11')"
        disableClear
        :placeholder="$t('message.i12')"
        type="tel"
        v-model="phone"
      >
        <span @click="sheetVisible = true">
          <i v-show="cot === '+852'">🇭🇰</i>
          <i v-show="cot === '+66'">🇹🇭</i>
          <i v-show="cot === '+84'">🇻🇳</i>
          <i v-show="cot === '+855'">🇰🇭</i>
          <i v-show="cot === '+65'">🇸🇬</i>
          {{ cot }}
          ↕️
        </span>
      </mt-field>
      <mt-field
        v-if="type === 'forgove' || type === 'register'"
        :state="captcha_state ? 'success' : 'warning'"
        :label="$t('message.i15')"
        disableClear
        v-model="captcha"
      >
        <span height="45px" width="100px" @click="get_code()">{{ num ? `${num} s` : $t('message.i16') }}</span>
      </mt-field>
      <mt-field
        :state="password_state ? 'success' : 'warning'"
        :label="$t('message.i17')"
        disableClear
        :placeholder="$t('message.i18')"
        type="password"
        v-model="password"
      ></mt-field>
      <mt-field
        v-if="type === 'register'"
        :state="password2_state ? 'success' : 'warning'"
        :label="$t('message.i19')"
        disableClear
        :placeholder="$t('message.i20')"
        type="password"
        v-model="password2"
      ></mt-field>
    </div>
    <div class="booo">
      <div v-if="type === 'login'">
        <mt-button @click.native="login" type="primary" size="large"
          >{{ $t('message.i21') }}</mt-button
        >
        <p class="ii" @click="goto_page('register')">{{ $t('message.i22') }}</p>
      </div>
      <div v-else-if="type === 'register'">
        <mt-button @click.native="register" type="primary" size="large"
          >{{ $t('message.i23') }}</mt-button
        >
        <p class="ii" @click="goto_page('login')">{{ $t('message.i24') }}</p>
      </div>
      <div v-else-if="type === 'forgove'">
        <mt-button @click.native="forgove" type="primary" size="large"
          >{{ $t('message.i25') }}</mt-button
        >
        <p class="ii" @click="goto_page('login')">{{ $t('message.i26') }}</p>
      </div>
    </div>
    <!-- <p class="bottom" v-show="type === 'login'" @click="goto_page('forgove')">
      忘记密码？
    </p> -->
  </div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { phoneModel } from '@/utiles'
// import { mapActions } from 'vuex'

export default {
  name: "Login",
  components: {},
  data() {
    return {
      title: window.config.title,
      type: "login",
      phone: "",
      password: "",
      password2: "",
      captcha: "",
      username: '',
      surname: '',
      name: '',
      name_state: false,
      surname_state: false,
      username_state: false,
      phone_state: false,
      captcha_state: false,
      password_state: false,
      password2_state: false,
      back_captcha: '',
      num: 0,
      sheetVisible: false,
      cot: '+852',
      actions: [
        {
          name: '🇭🇰香港+852',
          method: this.select,
          key: '+852'
        },
        // {
        //   name: '🇹🇭泰国+66',
        //   method: this.select,
        //   key: '+66'
        // },
        // {
        //   name: '🇻🇳越南+84',
        //   method: this.select,
        //   key: '+84'
        // },
        // {
        //   name: '🇰🇭柬埔寨+855',
        //   method: this.select,
        //   key: '+855'
        // },
        // {
        //   name: '🇸🇬新加坡+65',
        //   method: this.select,
        //   key: '+65'
        // },
      ]
    };
  },
  created() {
    // window.sessionStorage.clear()
    this.type = this.$route.query.type
    this.num = Number(localStorage.getItem('code_num')) || 0
    if (this.num) {
      this.set_num()
    }
    this.set_cot()
  },
  watch: {
    surname(new_val) {
      if (!new_val) return;
      this.surname_state = true;
    },
    name(new_val) {
      if (!new_val) return;
      this.name_state = true;
    },
    username(new_val) {
      if (!new_val) return;
      this.username_state = true;
    },
    phone(new_val) {
      if (!new_val) return;
      if (this.phone.length > 5 && this.phone.length <= 15) {
        this.phone_state = true;
      } else {
        this.phone_state = false;
      }
    },
    password(new_val) {
      if (!new_val) return;
      if (this.password.length >= 6) {
        // this.$Toast('提示信息');
        this.password_state = true;
      } else {
        this.password_state = false;
      }
    },
    password2(new_val) {
      if (!new_val) return;
      if (this.password2.length >= 6 && this.password === this.password2) {
        // this.$Toast('提示信息');
        this.password2_state = true;
      } else {
        this.password2_state = false;
      }
    },
    captcha(new_val) {
      if (!new_val) return;
      if (this.captcha.length >= 4) {
        // this.$Toast('提示信息');
        this.captcha_state = true;
      } else {
        this.captcha_state = false;
      }
    },
  },
  methods: {
    // ...mapActions(['login_methonds']),
    set_cot() {
      // const locale = this.$i18n.locale
      // if (locale === 'en') {
      //   this.cot = '+65'
      // } else if (locale === 'hk') {
      //   this.cot = '+852'
      // } else if (locale === 'tai') {
      //   this.cot = '+66'
      // } else {
      //   this.cot = '+852'
      // }
      this.cot = '+852'
    },
    select(e) {
      this.cot = e.key
    },
    set_num() {
      window.setTimeout(() => {
        this.num -= 1
        localStorage.setItem('code_num', this.num)
        if (this.num == 0) return
        this.set_num()
      }, 1000)
    },
    async get_code() {
      if (!this.phone) {
        this.$Toast(this.$t('message.i12'));
        return
      }
      this.num = 180
      this.set_num()
      const params = {
        phone: this.cot + this.phone || ''
      }
      const { errNum, data } = await this.$http('sendCode', params);
      if (errNum === 1 && data) {
        this.back_captcha = data.sendCode
        // console.log(this.back_captcha)
        this.$Toast(this.$t('message.i27'));
      } else {
        this.$Toast(this.$t('message.i28'));
      }
    },
    goto_page(type) {
      this.$router.replace({ name: 'Login', query: { type: type, invite_code: this.$route.query.invite_code } })
      this.type = type
    },
    isPoneAvailable($poneInput) {
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test($poneInput)) {
        return false;
      } else {
        return true;
      }
    },
    back() {
      this.$router.push({ name: 'Mine', query: { invite_code: this.$route.query.invite_code } });
    },
    async forgove() {
      // if (this.captcha !== this.back_captcha) {
      //   this.$Toast("验证码错误");
      //   return
      // }
      const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get()
      const visitorId = result.visitorId
      const params = {
        account: this.cot + this.phone,
        phone: this.cot + this.phone,
        password: this.password,
        captcha: this.captcha,
        visitorId: visitorId,
        phoneModel: phoneModel()
      };
      this.commom('updateUser', params)
    },
    async login() {
      const params = {
        account: this.cot + this.phone,
        password: this.password,
      };
      this.commom('goLogin', params)
    },
    async register() {
      if (this.captcha !== this.back_captcha) {
        this.$Toast(this.$t('message.i62'));
        return
      }
      const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get()
      const visitorId = result.visitorId
      const params = {
        account: this.cot + this.phone,
        phone: this.cot + this.phone,
        password: this.password,
        surname: this.surname,
        name: this.name,
        visitorId: visitorId,
        phoneModel: phoneModel()
      };
      // console.log('visitorId: ', visitorId)
      // console.log('phoneModel: ', phoneModel())
      this.commom('setUser', params)
    },
    async commom(api_name, params) {
      if (api_name !== 'goLogin') {
        if (this.password.length < 6) {
          this.$Toast(this.$t('message.i29'));
          return;
        }
        if (api_name === 'setUser') {
          if (!this.phone) {
            this.$Toast(this.$t('message.i12'));
            return
          }
          if (this.password !== this.password2) {
            this.$Toast(this.$t('message.i32'));
            return
          }
        }
      }
      // console.log(params)
      const { errNum, errMsg, data } = await this.$http(api_name, params);
      if (errNum === 1) {
        this.$Toast(this.$t('message.i33'));
        this.$store.commit('user_info', data)
        this.$store.commit('my_token', data.token)
        this.$router.push({ name: 'Mine' })
      } else if (errNum === 2) {
        if (api_name === 'updateUser') {
          this.$Toast(this.$t('message.i34'));
        } else if (api_name === 'goLogin') {
          this.$Toast(this.$t('message.i35'));
        } else if (api_name === 'setUser') {
          this.$Toast(this.$t('message.i36'));
        }
      } else {
        this.$Toast(errMsg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  padding: 20px;
  .close {
    height: 30px;
    text-align: right;
    color: #fff;
    .iconguanbi {
      font-size: 25px;
    }
  }
  .a1 {
    text-align: left;
    color: #fff;
  }
  .a2 {
    font-size: 15px;
  }
  .form {
    margin-top: 35px;
    margin-bottom: 45px;
  }
  .booo {
    .ii {
      color: #fff;
      font-size: 14px;
      color: #2196f3;
    }
  }
  .bottom {
    font-size: 14px;
    margin-top: 80px;
    color: #2196f3;
  }
}
</style>
