<template>
  <div id="content">
    <keep-alive>
      <router-view id="box" />
    </keep-alive>
    <mt-tabbar v-if="isPcOrMobile === 1" v-model="selected" :fixed="true">
      <mt-tab-item :id="item.id" v-for="(item, k) in navs" :key="k+'navs'">
        <i v-if="item.icon" class="iconfont" :class="[ item.icon ]"></i>
        <img v-else :src="item.img" class="own">
        <span>{{item.text}}</span>
      </mt-tab-item>
    </mt-tabbar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import tvpng from '@/assets/others/tv.png'
export default {
  name: "Content",
  data() {
    return {
      selected: null
    };
  },
  computed: {
    ...mapGetters({
      my_token: 'my_token',
      menus: 'menus',
      isPcOrMobile: 'isPcOrMobile'
    }),
    navs() {
      return [
        // {
        //   id: 'Home',
        //   text: this.$t('message.i40'),
        //   icon: 'iconshouye'
        // },
        {
          id: 'Types',
          text: this.$t('message.i41'),
          icon: '',
          img: tvpng
        },
        {
          id: 'Extend',
          text: this.$t('message.i42'),
          icon: 'iconziyuanxhdpi'
        },
        {
          id: 'Discount',
          text: this.$t('message.星汇娱乐'),
          icon: 'iconhuangguan'
        },
        {
          id: 'Tips',
          text: this.$t('message.心水推荐'),
          icon: 'iconshoucang'
        },
        {
          id: 'Mine',
          text: this.$t('message.i1') + '/' + this.$t('message.i2'),
          icon: 'iconwode'
        },
        // {
        //   id: 'Mine',
        //   text: this.my_token ? this.$t('message.i61') : this.$t('message.i43'),
        //   icon: 'iconwode'
        // },
      ]
    },
  },
  mounted() {
    const name = this.$route.name
    const next = this.navs.some(item => item.id === name)
    if (!next) return
    this.selected = name
  },
  watch: {
    selected(newVal) {
      if (newVal === 'Discount') {
        // console.log(this.menus)
        const configs = this.menus.menu4
        if (configs.open_type === '新开窗口打开') {
          window.open(configs.link)
        } else {
          window.location.href = configs.link
        }
        return
      }
      const name = this.$route.name
      if (name === newVal || !newVal) return
      this.$router.push({ name: newVal, query: { invite_code: this.$route.query.invite_code } });
    },
    '$route': 'change'
  },
  methods: {
    change() {
      const name = this.$route.name
      const next = this.navs.some(item => item.id === name)
      if (!next) {
        this.selected = ''
        return
      }
      this.selected = name
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
#content {
  width: 100%;
  height: 100%;
  padding-bottom: 47px;

  #box {
    width: 100%;
    // height: calc(100% - 47px);
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 20px;
    // position: relative;
    // bottom: 47px;
    // top: 0;
  }
  .own {
    width: 20px;
    margin: 0 auto;
    color: #ffc107;
  }
}
</style>