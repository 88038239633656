<template>
  <div class="tutorial-index">
    <img :src="bg" alt="" />
    <div class="button">
      <div class="back" @click="$router.push({ name: 'SecondPage' })">返回下载页面</div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            bg: require('@/assets/others/three_page_bg.jpg')
        }
    },
}
</script>
<style lang="less" scoped>
.tutorial-index {
  img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
  .button {
    background: #5980f3;
    padding: 1.333vw 0 10.667vw 0;
    .back {
      width: 80vw;
      margin: 0 auto;
      padding: 2.667vw 0;
      text-align: center;
      font-size: 4vw;
      color: #5d4ca7;
      background: #fff;
      border-radius: 10.667vw;
    }
  }
}
</style>