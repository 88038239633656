const state = {
    isCollapse:true,
    isLevelOneCollapse:true,
    isLevelTwoCollapse:false,
    typeMenus: {},
}
const getters = {
    isCollapse: state => state.isCollapse,
    typeMenus: state => state.typeMenus,
}
const actions = {}
const mutations = {
    set_is_collapse: (state, param) => {
        state.isCollapse = param
    },
    SET_MENUS: (state, param) => {
        state.typeMenus = param
    },
}


export default {
    namespaced:false,
    state,
    getters,
    actions,
    mutations
}