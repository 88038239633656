<template>
  <div class="home" v-if="others">
    <div class="ios-index" :style="{ backgroundImage: 'url(' + others.other_pic_link.SecondPage.bg + ')' }">
      <div class="box">
        <div class="item AClass">
          <div class="setup" :style="{ backgroundImage: 'url(' + setup + ')' }">第一步</div>
          <div class="body">
            <div class="text">
              <p>首先安装【TestFlight】然后返回本页</p>
              <p>如已安装，请直接操作第二步</p>
            </div>
            <div class="button">
              <a
                href="https://itunes.apple.com/cn/app/testflight/id899247664?mt=8"
                target="_blank"
                ><div class="ico"><img :src="one_setup" alt="" /></div>
                <div class="tip">安装 TestFlight</div></a
              >
            </div>
          </div>
        </div>
        <div class="item BClass">
          <div class="setup" :style="{ backgroundImage: 'url(' + setup + ')' }">第二步</div>
          <div class="body">
            <div class="text">
              <p>点击下载 【左手视频】</p>
              <p>请勿在意名字，下载完成后打开即为左手视频</p>
            </div>
            <div class="button">
              <a
                @click="download()"
                :href="others.download_link.ios_testflight"
                target="_blank"
                data-clipboard-text="P24GH0"
                class="clipboard_button"
                ><div class="ico">
                  <img
                    :src="two_setup"
                    alt=""
                    style="border-radius: 6px"
                  />
                </div>
                <div class="tip">下载 左手视频</div></a
              >
            </div>
            <div class="tutorial">
              <div class="text" @click="$router.push({ name: 'ThreePage' })">
                如遇提示 "无法打开链接"，查看设置教程 &gt;&gt;&gt;
              </div>
            </div>
          </div>
        </div>
        <div class="item BClass">
          <div class="setup" :style="{ backgroundImage: 'url(' + setup + ')' }">备用下载</div>
          <div class="body">
            <div class="text">
              <p>苹果备用下载通道</p>
              <p>以上方式均无法安装的时候，请点击此下载</p>
            </div>
            <div class="button">
              <a
                @click="download()"
                :href="others.download_link.ios_enterprise"
                target="_blank"
                data-clipboard-text="P24GH0"
                class="clipboard_button"
                ><div class="ico">
                  <img
                    :src="others.other_pic_link.logo"
                    alt=""
                    style="border-radius: 6px"
                  />
                </div>
                <div class="tip">备用下载通道</div></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getSystemOs, phoneModel } from '@/utiles'
export default {
  name: "SecondPage",
  components: {},
  computed: {
    ...mapGetters({
      others: 'others',
      visitorId: 'visitorId',
    }),
  },
  data() {
    return {
      setup: require("@/assets/others/setup.png"),
      one_setup: require('@/assets/others/one_setup.png'),
      two_setup: require('@/assets/others/two_setup.png'),
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo({ 
        top: 0, 
        behavior: "smooth" 
      })
    })
  },
  methods: {
    async set_download(params) {
      await this.$http('setAppInvite', params);
    },
    async download() {
      const user_os = getSystemOs()
      const phoneModels = phoneModel()
      const invite_code = this.$route.query.invite_code
      const params = {
        invite_code: invite_code || '',
        visitorId: this.visitorId,
        phoneModels: phoneModels,
      }
      if (user_os == 'android') {
        this.set_download(params)
        this.$router.push({ name: 'FirstPage', query: { invite_code: this.$route.query.invite_code } })
        window.open(this.others.download_link.android)
      } else if (user_os == 'ios') {
        this.set_download(params)
      } else {
        this.set_download(params)
        this.$router.push({ name: 'FirstPage', query: { invite_code: this.$route.query.invite_code } })
        window.open(this.others.download_link.android)
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  .ios-index {
    width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-color: #4d81fb;
    background-size: 100%;
    .box {
      width: 92vw;
      padding: 60vw 0 13.333vw 0;
      margin: 0 auto;
      .item {
        width: 92vw;
        padding: 0 0 4vw 0;
        background: #fff;
        border-radius: 2.667vw;
        position: relative;
        margin: 0 0 4vw 0;
        .setup {
          width: 20vw;
          height: 10.667vw;
          line-height: 9.067vw;
          position: absolute;
          background-repeat: no-repeat;
          background-position: top;
          background-size: 20vw 10.667vw;
          left: -2.4vw;
          top: 3.467vw;
          color: #fff;
          text-align: center;
        }
        .body {
          padding: 16vw 0 0 0;
          .text {
            p {
              text-align: center;
              margin: 0;
              padding: 0;
              border: 0;
              line-height: 1;
              font-size: 4.267vw;
              font-weight: 700;
              color: #333;
            }
            p:last-child {
                color: #666;
                font-size: 3.733vw;
                padding: 2.667vw 0 0 0;
                font-weight: 400;
            }
          }
          .button {
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 53.333vw;
              height: 12vw;
              margin: 6.133vw auto 0 auto;
              border-radius: 12vw;
              border: 1px solid #4d81fb;
              text-decoration: none;
            }
            .ico {
              width: 8vw;
              height: 8vw;
              margin: 0 2.667vw 0 0;
              img {
                width: 100%;
                vertical-align: top;
              }
            }
            .tip {
              color: #333;
              font-size: 4vw;
              font-weight: 700;
            }
          }
          .tutorial {
            text-align: center;
            color: red;
            padding: 2.667vw 0 1.333vw 0;
            margin: 2.667vw 0 1.333vw 0;
            .text {
              font-size: 3.733vw;
            }
          }
        }
      }
    }
  }
}
</style>
