import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import i18n from '@/assets/i18n/i18n'
// import VueScroller from 'vue-scroller'



import './injects'
import '@/assets/iconfont.css'
// import 'iview/dist/styles/iview.css' //全局加载iview

// Vue.use(VueScroller);
Vue.use(VueClipboard);
Vue.config.productionTip = false
Vue.prototype.$video = Video

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
