import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
// import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  const my_token = window.localStorage.getItem('hkhk_my_token')
  if (to && to.name === 'Play') {
    if (!my_token) {
      // 暂时开放
      Vue.prototype.$Toast("请先登录");
      next({
        name: 'Login',
        query: {
          type: 'login'
        }
      })
    }
  }
  next();
});

// 解决上面跳转加参数报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

// 修改路由replace方法,阻止重复点击报错
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

export default router