export default {
    /**
     * 获取新闻
     */
    getSgNews: params => {
        return {
            url: '/admin/getSgNews',
            method: 'post',
            params
        }
    },
    getNewsDetail: params => {
        return {
            url: '/admin/getNewsDetail',
            method: 'post',
            params
        }
    },
}