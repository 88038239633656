<template>
    <div class="Fullscreen">
        <img v-if="matches === 1" class="trans" src="@/assets/others/transform.webp" alt="transform.webp" />
        <div class="box" :class="[matches === 1 ? 'no_normal' : 'normal' ]" :style="[matches === 1 ? { width: no_normal.height, height: no_normal.width } : {} ]">
            <iframe
                :src="videoUrlNormal"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                width="100%"
                height="100%"
            ></iframe>
            <!-- <p class="close_" v-if="fullscreen" @click="$router.go(-1)">
                <span :class="[$i18n.locale !== 'hk' ? 'ii' : '']">{{ $t('message.i65') }}</span>
            </p> -->
        </div>
    </div>
</template>
<script>

export default {
    name: 'Fullscreen',
    data() {
        return {
            videoUrlNormal: '',
            matches: null,
            no_normal: {},
        }
    },
    created() {
        this.videoUrlNormal = this.$route.query.url
    },
    mounted() {
        this.launchFullscreen()
        const screenDirection = window.matchMedia('(orientation: portrait)');
        screenDirection.addListener(this.handleOrientationChange);
        this.handleOrientationChange(screenDirection);
        this.matches = 1
        this.no_normal.width = document.body.offsetWidth + 'px'
        this.no_normal.height = document.body.offsetHeight + 'px'
    },
    methods: {
        handleOrientationChange(screenDirection) {
            if (screenDirection.matches) {
                 /* 竖屏处理事件 */
                this.matches = 1
                this.no_normal.width = document.body.offsetWidth + 'px'
                this.no_normal.height = document.body.offsetHeight + 'px'
                // console.log(this.no_normal)
            } else {
                 /* 横屏屏处理事件 */
                this.matches = 2
            }
        },
        launchFullscreen() {
            const element = document.documentElement
            if (element.requestFullscreen) {
                element.requestFullscreen()
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen()
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen()
            }
        },
    },
}
</script>
<style lang="less" scoped>
.Fullscreen {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .trans {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
        z-index: 9;
    }
    .normal {
        width: 100% !important;
        height: 100% !important;
    }
    .no_normal {
        // width: 896px !important;
        // height: 414px !important;
        transform: rotate(90deg);
    }
    .box {
        .close_ {
            font-size: 15px;
            color: #fff;
            // transform: rotate(90deg);
            height: 100%;
            display: flex;
            align-items: center;
            background-color: #000;
            width: 30px;
            justify-content: center;
            .ii {
                transform: rotate(90deg);
            }
        }
    }
}
</style>