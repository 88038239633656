<template>
  <div class="page-content">
    <!-- <NoData></NoData> -->
    <header class="title">
      <div class="t-btn">
        <button :class="{'btn-span tab-cursor': true, 'active': dateType === 'Y'}"
                @click.stop="dateType = 'Y', isMonth = false">年</button>
        <button :class="{'btn-span tab-cursor': true, 'active': dateType === 'M'}"
                :style="{'border-radius': (dateType === 'M' ? '3px 3px': '')}"
                @click.stop="clickMonth(dateObj.month)">月</button>
        <button :class="{'btn-span tab-cursor': true, 'active': dateType === 'D'}"
                :style="{'border-radius': (dateType === 'D' ? '0 3px 3px 0': '')}"
                @click.stop="dateType = 'D', isMonth = false">日</button>
      </div>
      <div class="thead">
        <i class="icon-img icon-left tab-cursor el-icon-arrow-left" @click.stop="lastMonth"></i>
         <!-- <i class="icon-img icon-left tab-cursor" @click.stop="lastMonth">
          <svg-icon icon-class="icon-left"></svg-icon>
        </i> -->
        <p class="thead-date" v-show="dateType === 'Y'">{{ dateObj.year }}年</p>
        <p class="thead-date" v-show="dateType === 'M'">{{ dateObj.year }}年{{ dateObj.month | filterTime }}月</p>
        <p class="thead-date" v-show="dateType === 'D'">{{ dateObj.year }}年{{ dateObj.month | filterTime }}月{{ dateObj.days | filterTime }}日</p>
        <i class="icon-img icon-right tab-cursor el-icon-arrow-right" @click.stop="nextMonth"></i>

         <!-- <i class="icon-img icon-right tab-cursor" @click.stop="nextMonth">
          <svg-icon icon-class="icon-right"></svg-icon>
        </i> -->
      </div>
    </header>
    <section style="width: 100%;">
      <div class="box-content">
        <span class="already-radius"></span>
        <p class="plan-txt">已排计划</p>
        <span class="free-radius"></span>
        <p class="plan-txt">空闲</p>
        <span class="not-already-radius"></span>
        <p class="plan-txt">非工作日</p>
      </div>
      <!-- 年日历 -->
      <table class="box-table"
             v-show="dateType === 'Y'">
        <tr v-for="(item, index) in yearDateList"
            :key="index"
            class="box-w25"
            @click="clickMonth(item)">
          <td class="box-td">
            <calendar-Component v-if="showCalendar"
                                :isMonth="isMonth"
                                :planList="planList"
                                :dateType="dateType"
                                :dateObj={year:dateObj.year,month:item}></calendar-Component>
          </td>
        </tr>
      </table>
      <!-- 月日历 -->
      <div v-show="dateType === 'M'">
        <calendar-Component :isMonth="isMonth"
                            @pickOneDay="pickOneDay"
                            :planList="planList"
                            :dateType="dateType"
                            :dateObj="dateObj"
                            v-if="showCalendar"
                            ref="monthDate"></calendar-Component>
      </div>
      <!-- 日 日历 -->
      <div v-show="dateType === 'D'">
        <day-Calendar :isMonth="isMonth"
                      :planList="planList"
                      :dateType="dateType"
                      :dateObj="dateObj"></day-Calendar>
      </div>
    </section>
  </div>
</template>
<script>
import calendar from './calendar'
import dayDate from './dayCalendar'
let newArr = []
export default {
  name: 'ServicePlan',
  data () {
    return {
      isMonth: true,
      showCalendar: false, // 是否展示日历
      dateType: 'M',
      dateObj: {},
      yearDateList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      planList: [], // 服务计划日期
      planTime: [] // 服务计划时间
    }
  },
  filters: {
    filterTime (val) {
      return val < 10 ? `0${val}` : val
    }
  },
  components: {
    'calendar-Component': calendar,
    'day-Calendar': dayDate
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
    //   this.$https('plan', { uuid: this.$route.query.serveId }).then(({ code, data }) => {
    //     if (code === '0') {
    //       const arry = []
    //       newArr = []
    //       // 获取服务器时间
    //       if (data.rows && data.rows.length > 0) {
    //         const date1 = data.rows[0].now.split('-')
    //         const date = []
    //         date1.forEach(e => {
    //           e = e.replace(/\b(0+)/gi, '')
    //           date.push(e)
    //         })
    //         this.dateObj = {
    //           year: date[0] || new Date().getFullYear(),
    //           month: date[1] || new Date().getMonth() + 1,
    //           days: date[2] || new Date().getDate()
    //         }
    //       } else {
    //         this.dateObj = {
    //           year: new Date().getFullYear(),
    //           month: new Date().getMonth() + 1,
    //           days: new Date().getDate()
    //         }
    //       }
    //       data.rows && data.rows.map(item => {
    //         arry.push(this.quryInterval(item.busyStartTime, item.busyEndTime))
    //       })
    //       const list = this.flatten(arry)
    //       this.planList = this.unique(list)
    //       this.showCalendar = true
    //     } else {
    //       this.showCalendar = true
    //     }
    //   }).catch(msg => {
    //     this.dateObj = {
    //       year: new Date().getFullYear(),
    //       month: new Date().getMonth() + 1,
    //       days: new Date().getDate()
    //     }
    //     this.showCalendar = true
    //     console.log(msg)
    //   })


       let data = {"total":1,"rows":[{"now":"2021-01-25","busyStartTime":"2021-01-18","busyStartTime1":"00:00","busyEndTime":"2021-01-22","busyEndTime1":"00:00","startTime":null,"endTime":null}]}
      let code = "0";
      if (code === "0") {
        const arry = [];
        newArr = [];
        // 获取服务器时间
        if (data.rows && data.rows.length > 0) {
          const date1 = data.rows[0].now.split("-");
          const date = [];
          date1.forEach(e => {
            e = e.replace(/\b(0+)/gi, "");
            date.push(e);
          });

          this.dateObj = {
            year: date[0] || new Date().getFullYear(),
            month: date[1] || new Date().getMonth() + 1,
            days: date[2] || new Date().getDate()
          };
        } else {
          this.dateObj = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            days: new Date().getDate()
          };
        }
        data.rows &&
          data.rows.map(item => {
            arry.push(this.quryInterval(item.busyStartTime, item.busyEndTime));
          });
        const list = this.flatten(arry);
        this.planList = this.unique(list);
        this.showCalendar = true;
      } else {
        this.showCalendar = true;
      }
      
    },
    // 把嵌套数组拉平
    flatten (arr) {
      for (const key of arr) {
        if (Array.isArray(key)) {
          this.flatten(key) // 如果还是数组继续递归调用
        } else {
          newArr.push(key)
        }
      }
      return newArr
    },
    // 数据区重复
    unique (arr) {
      return Array.from(new Set(arr))
    },
    // 查找区间数值
    quryInterval (a, b) {
      const q = String(a).replace(/-/g, '')
      const w = String(b).replace(/-/g, '')
      const arry = []
      for (let d = q; d <= w; d++) {
        arry.push(Number(d))
      }
      return arry
    },
    // 上一个月
    lastMonth () {
      if (this.dateType === 'Y') {
        this.dateObj.year--
      } else if (this.dateType === 'D') {
        if (this.dateObj.days == 1) {
          if (this.dateObj.month == 1) {
            this.dateObj.month = 12
            this.dateObj.year--
          } else {
            this.dateObj.month--
          }
          this.dateObj.days = new Date(this.dateObj.year, this.dateObj.month, 0).getDate()
        } else {
          this.dateObj.days--
        }
      } else {
        if (this.dateObj.month == 1) {
          this.dateObj.month = 12
          this.dateObj.year--
        } else {
          this.dateObj.month--
        }
      }
    },
    // 下个月
    nextMonth () {
      if (this.dateType === 'Y') {
        this.dateObj.year++
      } else if (this.dateType === 'D') {
        if (this.dateObj.days == new Date(this.dateObj.year, this.dateObj.month, 0).getDate()) {
          if (this.dateObj.month == 12) {
            this.dateObj.month = 1
            this.dateObj.year++
          } else {
            this.dateObj.month++
          }
          this.dateObj.days = 1
        } else {
          this.dateObj.days++
        }
      } else {
        if (this.dateObj.month == 12) {
          this.dateObj.month = 1
          this.dateObj.year++
        } else {
          this.dateObj.month++
        }
      }
    },
    // 点击某一个月份
    clickMonth (month) {
      this.dateType = 'M'
      this.isMonth = true
      this.dateObj.month = month
      this.$refs.monthDate.initPage()
    },
    // 月日历点击了某一天
    pickOneDay (item) {
      this.dateType = 'D'
      this.isMonth = false
      this.dateObj.days = item
    }
  }
}
</script>
<style scoped>
button {
  border: 0;
  background-color: transparent;
  outline: none;
}
.page-content {
  width: 100%;
  margin-left: 2px;
  margin-bottom: 94px;
}
.title {
  width: 100%;
  display: flex;
  margin: 2.1% 0 1.6% 0;
}
.thead {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.t-btn {
  width: 24%;
  display: flex;
}
.btn-span {
  width: 64px;
  height: 32px;
  font-family: 'PingFangSC-Medium';
  font-size: 14px;
  color: #575757;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 3px 0 0 3px;
}
.thead-date {
  /* width: 40%; */
  font-family: 'PingFangSC-Medium';
  font-weight: bold;
  font-size: 24px;
  color: #171717;
  text-align: center;
}
.icon-img {
  /* width: 20%; */
  background-size: 24px 24px;
}
.icon-right {
  margin-left: 24px;
  text-align: right;
}
.icon-left {
  margin-right: 24px;
  text-align: left;
}
.active {
  color: #016ae0;
  border: 1px solid #006ae1;
}
.box-content {
  display: flex;
  align-items: center;
  margin-bottom: 2.4%;
}
.plan-txt {
  font-family: 'PingFangSC-Regular';
  font-size: 14px;
  color: #171717;
  margin-left: 0.3%;
}
/* 已安排 */
.already {
  color: #e02020;
}
/* 空闲 */
.free {
  color: #00b532;
}
/* 未排计划 */
.not-already {
  color: #8c8c8c;
}
/* 已安排 */
.already-radius {
  /* content: ''; */
  width: 8px;
  height: 8px;
  border: 1px solid #e02020;
  background-color: #e02020;
  border-radius: 50% 50%;
}
/* 空闲 */
.free-radius {
  /* content: ''; */
  width: 8px;
  height: 8px;
  border: 1px solid #00b532;
  background-color: #00b532;
  border-radius: 50% 50%;
  margin-left: 1.6%;
}
/* 未排计划 */
.not-already-radius {
  /* content: ''; */
  width: 8px;
  height: 8px;
  border: 1px solid #8c8c8c;
  background-color: #8c8c8c;
  border-radius: 50% 50%;
  margin-left: 1.6%;
}
.box-table {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.box-w25 {
  width: 25%;
  display: flex\0;
  padding: 0 6px;
  margin-bottom: 2.9%;
}
.box-td {
  cursor: pointer;
  display: flex;
  width: 100%
}
.tab-cursor {
  cursor: pointer;
}
</style>
