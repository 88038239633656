<template>
  <div class="Reback">
    <mt-header :title="$t('message.i47')" :fixed="true">
      <router-link
        :to="{ name: 'Mine', query: { invite_code: $route.query.invite_code } }"
        slot="left"
      >
        <i class="iconfont iconzuojiantou"></i>
      </router-link>
      <!-- <div slot="right">清空</div> -->
    </mt-header>
    <div class="arrow">
      <span>{{ $t('message.i50') }}</span>
    </div>
    <div class="idea">
      <button
        @click="select(i)"
        v-for="(item, i) in types"
        :key="i + 'types'"
        :class="[type === i ? 'handle' : '']"
      >
        {{ item }}
      </button>
    </div>
    <div class="content">
      <p class="arrow-content">{{ $t('message.i51') }}</p>
      <div class="description">
        <mt-field
          v-model="content"
          :placeholder="$t('message.i60')"
          type="textarea"
        ></mt-field>
      </div>
    </div>
    <div class="commit"><button class="submit" @click="refer">{{ $t('message.i52') }}</button></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "Reback",
  components: {},
  data() {
    return {
      type: 3,
      content: ""
    };
  },
  computed: {
    ...mapGetters({
      user_info: 'get_user_info'
    }),
    types() { 
      return [
        this.$t('message.i53'),
        this.$t('message.i54'),
        this.$t('message.i55'),
        this.$t('message.i56'),
      ]
    }
  },
  methods: {
    select(i) {
      this.type = i;
    },
    async refer() {
      const param = {
        uid: this.user_info ? this.user_info.id : '',
        title: this.type,
        content: this.content,
        phone: this.user_info ? this.user_info.phone : ''
      }
      if (!param.uid) {
        this.$Toast(this.$t('message.i57'));
        return
      }
      const { errNum, data } = await this.$http('setUserReback', param);
      if (errNum === 1 && data) {
        this.content = ''
        this.$Toast(this.$t('message.i58'));
      }else{
        this.$Toast(this.$t('message.i59'));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Reback {
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: calc(100% - 20px);
 overflow-y: auto;
-webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  .arrow {
    color: #fff;
    span {
      display: inline-block;
      font-size: 14px;
      left: 0;
      margin-right: 320px;
      width: 100%;
      text-align: left;
    }
  }
}
.idea {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  button {
    width: 29%;
    height: 35px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 20px;
    background: white;
    color: #000;
    // border: 1px solid #eee;
    border: none;
    outline: none;
  }
  .handle {
    background-color: #ffd602;
    color: #fff;
  }
}
.content {
  margin-top: 10px;
  margin-bottom: 10px;
  .arrow-content {
    color: #fff;
    font-size: 14px;
    margin-right: 320px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
  }
}
.submit {
  width: 80%;
  height: 40px;
  margin-top: 20px;
  background: #ffd602;
  border-radius: 20px;
  font-size: 16px;
  border: none;
  outline: none;
  color: #fff;
}
</style>
