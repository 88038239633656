<template>
  <div class="NoData">
    <img :src="require('../../../assets/no_data.png')" alt="" />
    <p>{{ $t('message.i63') }}</p>
  </div>
</template>

<script>
// import { Chart } from '@antv/g2';
export default {
  name: "NoData",
  props: {
    // iconClass:{
    //   type:String,
    //   required:true
    // },
    // className:{
    //    type:String,
    //    default:''
    // }
  },
};
</script>

<style scoped lang="less">
.NoData {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
    height: 200px;
  }
  p {
    font-size: 16px;
    color: #ffd602;
  }
}
</style>
