<template>
  <div class="News" ref="lists">
    <!--  <scroller
      :on-refresh="refresh"
      :on-infinite="infinite"
      refreshText="Pull-to-Refresh"
      noDataText="No-Data"
      ref="my_scroller_news"
    > -->
    <div class="content">
      <!-- <ul v-if="centerList && centerList.length !== 0">
        <li v-for="(m, n) in centerList" :key="n" @click="openNews(m)">
          <div class="img">
            <img v-if="m.img" :src="m.img" alt="newsPic" />
          </div>
          <div class="info">
            <h3>{{ m.title }}</h3>
            <p class="con" v-html="m.contents"></p>
            <p class="time">{{ m.addtime }}</p>
          </div>
        </li>
      </ul> -->
      <NewsList :lists="centerList" routeName="NewsDetail" />
      <!-- <NoData v-else /> -->
      <p v-show="centerList && centerList.length !== 0" @click="nextPage" class="nextPage">下一页</p>
    </div>
    <!-- </scroller> -->
  </div>
</template>
<script>
// import Poster from './components/Poster'
import { mapGetters } from "vuex";

export default {
  name: "News",
  components: {
    // Poster,
  },
  computed: {
    ...mapGetters({
      getNewsData: "getNewsData",
      getNewsPage: "getNewsPage",
      getNewsLoadState: "getNewsLoadState",
      getCont: "getCont",
      typeMenus: 'typeMenus'
    }),
    centerList: function() {
      const data = this.getNewsData.data || [];
      return data.filter(function(item, m) {
        return m < 9;
      });
    },
  },
  watch: {
    '$route.query.type': 'getData',
    getCont() {
      this.getData();
    }
  },
  data() {
    return {
      lists: [],
      types_bg: [],
      page: 1,
      limit: 20,
    };
  },
  created() {
    if (!this.getNewsData.data) {
      this.getData();
    }
    // window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    // window.removeEventListener('scroll', this.handleScroll) //页面离开后销毁监听事件
  },
  methods: {
    nextPage() {
      this.page += 1
      this.getData('next');
    },
    handleScroll(e) {
      //与第 4 行 ref 的值相对应
      let dom = this.$refs.lists
      // console.log(dom)

      //>>>>滚动条滚动距离
      let scrollTop = e.target.scrollTop;

      //文档内容实际高度（包括超出视窗的溢出部分）
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);

      //窗口可视范围高度
      let clientHeight = dom.innerHeight || Math.min(dom.clientHeight, dom.clientHeight);

      // console.log(scrollHeight)
      if (clientHeight + scrollTop >= scrollHeight) {
        console.log('到底了')
      }
    },
    async getData(ee) {
      const locale = this.$i18n.locale
      if (locale === 'hk') {
        this.$store.commit('SETCONT', 'TraditionalChinese')
      } else if (locale === 'tai') {
        this.$store.commit('SETCONT', 'Thailand')
      } else if (locale === 'en') {
        this.$store.commit('SETCONT', 'English')
      }
      const param = {
        siteName: "afb",
        page: this.page,
        limit: this.limit,
        cont: this.getCont,
        type: this.$route.query.type || ''
      }
      // console.log(param)
      const { code, data } = await this.$http('getSgNews', param);
      // console.log(code)
      // console.log(data)
      if (code === 20000 && data) {
        const newsData = []
        if (data && data.length !== 0) {
          data.forEach(item => {
            // if (window.myConfigs.isNotApp) {
            const be = window.location.protocol ? window.location.protocol : 'http:'
            item.content = item.content.replace(/http:/g, be)
            // }
            newsData.push(item)
          })
        } else {
          // console.log(ee)
          if (!ee) {
            this.$store.commit('RESETNEWS')
          }
          return
        }
        const backdata = {
          allPage: data.allPage,
          allCount: data.allCount,
          data: newsData
        }
        this.$store.commit('NEWS', {
          a: param.page,
          b: backdata,
          name: param.pagename
        })
      } else {
        // console.log(ee)
        if (!ee) {
          this.$store.commit('RESETNEWS')
        }
      }
    },
    /**
     * 上拉获取
     */
    infinite(done) {
      if (this.getNewsLoadState) {
        // this.one = false;
        this.$store.commit("UPDATENEWSLOADSTATE", false);
        done(true);
        return;
      }
      // this.getNewsPage += 1;
      const page = this.getNewsPage + 1;

      if (this.getNewsData.data && this.getNewsData.data.length !== 0) {
        this.$store.commit("UPDATENEWSPAGE", page);
      } else {
        this.$store.commit("UPDATENEWSPAGE", 1);
      }

      const _this = this;
      async function b() {
        await _this.$store.dispatch("getNewsData", {
          _this,
          siteName: "afb",
          page: _this.getNewsPage,
          c: done,
          cont: this.getCont,
          limit: 10,
        });
      }
      b();
      // this.enterfullscreen()
    },
    refresh() {
      // this.page = 1;
      this.$store.commit("UPDATENEWSPAGE", 1);
      const _this = this;
      async function a() {
        await _this.$store.dispatch("getNewsData", {
          _this,
          siteName: "afb",
          page: _this.getNewsPage,
          cont: this.getCont,
          limit: 10,
        });
        // done();
        _this.$refs.my_scroller_news.finishPullToRefresh();
      }
      a();
    },
  },
};
</script>
<style lang="less" scoped>
.News {
  padding: 10px;

  .content {
    overflow: hidden;

    .nextPage {
      width: 150px;
      padding: 10px;
      background: #ffd602;
      border-radius: 10px;
      color: #000;
      font-size: 19px;
      margin: 20px auto;
      cursor: pointer;
    }

    ul {
      margin: 5px;

      li {
        display: flex;
        width: 100%;
        background-color: transparent;
        border: 1px solid #ffd602;
        margin-bottom: 5px;
        border-radius: 5px;
        height: 180px;
        padding-left: 5px;

        .img {
          width: 30%;
          height: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            display: block;
            width: 100%;
            height: 120px;
          }
        }

        .info {
          width: 70%;
          padding: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          color: #ffd602;

          h3 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .time {
            font-size: 12px;
            color: #ffd602;
          }

          .con {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            // white-space: nowrap;
            height: 50px;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}
</style>