
import {importAllFiles} from '@/utiles'
import API from '../api'
import mockAdapter from './common'
import Mock from 'mockjs'

const requireMock = (excludeFiles = []) => {
  const req = require.context('./modules', true, /\.js$/)
  return importAllFiles(req, excludeFiles)
}

/**
 * mock 请求
 *  @param {String} apiName api名称
 *  @param {Object}  data 请求响应数据
 *
*/

const mockRequest = (apiName, data = {}) => {
  if (!apiName) return
  if (typeof API[apiName] === 'undefined') {
    // console.log(`apiName:${apiName} mock 数据不存在`)
  } else {
    let {url, method} = API[apiName]({})
    switch (method) {
      case 'post':
        mockAdapter.onPost(url).reply(200, Mock.mock(data))
        break
      case 'get':
        mockAdapter.onGet(url).reply(200, Mock.mock(data))
        break
      default:
        mockAdapter.onPost(url).reply(200, Mock.mock(data))
    }
  }
}
// 引入mock文件
let MocksList = requireMock()

MocksList.length && MocksList.forEach(item => {
  mockRequest(item.apiName, item.data)
})
