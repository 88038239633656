<template>
  <div class="Collect">
    <mt-header title="我的收藏" :fixed="true">
      <router-link
        :to="{ name: 'Mine', query: { invite_code: $route.query.invite_code } }"
        slot="left"
      >
        <i class="iconfont iconzuojiantou"></i>
      </router-link>
      <div slot="right" @click="edit">{{ is_edit ? '完成' : '编辑'}}</div>
    </mt-header>
    <div v-if="lists && lists.length !== 0">
      <LoadMore
        :bottomStatus="bottomStatus"
        :allLoaded="allLoaded"
        @loadTop="loadTop"
        @loadBottom="loadBottom"
        @handleBottomChange="handleBottomChange"
        ref="gloadmore"
      >
        <VdListItem
         @select_item='select_item'
         :select_ids="select_ids"
          v-for="(n, i) in lists"
          :key="i + '1'"
          :is_edit="is_edit"
          :items="n"
        />
      </LoadMore>
    </div>
    <NoData v-else />
    <div class="bottom" v-if="is_edit">
      <div @click="all_select">全选</div>
      <div @click="delete_select">删除</div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixin/list'
export default {
  name: "Collect",
  mixins: [mixin],
  components: {},
  data() {
    return {
      user_info: window.sessionStorage.getItem("user_info")
        ? JSON.parse(window.sessionStorage.getItem("user_info"))
        : "",
      is_edit: false,
      lists: [],
      select_ids: [],
    };
  },
  created() {
    this.get_lists('log_top')
  },
  methods: {
    async get_lists(type) {
       if (type === 'log_top') {
        this.lists = []
      }
      const params = {
        uid: this.user_info ? this.user_info.id : "",
        page: this.page || 1,
      };
      const { errNum, data } = await this.$http("getCollect", params);
      if (errNum === 1 && data) {
        // console.log(data)
        data.forEach(element => {
          this.lists.push(element)
        });
        if (data.length === 0) {
          this.page = this.page > 1 ? this.page - 1 : 1
          this.allLoaded = true; // 若数据已全部获取完毕
        } else {
          this.allLoaded = false
        }
      } else {
        this.lists = [];
        this.$Toast("获取列表失败");
      }
    },
    all_select() {
      this.select_ids = []
      this.lists.forEach(item => {
        this.select_ids.push(item.id)
      })
    },
    async delete_select() {
      // console.log(this.select_ids)
      const params = {
        uid: this.user_info ? this.user_info.id : "",
        ids: this.select_ids || ''
      };
      const { errNum, data } = await this.$http("deleteCollect", params);
      if (errNum === 1 && data) {
        this.$Toast("删除成功");
        this.select_ids.forEach(item => {
          const i = this.lists.findIndex(items => items.id === item)
          this.lists.splice(i, 1)
        })
      } else {
        this.$Toast("发生错误");
      }
    },
    select_item(e) {
      const index = this.select_ids.indexOf(e)
      if (index !== -1) {
        this.select_ids.splice(index, 1)
      } else {
        this.select_ids.push(e)
      }
    },
    edit() {
      this.is_edit = !this.is_edit
      if (!this.is_edit) {
        this.select_ids = []
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Collect {
  width: 100%;
  padding-top: 40px;
  height: calc(100% - 41px);
 overflow-y: auto;
-webkit-overflow-scrolling: touch;
  .bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #eee;
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
      padding: 10px 0;
      font-size: 15px;
    }
    div:first-child {
      width: calc(50% - 1px) !important;
      border-right: 1px solid #ddd;
    }
  }
}
</style>
