
import Vue from 'vue'
// import { parse } from 'qs'

//@ts-ignore
import MobileDetect from "mobile-detect";
import getIphoneModel from "./iPhone-detect.min.js";

export const importAllFiles = (req, excludeFiles, returnType = 'array') => {
  let isArray = returnType === 'array'
  let tempList = isArray ? [] : {}
  req.keys().forEach(file => {
    let fileName = file.split('/')[1].split('.')[0]
    if (excludeFiles && excludeFiles.indexOf(fileName) > -1) return
    if (isArray) {
      tempList.push(...req(file).default)
    } else {
      tempList = {...tempList, ...req(file).default}
    }
  })
  return isArray ? [...tempList] : tempList
}
export const mocklogRequest = res => {

    let [config, resData, params] = [res.config, res.data, null]
    console.groupCollapsed(`mock:${config.method}:${config.url}`)

    console.group('request')
    if (config.method === 'post') {
      params = JSON.parse(config.data)
    } else if (config.method === 'get') {
      params = config.params
    }
    console.log('params',params)
    console.groupEnd()
    console.group('response')
    console.log('code:', resData.code)
    console.log('data:',resData.data)
    console.groupEnd()
    console.groupEnd()

}

export const registerComponents = (componetsList = []) => {
  const requireComponent = require.context('@/components/GlobalComponents', true, /.vue$/)
  requireComponent.keys().forEach(file => {
    let baseComponentConfig = requireComponent(file)
    baseComponentConfig = baseComponentConfig.default || baseComponentConfig
    const baseComponentName = baseComponentConfig.name ||
      file.replace(/^.+\//, '').replace(/\.\w+$/, '')
    if (!componetsList.includes(baseComponentName)) {
      Vue.component(baseComponentName, baseComponentConfig)
    }
  })
}

export const echartsComponents = (componetsList = []) => {
  const requireComponent = require.context('@/components/EchartComponents', true, /.vue$/)
  requireComponent.keys().forEach(file => {
    let baseComponentConfig = requireComponent(file)
    baseComponentConfig = baseComponentConfig.default || baseComponentConfig
    const baseComponentName = baseComponentConfig.name ||
      file.replace(/^.+\//, '').replace(/\.\w+$/, '')
    if (!componetsList.includes(baseComponentName)) {
      Vue.component(baseComponentName, baseComponentConfig)
    }
  })
}

export const IEVersion = () => {
  let userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
  let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 // 判断是否IE<11浏览器
  let isEdge = userAgent.indexOf('Edge') > -1 && !isIE // 判断是否IE的Edge浏览器
  let isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
  if (isIE) {
    const reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    const fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion == 7) {
      return 7
    } else if (fIEVersion == 8) {
      return 8
    } else if (fIEVersion == 9) {
      return 9
    } else if (fIEVersion == 10) {
      return 10
    } else {
      return 6
    }
  } else if (isEdge) {
    return 'edge'
  } else if (isIE11) {
    return 11
  } else {
    return -1 // 不是IE浏览器
  }
}

export const isIEBrowser = () => {
  return IEVersion() !== -1
}

export function debounce (fn, delay) {
  // 记录上一次的延时器
  var timer = null;
  const delays = delay || 200;
  return function() {
    var args = arguments;
    var that = this;
    // 清除上一次延时器
    clearTimeout(timer)
    timer = setTimeout(function() {
        fn.apply(that,args)
    }, delays);
  }
}

export function phoneModel() {
  let contains = function(deviceInfo, needle) {
    for (let i in deviceInfo) {
      if (deviceInfo[i].indexOf(needle) > 0) return i;
    }
    return -1;
  };
  let device_type = navigator.userAgent; //获取userAgent信息
  let md = new MobileDetect(device_type); //初始化mobile-detect
  let os = md.os(); //获取系统
  let model = "";
  if (os == "iOS") {
    //ios系统的处理
    os = md.os() + md.version("iPhone");
    model = getIphoneModel();
  } else if (os == "AndroidOS") {
    //Android系统的处理
    os = md.os() + md.version("Android");
    let sss = device_type.split(";");
    let i = contains(sss, "Build/");
    if (i > -1) {
      model = sss[i].substring(0, sss[i].indexOf("Build/"));
    }
  }
  const brand =
    md.mobile() !== "UnknownPhone"
      ? md.mobile()
      : md.phone() !== "UnknownPhone"
      ? md.phone()
      : "";
  return os == "iOS" ? `${model} (${os})` : `${brand} ${model} (${os})`;
}

export function getSystemOs() {
  let device_type = navigator.userAgent; //获取userAgent信息
  let md = new MobileDetect(device_type); //初始化mobile-detect
  let os = md.os(); //获取系统
  let model = "";
  if (os == "iOS") {
    //ios系统的处理
    model = 'ios'
  } else if (os == "AndroidOS") {
    //Android系统的处理
    model = 'android'
  } else {
    model = 'pc'
  }
  return model;
}

/**
 * 简单封装一下
 */
//  export const aesUtil = {

//   //获取key，
//   genKey : function (length = 16) {
//       let random = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//       let str = "";
//       for (let i = 0; i < length; i++) {
//           str  = str + random.charAt(Math.random() * random.length)
//       }
//       return str;
//   },

//   //加密
//   encrypt : function (plaintext,key) {
//       if (plaintext instanceof Object) {
//           //JSON.stringify
//           plaintext = JSON.stringify(plaintext)
//       }
//       let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), CryptoJS.enc.Utf8.parse(key), {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
//       return encrypted.toString();
//   },

//   //解密
//   decrypt : function (ciphertext,key) {
//       let decrypt = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
//       let decString = CryptoJS.enc.Utf8.stringify(decrypt).toString();
//       if(decString.charAt(0) === "{" || decString.charAt(0) === "[" ){
//           //JSON.parse
//           decString = JSON.parse(decString);
//       }
//       return decString;
//   }
// }
