/**
 * 测试
*/

export default {
  /**
     * 登陆
     */
  test: params => {
    return {
      url: '/test',
      method: 'get',
      params
    }
  }
}
