import Vue from 'vue'
import {registerComponents, echartsComponents} from '@/utiles'
import {$http} from '@/http'
import '@/icons'
import '@/mintUi'
import '@/elementUi'


if(process.env.VUE_APP_MOCK === 'true'){
    require('@/http/mock')
  }

const componentList = []

registerComponents(componentList)
const echartList = []
echartsComponents(echartList)

Vue.prototype.$http= $http
