
export default {
    getMeanus: params => {
        return {
            url: '/admin/getMeanus',
            method: 'post',
            params
        }
    },
    getiUsers: params => {
        return {
            url: '/users/getiUsers',
            method: 'post',
            params
        }
    },
    sendCode: params => {
        return {
            url: '/users/sendCode',
            method: 'post',
            params
        }
    },
    getMsgCode: params => {
        return {
            url: '/users/getMsgCode',
            method: 'post',
            params
        }
    },
    addLookCount: params => {
        return {
            url: '/users/addLookCount',
            method: 'post',
            params
        }
    },
    setAppInvite: params => {
        return {
            url: '/users/setAppInvite',
            method: 'post',
            params
        }
    },
    getCollect: params => {
        return {
            url: '/users/getCollect',
            method: 'post',
            params
        }
    },
    setCollect: params => {
        return {
            url: '/users/setCollect',
            method: 'post',
            params
        }
    },
    deleteCollect: params => {
        return {
            url: '/users/deleteCollect',
            method: 'post',
            params
        }
    },
    getHistory: params => {
        return {
            url: '/users/getHistory',
            method: 'post',
            params
        }
    },
    setHistory: params => {
        return {
            url: '/users/setHistory',
            method: 'post',
            params
        }
    },
    deleteHistory: params => {
        return {
            url: '/users/deleteHistory',
            method: 'post',
            params
        }
    },
    setUserReback: params => {
        return {
            url: '/users/setUserReback',
            method: 'post',
            params
        }
    },
}
