<template>
  <div class="GuessYouLike">
    <div class="top">
      <p class="title">
        <span>{{ $t('message.相关文章') }}</span>
      </p>
      <div class="more" @click="more()">{{ $t('message.查看全部') }}</div>
    </div>
    <NewsList :lists="lists" routeName="NewsDetail" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  props: {
    type: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters({
       getNewsData: "getNewsData"
    }),
    lists() {
      const _this = this
      if (!_this.$route.query.infoId) return []
      const data = _this.getNewsData.data || [];
      return data.filter(function(item, m) {
        return item.id != _this.$route.query.infoId && item.type === _this.type && m < 3;
      });
    },
  },
  methods: {
    more() {
      this.$router.push({ name: 'News', query: { type: this.type } });
    }
  },
};
</script>
<style lang="less" scoped>
.GuessYouLike {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-around;
  padding: 15px 10px;
  .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      // border-bottom: 3px solid #fbe802;
      color: #ffd602;
    }
    .more {
      cursor: pointer;
      border: 1px solid;
      border-color: #ffd602;
      color: #ffd602;
      border-radius: 5px;
      padding: 5px 6px;
      font-size: 15px;
    }
    // div {
    //     font-size: 15px;
    //     color: #607d8b;
    //     span {
    //         margin-right: 5px;
    //     }
    // }
  }
  .box {
    width: 100%;
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // height: 112px;
    // display: flex;
    .items {
      width: 49%;
      border-radius: 0 0 5px 5px;
      margin-bottom: 10px;
      .top_bg {
        border-left: 1px solid;
        border-right: 1px solid;
        border-top: 1px solid;
        border-color: #ffd602;
        border-radius: 5px 5px 0 0;
        padding: 5px;
        img {
          width: 100%;
        }
      }
      .center {
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: #ffd602;
        background-color: #593c22;
        color: #fff;
        display: flex;
        font-size: 12px;
        /* justify-content: start; */
        padding-left: 15px;
        text-align: left;
      }
      .bottom {
        background-color: #bcbcbc;
        display: flex;
        padding: 10px 5px;
        border-radius: 0 0 5px 5px;
        position: relative;
        justify-content: space-between;
        .time {
          padding: 2px 5px;
          border-radius: 15px;
          color: #fff;
          background-color: #58595a;
          text-align: center !important;
          position: absolute;
          right: 14px;
          bottom: 20px;
          font-size: 11px;
        }
        .code {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .ii {
          font-size: 11px;
          p {
            text-align: left;
          }
          .vs {
            color: red;
            font-size: 10px;
            font-weight: bold;
            text-align: center !important;
          }
        }
      }
    }
    .item {
      // flex-shrink: 0;
      width: calc(50% - 10px);
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 100px;
        border-radius: 10px;
      }
      span {
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
