<template>
  <div class="vdListItem">
    <div class="content">
      <div class="select" v-if="is_edit" @click="select">
        <i v-if="select_ids.indexOf(items.id) !== -1" class="iconfont icongougou"></i>
        <i v-else class="no_icon"></i>
      </div>
      <img v-lazy="items.img_url" alt="" @click="goto_play(items)">
      <div class="right" :class="[is_edit ? 'only' : 'defalut']" @click="goto_play(items)">
        <span class="sspan">{{items.title}}</span>
        <div class="tag">
          <p v-for="(item, i) in items.tag.split(',')" :key="i">{{item}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.vdListItem {
  padding: 5px 20px;
  .content {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .select {
      display: flex;
      align-items: center;
      .iconfont {
        padding: 5px;
        border-radius: 50%;
        background-color: #fbe802;
        color: #fff;
        font-size: 12px;
        margin-right: 10px;
      }
      .no_icon {
        border: 1px solid #000;
        padding: 10px;
        border-radius: 50%;
        background-color: #fff;
        margin-right: 10px;
      }
    }
    img {
      width: 150px;
      height: 100px;
      border-radius: 10px;
    }
    .defalut {
      width: calc(100% - 150px);
    }
    .only {
      width: calc(100% - 150px - 32px);
    }
    .right {
      display: flex;
      flex-direction: column;
      .sspan {
        // padding: 10px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        // text-align: left;
      }
      .tag {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        p {
          padding: 5px 10px;
          background-color: #f7efe4;
          color: #997e55;
          font-size: 14px;
          border-radius: 15px;
        }
      }
    }
  }
}
</style>
<script>
// import { Chart } from '@antv/g2';
export default {
  name: "VdListItem",
  props: {
    items: {
      type: Object,
    },
    is_edit: {
      type: Boolean,
    },
    select_ids: {
      type: Array,
    },
  },
  methods: {
    goto_play(item) {
      // console.log(item)
      const data = JSON.stringify(item);
      if (this.$route.name === 'Play') {
        this.$router.replace({ name: "Play", query: { params: data, invite_code: this.$route.query.invite_code } });
      } else {
        this.$router.push({ name: "Play", query: { params: data, invite_code: this.$route.query.invite_code } });
      }
    },
    select() {
      this.$emit('select_item', this.items.id)
    },
  },
  computed: {
    // iconName (){
    //   return `#icon-${this.iconClass}`
    // },
  },
};
</script>

<style scoped lang="less">
</style>
