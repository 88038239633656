<template>
  <div class="test-box">

    <!-- 年日历标题 -->
    <div class="box-title" v-show="!isMonth">{{ dateObj.month | monthTransform }}月</div>
    <!-- End -->
    <div :class="{'max-w': isMonth, 'min-w': !isMonth}">
      <div class="secd" v-show="isMonth">
        <p class="max-p">周日</p>
        <p class="max-p">周一</p>
        <p class="max-p">周二</p>
        <p class="max-p">周三</p>
        <p class="max-p">周四</p>
        <p class="max-p">周五</p>
        <p class="max-p">周六</p>
      </div>
      <div class="secd" v-show="!isMonth">
        <p class="min-p">日</p>
        <p class="min-p">一</p>
        <p class="min-p">二</p>
        <p class="min-p">三</p>
        <p class="min-p">四</p>
        <p class="min-p">五</p>
        <p class="min-p">六</p>
      </div>
      <table
        :class="{'content-time': true, 'box-border-header': isMonth}"
        border="0"
        cellspacing="0"
        cellpadding="0"
      >
        <tr
          v-for="(item, key) in dateList"
          :key="key"
          :class="{
            'max-days': isMonth,
            'min-days': !isMonth,
            'box-border': isMonth
          }"
        >
          <td :style="{cursor: 'pointer', color: isWeekend(item)}"
              :class="{'m_30': isMonth, 'm_12': !isMonth, 'current-day': dateType === 'D' && dateObj.days == item.day}"
              @click.stop="clickDay(item), dateObj.days = item.day">{{ item.day }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

const MONTH_ONE_DAY = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31] // 1-12月每个月的天数
export default {
  name: 'calendarComponent',
  props: {
    dateObj: {
      type: Object,
      default: () => {
        return {
          year: new Date().getFullYear(), // 如果不传，默认获取当前年
          month: new Date().getMonth() + 1, // 如果不传，默认获取当前月
          days: new Date().getDate()
        }
      }
    },
    isMonth: {
      type: Boolean,
      default: false
    },
    dateType: {
      type: String
    },
    planList: {
      type: Array,
      default: Array
    }
  },
  watch: {
    'dateObj.year': {
      handler () {
        this.initPage()
      }
    },
    'dateObj.month': {
      handler () {
        this.initPage()
      }
    }
  },
  data () {
    return {
      dateList: [] // 当月的日期列表
    }
  },
  filters: {
    monthTransform (val) {
      const MONTH_MAP = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '七',
        8: '八',
        9: '九',
        10: '十',
        11: '十一',
        12: '十二'
      }
      return MONTH_MAP[val] || '--'
    }
  },
  mounted () {
    this.initPage()
  },
  methods: {
    initPage () {
      this.nowmonth(this.dateObj.year, this.dateObj.month)
    },
    // 判断平年闰年
    isyear (val) {
      if (
        (val % 4 == 0 && val % 100 != 0) ||
        (val % 100 == 0 && val % 400 == 0)
      ) {
        MONTH_ONE_DAY[1] = 29
      } else {
        MONTH_ONE_DAY[1] = 28
      }
    },
    filterDate (val = '') {
      if (typeof val !== 'string') {
        val = String(val)
      }
      return val.replace(/-/g, '')
    },
    // 计算当月多少天
    nowmonth (year = 0, month = 0) {
      this.isyear(year) // 判断平年闰年
      const daylength = MONTH_ONE_DAY[month - 1]
      const dayList = []
      for (let i = 0; i < daylength; i++) {
        // 判断当前日期是否包含在
        const day = i + 1
        const oneday = this.planList.find(item => {
          return item == `${year}${month < 10 ? `0${month}` : month}${day < 10 ? `0${day}` : day}`
        })
        const isPlan = oneday !== undefined && oneday !== -1
        dayList.push({
          day,
          isPlan,
          type: i
        }) // 根据获取的天数向数组中添加日期
      }
      // 获取当月1号是周几，然后向数组中添加空字符串占位
      let times = new Date(year, month - 1, 1).getDay() + 1
      if (times == 0) {
        // 如果times是0则在前面添加6个空字符
        times = 6
      } else {
        times-- // 返回6则添加5个空字符，以此内推，返回1则不添加
      }
      for (let f = 0; f < times; f++) {
        dayList.unshift({
          day: '',
          type: 36 + f,
        })
      }
      let count = 0
      if (times >= 5 && daylength > 29) {
        count = 42
      } else {
        count = 35
      }
      // 日历宮格展示35，不足的空格补充占位
      for (let k = dayList.length; k < count; k++) {
        dayList.push({
          day: '',
          type: k,
        })
      }
      this.dateList = dayList
    },
    // 天选择
    clickDay (item) {
      if (this.dateType !== 'D') {
        if (this.dateType === 'Y') {
          this.$parent.clickMonth(this.dateObj.month)
          return
        }
      }
      if (this.dateType === 'M' || this.dateType === 'D') {
        // 月日历选择了某一天
        this.$emit('pickOneDay', item.day)
      }
    },
    // 判断是否周末
    isWeekend (item) {
      // 已排计划
      if (item.isPlan) {
        return '#e02020'
      } else {
        const weekday = new Date(this.dateObj.year, this.dateObj.month - 1, item.day).getDay()
        return weekday === 6 || weekday === 0 ? '#8c8c8c' : '#00B532'
      }
    }
  }
}
</script>
<style scoped>
.test-box {
  /* width: 100vw;
  height: 100vh; */
  width: 100%;
  text-align: center;
}
/* 表格边框 */
.box-border-header {
  border-top: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 0;
  border-bottom: 0;
}
.box-border {
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}
/* 月日历样式Start */
.max-w {
  width: 100%;
}
.secd {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
}
.max-p {
  width: 14.2%;
  display: flex;
  justify-content: center;
  font-family: 'PingFangSC-Regular';
  font-size: 18px;
  color: #a9a9a9;
}
.content-time {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.max-days {
  width: 14.286%;
  display: flex;
  justify-content: center;
  font-family: 'PingFangSC-Medium';
  font-size: 24px;
  /* 日期天数默认颜色，未排计划 */
  color: #8c8c8c;
}
/* 已安排 */
.already {
  color: #e02020;
}
/* 空闲 */
.free {
  color: #00b532;
}
.m_30 {
  margin: 30px 0px 30px 0px;
  padding: 30px\0 0px\0 30px\0 0px\0;
}
/** End */

/* 年日历样式Start */
.min-w {
  width: 100%;
}
.box-title {
  font-family: 'PingFangSC-Regular';
  font-weight: normal;
  font-size: 20px;
  color: #016ae0;
  text-align: left;
  margin-bottom: 12px;
}
.min-p {
  width: 15%;
  display: flex;
  justify-content: center;
  font-family: 'PingFangSC-Regular';
  font-size: 12px;
  color: #8c8c8c;
}
.min-days {
  width: 14.286%;
  display: flex;
  justify-content: center;
}
.m_12 {
  width: 18px;
  height: 20px;
  line-height: 20px;
  font-family: 'PingFangSC-Medium';
  font-size: 12px;
  font-weight: normal;
  /* 日期天数默认颜色，空闲 */
  color: #171717;
}
/** 选择的日 */
.current-day {
  color: #FFFFFF!important;
  background-color: #016ae0;
  border-radius: 50% 50%;
}
</style>
