export default {
  get_cont_data: params => {
      return {
        url: '/admin/get_cont_data',
        method: 'post',
        params
      }
    },
    videoLists: params => {
      return {
        url: '/play/postVideoLists',
        // url: 'http://api.sbsport.tv/play/postVideoLists',
        method: 'post',
        params
      }
    },
    getAds: params => {
      return {
        url: '/play/getAds',
        method: 'post',
        params
      }
    },
    getType: params => {
      return {
        url: '/home/getType',
        method: 'get',
        params
      }
    },
    getHome: params => {
      return {
        url: '/home',
        method: 'post',
        params
      }
    },
    getExport: params => {
      return {
        url: '/home/getExport',
        method: 'post',
        params
      }
    },
    updateGuess: params => {
      return {
        url: '/home/updateGuess',
        method: 'post',
        params
      }
    },
    search: params => {
      return {
        url: '/home/search',
        method: 'post',
        params
      }
    },
    hotSearch: params => {
      return {
        url: '/home/hotSearch',
        method: 'get',
        params
      }
    },
    historySearch: params => {
      return {
        url: '/home/historySearch',
        method: 'post',
        params
      }
    },
    deleteHistorySearch: params => {
      return {
        url: '/home/deleteHistorySearch',
        method: 'post',
        params
      }
    },
  }
  