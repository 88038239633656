<template>
  <div class="ExtendCenter">
    <mt-header title="推广中心" :fixed="true">
      <router-link
        :to="{ name: 'Mine', query: { invite_code: $route.query.invite_code } }"
        slot="left"
      >
        <i class="iconfont iconzuojiantou"></i>
      </router-link>
    </mt-header>
    <div class="information">
      <i class="iconfont iconicon30"></i>
      <div class="center">
        <p class="account">{{user_info.account.length === 32 ? '游客' : user_info.account}}</p>
        <p class="total">累计推广人数: {{user_detail.all_invite_count || 0}}</p>
      </div>
      <div class="duanwei" v-if="now_level_obj">
        <i class="iconfont iconhuangguan" :style="{ color: now_level_obj.color }"></i>
      </div>
    </div>
    <div class="level_box">
      <div class="top_box">
        <div class="left">
          <div class="top" v-if="now_level_obj">
            <span
              >LV{{ now_level_obj.level }}.{{ now_level_obj.level_name }}</span
            >
            <span v-if="now_level_obj.level !== level[level.length - 1].level"
              >LV{{ level[now_level_obj.level].level }}.{{
                level[now_level_obj.level].level_name
              }}</span
            >
          </div>
          <div v-else class="top">暂无数据</div>
          <mt-progress :value="press_vals || 0" :bar-height="5"></mt-progress>
        </div>
        <div class="right" @click="goto_ExtendCenter">
          <button>前往升级</button>
        </div>
      </div>
      <p>
        <span
          v-if="
            now_level_obj &&
            now_level_obj.level !== level[level.length - 1].level
          "
          >距离升级（增加观影次数）只差<i>{{
            level && level.length !== 0 && now_level_obj
              ? Number(level[now_level_obj.level].pepole) -
                Number(user_detail.all_invite_count)
              : "**"
          }}</i
          >人</span
        >
        <span
          v-else-if="
            now_level_obj &&
            now_level_obj.level === level[level.length - 1].level
          "
          >你已达到顶峰，恭喜你！</span
        >
        <span v-else>暂无数据</span>
      </p>
    </div>
    <div class="level">等级权益</div>
    <ul>
      <li>等级</li>
      <li>推广人数</li>
      <li>观影奖励</li>
      <li>清晰度</li>
    </ul>
    <div class="level_1" v-for="(item, i) in level" :key="i + 'level'">
      <div>
        <i class="iconfont iconhuangguan" :style="{ color: item.color }"></i>
        <p class="huangjin">{{item.level_name}}</p>
      </div>
      <p>{{item.pepole}}</p>
      <p>{{item.award}}</p>
      <p>{{item.definition}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExtendCenter",
  components: {},
  data() {
    return {
      press_vals: 0,
      now_level_obj: '',
      level: [],
      user_detail: {
        all_invite_count: 0
      },
      user_info: window.sessionStorage.getItem('user_info') ? JSON.parse(window.sessionStorage.getItem('user_info')) : '',
    };
  },
  created() {
    this.get_info();
  },
  methods: {
    goto_ExtendCenter() {
      this.$router.push({ name: 'Extend', query: { invite_code: this.$route.query.invite_code } })
    },
    press_val() {
      const next = this.now_level_obj.level
      if (next === this.level[this.level.length - 1].level) {
        return 100
      }
      const now = this.user_detail.all_invite_count
      const next_pepole = Number(this.level[next].pepole)
      const margin = Number(this.level[next].pepole) - Number(this.now_level_obj.pepole)
      return now == 0 ? 0 : 100 - margin / next_pepole * 100
    },
    async get_info() {
      const params = {
        uid: this.user_info ? this.user_info.id : "",
      };
      const { errNum, data, level } = await this.$http("getUsers", params);
      if (errNum === 1) {
        this.user_detail = data[0] || [];
        this.level = level;
        if (this.user_detail.length === 0 || this.level.length === 0) return;
        for (let i = 0; i < this.level.length; i++) {
          const element = this.level[i];
          if (element.pepole == this.user_detail.all_invite_count) {
            this.now_level_obj = element;
            this.press_vals = this.press_val();
            // console.log(this.now_level_obj)
            return;
          }
        }
        this.now_level_obj  = this.level[this.level.length - 1]
        this.press_vals = 100
      } else {
        this.$Toast("获取用户信息出现错误");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ExtendCenter {
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
  .information {
    background: #eee;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    .iconfont {
      font-size: 70px;
      // padding-left: 30px;
    }
    .center {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      p {
        font-size: 12px;
        display: flex;
        align-items: space-around;
        // padding: 5px;
      }
    }
    .duanwei {
      display: flex;
      align-items: center;
      .iconfont {
        // color: #fbe802;
        font-size: 40px;
        // padding-top: 20px;
        // padding-left: 80px;
        display: flex;
        justify-content: space-around;
      }
    }
  }
  .level_box {
    background-color: #fbe802;
    border-radius: 10px;
    padding: 5px 10px;
    .top_box {
      display: flex;
      flex-direction: row;
      font-size: 15px;
      align-items: center;
      .left {
        width: calc(100% - 100px);
        .top {
          font-size: 13px;
          display: flex;
          justify-content: space-between;
          span:first-child {
            color: orange;
          }
        }
      }
      .right {
        width: 80px;
        margin-left: 15px;
        margin-top: 15px;
        button {
          width: 85px;
          height: 30px;
          background-color: #fff;
          border: none;
          border-radius: 20px;
        }
      }
    }
    p {
      font-size: 13px;
      text-align: left;
      span {
        i {
          color: orange;
          margin: 0 5px;
        }
      }
    }
  }
}
.level {
  font-size: 16px;
  padding-top: 20px;
}
ul {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  padding-top: 20px;
  margin-left: 30px;
  li:nth-child(2) {
    margin-left: 15px;
  }
}
.level_1 {
  // margin-top: 20px;
  border: 1px solid#eee;
  border-radius: 20px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  // padding-top: 8px;
  margin-top: 10px;
  font-size: 12px;
  align-items: center;
  font-size: 12px;
  p:nth-child(2) 
  {
    font-size: 14px;
  }
  p:nth-child(3) {
    font-size: 14px;
  }
  .iconfont {
    font-size: 20px;
    margin-top: 10px;
  }
}
</style>
