import Vue from 'vue';
// import  'element-ui/lib/theme-chalk/index.css'
import 'mint-ui/lib/style.css'
// import MintUI from 'mint-ui'
import './rewrite/index.less'

// Vue.use(MintUI)

import { Search, Actionsheet, Lazyload, Toast, Indicator, MessageBox, Field, Header, Button, Tabbar, TabItem, Navbar, Loadmore, Swipe, SwipeItem, Progress } from 'mint-ui'

Vue.component(Actionsheet.name, Actionsheet);
Vue.component(Button.name, Button);
Vue.component(Tabbar.name, Tabbar);
Vue.component(TabItem.name, TabItem);
Vue.component(Navbar.name, Navbar);
Vue.component(Loadmore.name, Loadmore);
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);
Vue.component(Header.name, Header);
Vue.component(Progress.name, Progress);
Vue.component(Field.name, Field);
Vue.component(Search.name, Search);

Vue.prototype.$Toast = Toast
Vue.prototype.$Indicator = Indicator
Vue.prototype.$MessageBox = MessageBox

Vue.use(Lazyload);
